import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/solid';
import * as React from 'react';
import { Button, mergeClasses } from '.';
import { SortDirection, SortInfoRecord } from './sortInfo';

interface SortHeaderButtonProps {
  name: string;
  children: React.ReactChild;
  sortInfo: SortInfoRecord;
  disabled?: boolean;
  align?: 'left' | 'center' | 'right';
  onSort(column: string, direction: SortDirection): void;
}
export default function SortHeaderButton(props: SortHeaderButtonProps) {
  const sortThisColum = props.sortInfo.get('column') === props.name;
  const direction = props.sortInfo.get('direction');

  const onSortPress = () => {
    let newSortDirection: SortDirection =
      sortThisColum && direction === SortDirection.Ascending ? SortDirection.Descending : SortDirection.Ascending;

    props.onSort(props.name, newSortDirection);
  };

  return (
    <>
      <Button
        theme="none"
        onClick={onSortPress}
        disabled={props.disabled}
        className={mergeClasses(
          'relative w-full py-2 pl-2 text-xs uppercase tracking-wide shadow-none focus:ring-primary',
          {
            className: 'justify-center',
            enable: props.align === 'center',
          },
          {
            className: 'justify-end',
            enable: props.align === 'right',
          }
        )}
      >
        {sortThisColum ? (
          direction === SortDirection.Descending ? (
            <ChevronUpIcon className="absolute -inset-x-2 inset-y-2.5 h-4 w-4" />
          ) : (
            <ChevronDownIcon className="absolute -inset-x-2 inset-y-2.5 h-4 w-4" />
          )
        ) : null}
        {props.children}
      </Button>
    </>
  );
}
