import { CheckIcon } from '@heroicons/react/solid';
import { WithClassName } from '.';

export interface SelectedCheckboxProps {
  selected: boolean;
}

export default function SelectedCheckbox({ selected, className }: WithClassName<SelectedCheckboxProps>) {
  return selected ? (
    <div className={className}>
      <CheckIcon className="h-4 w-4 text-green-500" />
    </div>
  ) : null;
}
