import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotifications } from '../global/Notifications';
import { useUserProfile } from '../global/UserCompanyContext';

export function useAppVersion() {
  const { serverAppVersion, clientAppVersion } = useInternalAppVersions();

  const isAppVersionUpdated = useMemo(
    () => serverAppVersion === clientAppVersion,
    [clientAppVersion, serverAppVersion]
  );

  return {
    isAppVersionUpdated,
  };
}

export function useAppVersionNotification() {
  const { isAppVersionUpdated } = useAppVersion();
  const { serverAppVersion, clientAppVersion } = useInternalAppVersions();
  const { add: addNotification } = useNotifications();
  const [notifiedVersion, setNotifiedVersion] = useState(process.env.NODE_ENV === 'development' ? 'DEV' : '');

  const refreshBrowser = useCallback(() => window.location.reload(), []);

  useEffect(() => {
    if (serverAppVersion !== '' && clientAppVersion !== '' && !isAppVersionUpdated) {
      if (clientAppVersion !== notifiedVersion) {
        addNotification({
          headerText: 'New Version Available!',
          message: 'Refresh this page to see the latest version of BAS.',
          actionButtonName: 'Refresh',
          actionCallback: refreshBrowser,
        });
        setNotifiedVersion(clientAppVersion ?? '');
      }
    }
  }, [addNotification, clientAppVersion, isAppVersionUpdated, notifiedVersion, refreshBrowser, serverAppVersion]);
}

function useInternalAppVersions() {
  const { appVersion: serverAppVersion } = useUserProfile();
  const clientAppVersion = document.getElementsByTagName('html')[0].getAttribute('data-app-version');
  return { serverAppVersion, clientAppVersion };
}
