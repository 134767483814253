import { Switch } from '@headlessui/react';
import { forwardRef } from 'react';
import { mergeClasses } from '.';

type ToggleThemeName = 'standard' | 'error';
interface ToggleThemeInfo {
  checkedBackground: string;
  uncheckedBackground: string;
  switch: string;
}

export type ToggleTheme = ToggleThemeName | ToggleThemeInfo;

const themes: Record<ToggleThemeName, ToggleThemeInfo> = {
  standard: {
    checkedBackground: 'bg-primary',
    uncheckedBackground: 'bg-gray-400',
    switch: 'focus:ring-primary',
  },
  error: {
    checkedBackground: 'bg-red-600',
    uncheckedBackground: 'bg-gray-400',
    switch: 'focus:ring-red-600',
  },
};

type SwitchProps = Parameters<typeof Switch>[0];

export type ToggleProps = SwitchProps & {
  theme: ToggleTheme;
};

export default forwardRef(function Toggle(
  { className, theme, checked, ...props }: ToggleProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  if (typeof theme === 'undefined') {
    theme = 'standard';
  }

  let info: ToggleThemeInfo;
  if (typeof theme === 'string') {
    info = themes[theme];
    if (info === undefined) {
      throw Error('Invalid theme: ' + theme);
    }
  } else {
    info = theme;
  }

  return (
    <Switch
      {...props}
      checked={checked}
      className={mergeClasses(
        checked ? info.checkedBackground : info.uncheckedBackground,
        info.switch,
        'relative inline-flex h-6 w-11 flex-shrink-0 rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-offset-2',
        className
      )}
      ref={ref}
    >
      <span
        aria-hidden="true"
        className={mergeClasses(
          checked ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
        )}
      />
    </Switch>
  );
});
