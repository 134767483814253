import React from 'react';

export const AdminUserForm = React.lazy(() => import('./user/UserForm'));
export const AdminUserIndex = React.lazy(() => import('./user/UserIndex'));
export const AdminCompanyForm = React.lazy(() => import('./company/CompanyForm'));
export const AdminCompanyIndex = React.lazy(() => import('./company/CompaniesIndex'));
export const AdminImportIndex = React.lazy(() => import('./import/ImportIndex'));
export const AdminTagIndex = React.lazy(() => import('./tag/TagIndex'));
export const AdminTagForm = React.lazy(() => import('./tag/TagForm'));
export const AdminProvidedPlaybookIndex = React.lazy(() => import('./playbook/ProvidedPlaybookIndex'));
export const AdminProvidedPlaybookForm = React.lazy(() => import('./playbook/ProvidedPlaybookForm'));
export const AdminAttackSurfaceIndex = React.lazy(() => import('./attacksource/AttackSurfaceIndex'));
export const AdminAttackSurfaceForm = React.lazy(() => import('./attacksource/AttackSurfaceForm'));
export const AdminDataSourceIndex = React.lazy(() => import('./datasource/DataSourceIndex'));
export const AdminDataSourceForm = React.lazy(() => import('./datasource/DataSourceForm'));
export const AdminSecurityControlTypeIndex = React.lazy(() => import('./securitycontroltype/SecurityControlTypeIndex'));
export const AdminSecurityControlTypeForm = React.lazy(() => import('./securitycontroltype/SecurityControlTypeForm'));
export const AdminSecurityVendorIndex = React.lazy(() => import('./securityvendor/SecurityVendorIndex'));
export const AdminSecurityVendorForm = React.lazy(() => import('./securityvendor/SecurityVendorForm'));
export const AdminIndustryForm = React.lazy(() => import('./industry/IndustryForm'));
export const AdminIndustryIndex = React.lazy(() => import('./industry/IndustriesIndex'));
export const AdminDataComponentIndex = React.lazy(() => import('./datacomponent/DataComponentIndex'));
export const AdminDataComponentForm = React.lazy(() => import('./datacomponent/DataComponentForm'));
export const AdminDetectionStrategyIndex = React.lazy(() => import('./detectionstrategy/DetectionStrategyIndex'));
export const AdminDetectionStrategyForm = React.lazy(() => import('./detectionstrategy/DetectionStrategyForm'));
export const AdminSalesPackageForm = React.lazy(() => import('./salespackage/SalesPackageForm'));
export const AdminSalesPackageIndex = React.lazy(() => import('./salespackage/SalesPackagesIndex'));
export const AdminNotificationStrategyIndex = React.lazy(
  () => import('./notificationstrategy/NotificationStrategyIndex')
);
export const AdminNotificationStrategyForm = React.lazy(
  () => import('./notificationstrategy/NotificationStrategyForm')
);
