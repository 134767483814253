import { Map } from 'immutable';
import * as React from 'react';
import { HelpTip } from '.';
import { FieldErrorsAlert, getFieldErrors } from './';

export interface GenericInputRenderProps {
  id?: string;
  hasError: boolean;
}

export type GenericInputRenderFunc = (props: GenericInputRenderProps) => JSX.Element;

export interface GenericInputProps {
  name: string;
  label?: string;
  required?: boolean;
  id?: string;
  className?: string;
  labelClassName?: string;
  errors?: Map<string, string[]>;
  children?: React.ReactNode | GenericInputRenderFunc;
  helptip?: string | JSX.Element;
}

const GenericInput = ({
  children,
  name,
  label,
  required,
  id,
  className,
  labelClassName,
  errors,
  helptip,
}: GenericInputProps) => {
  const errorList = getFieldErrors(errors, name);
  const hasError = errorList.length > 0;

  return (
    <div className={className}>
      {label === undefined || label === '' ? null : (
        <label htmlFor={id ?? name} className={labelClassName ?? 'block text-sm font-medium'}>
          {label}:{required === true ? <span className="text-primary">*</span> : null}
          {helptip === undefined ? null : <HelpTip>{helptip}</HelpTip>}
        </label>
      )}
      <div className="relative flex">
        {typeof children === 'function' ? children({ id: id ?? name, hasError }) : children}
      </div>
      {hasError ? <FieldErrorsAlert errors={errorList} /> : null}
    </div>
  );
};

export default GenericInput;
