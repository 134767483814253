import { useCallback, useEffect, useState } from 'react';
import { useUserProfile } from '../../../global/UserCompanyContext';
import { useApiClass, useConfirmDialog, useErrors, useNavigate } from '../../../hooks';
import ProfileApi from '../profileApi';

export function useAuthenticatorAppTwoFactor() {
  const api = useApiClass(ProfileApi);
  const [qrCode, setQrCode] = useState('');
  const [otp, setOtp] = useState('');
  const [loading, setIsLoading] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const { errors, catchErrors, clear } = useErrors();
  const {
    fetchProfile,
    updateProfile,
    loading: profileLoading,
    hasAuthenticatorApp2Fa,
    hasPhone2Fa,
    isMfaRequired,
  } = useUserProfile();
  const navigate = useNavigate();

  const returnToMfa = useCallback(() => {
    navigate('/profile/mfa');
  }, [navigate]);

  const loadQrCode = useCallback(async () => {
    setIsLoading(true);
    clear();
    try {
      const qrCode = await api.getAuthenticatorQrCode();
      setQrCode(qrCode);
    } catch (e) {
      catchErrors(e);
    } finally {
      setIsLoading(false);
    }
  }, [api, catchErrors, clear]);

  const { show, showDialog, onAccept, onDecline } = useConfirmDialog(
    loadQrCode,
    returnToMfa,
    isMfaRequired && (hasAuthenticatorApp2Fa || hasPhone2Fa)
  );

  // If MFA is required and we already have 2FA configured display a warning, otherwise load the authenticator key immediately
  useEffect(() => {
    if (!profileLoading) {
      if (isMfaRequired && (hasAuthenticatorApp2Fa || hasPhone2Fa)) {
        showDialog();
      } else {
        (async () => {
          await loadQrCode();
          fetchProfile();
        })();
      }
    }
    // Only try to load this once, after the profile is loaded
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profileLoading]);

  const handleOtpChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setOtp(value);
  }, []);

  const onSubmitTwoFactorCodeHandler = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsVerifying(true);
      clear();
      try {
        const response = await api.verifyAuthenticatorCode(otp);
        updateProfile(response);
        navigate('/profile/mfa/success');
      } catch (e) {
        catchErrors(e);
      } finally {
        setIsVerifying(false);
      }
    },
    [api, catchErrors, clear, navigate, otp, updateProfile]
  );

  return {
    qrCode,
    onSubmitTwoFactorCodeHandler,
    otp,
    handleOtpChange,
    isVerifying,
    errors,
    loading,
    hasAuthenticatorApp2Fa,
    showWarningDialog: show,
    onWarningDialogAccept: onAccept,
    onWarningDialogDecline: onDecline,
  };
}
