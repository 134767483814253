import { CalendarIcon, ChevronDownIcon, XIcon } from '@heroicons/react/outline';
import { DatePicker, WithClassName } from '.';
import { formatLongDate } from '../../util/formattingHelpers';

export type SearchFilterDatePickerProps = WithClassName & {
  label: string;
  name: string;
  value: string;
  showCalender?: boolean;
  onChange(name: string, value: string): void;
};

const SearchFilterDatePicker = ({
  label,
  value,
  name,
  className,
  showCalender,
  onChange,
}: SearchFilterDatePickerProps) => {
  const hasDateSelected = value === undefined || value === '';

  let date = hasDateSelected ? undefined : new Date(value.replace(/-/g, '/'));

  const dateValue = date === undefined ? '' : formatLongDate(date, { dateStyle: 'medium' });
  const displayName = hasDateSelected ? label : dateValue;
  const conditionalClass = hasDateSelected ? 'pl-2' : 'pl-2 text-primary font-semibold';
  function handleOnChange(value: string) {
    onChange(name, value);
  }

  function handleClear() {
    onChange(name, '');
  }

  return (
    <div className="flex text-base text-gray-800">
      <DatePicker value={value ?? ''} onChange={handleOnChange}>
        <label className="flex" data-input>
          <div className={conditionalClass}>{displayName ?? 'Select Date'}</div>
          {showCalender ? <CalendarIcon className="my-auto ml-2 h-6 w-6 cursor-pointer" aria-hidden="true" /> : ''}
        </label>
      </DatePicker>
      {showCalender ?? (
        <div className={className ?? 'relative flex'}>
          {hasDateSelected === true ? (
            <DatePicker value={value ?? ''} onChange={handleOnChange}>
              <label className="flex " data-input>
                <ChevronDownIcon className="h-5 w-5 pt-2 pr-1 text-gray-800" aria-hidden="true" data-toggle />
              </label>
            </DatePicker>
          ) : (
            <button title="clear search" type="button" onClick={handleClear} className="item-center -mt-2 px-1">
              <XIcon className="h-4 w-4 flex-1 text-gray-500 " aria-hidden="true" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
export default SearchFilterDatePicker;
