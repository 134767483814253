import * as signalR from '@microsoft/signalr';
import { useEffect, useRef, useState } from 'react';
import { ErrorMapType } from '../components/common';
import { useAuth } from '../global/AuthProvider';
import useErrors from './useErrors';

export type HubEventHandler = (connection: signalR.HubConnection) => void | (() => void | undefined);
export type AsyncHubEventHandler = (connection: signalR.HubConnection) => void | Promise<void>;
export interface HubEvents {
  onInit: HubEventHandler;
  onConnected: AsyncHubEventHandler;
}

export enum HubState {
  Initializing,
  Connected,
  Reconnecting,
  Closed,
  Failed,
}

export interface SignalRState {
  state: HubState;
  errors: ErrorMapType;
}

export default function useSignalR(url: string, events?: HubEvents) {
  const [state, setState] = useState<HubState>(HubState.Initializing);
  const _connection = useRef<signalR.HubConnection | null>(null);
  const cleanupFunc = useRef<(() => void | undefined)[]>([]);
  const { errors, addError, catchErrors, clear: clearErrors } = useErrors();
  const { accessToken } = useAuth();
  const token = useRef(accessToken);

  useEffect(() => {
    token.current = accessToken;
  }, [accessToken]);

  const onInitHandler = events?.onInit;
  const onConnectedHandler = events?.onConnected;
  const hasToken = token.current !== '';
  useEffect(() => {
    let connection: signalR.HubConnection | null = null;
    if (hasToken) {
      _connection.current = connection = new signalR.HubConnectionBuilder()
        .withUrl(url, { accessTokenFactory: () => token.current })
        .withAutomaticReconnect()
        .build();

      async function start() {
        try {
          await connection!.start();
          clearErrors('hubConnection');
          setState(HubState.Connected);
          if (onConnectedHandler) {
            const ret = onConnectedHandler(connection!);
            if (ret) {
              await ret;
            }
          }
        } catch (error) {
          clearErrors('hubConnection');
          addError('hubConnection', 'Attempt to connect to the real-time update server failed.');
          setState(HubState.Failed);
        }
      }

      if (onInitHandler) {
        const initCleanup = onInitHandler(connection);
        if (initCleanup) {
          cleanupFunc.current.push(initCleanup);
        }
      }

      connection.onreconnecting((error) => {
        setState(HubState.Reconnecting);
        addError('hubConnection', 'Attempt to connect to the real-time update server failed. Reconnecting...');
      });

      connection.onreconnected((error) => {
        setState(HubState.Connected);
        clearErrors('hubConnection');
      });

      connection.onclose((error) => {
        if (_connection.current === null) {
          return;
        }

        if (typeof error !== 'undefined') {
          clearErrors('hubConnection');
          addError('hubConnection', 'Connection was lost.');
          start();
        }
        setState(HubState.Closed);
      });

      start();
    }

    return () => {
      clearErrors();

      if (connection !== null) {
        connection.stop();
        for (const cleanup of cleanupFunc.current) {
          cleanup();
        }
        _connection.current = null;
        cleanupFunc.current = [];
      }
    };
  }, [addError, catchErrors, clearErrors, onInitHandler, onConnectedHandler, url, hasToken]);

  return {
    state: state,
    errors: errors,
  };
}
