import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import authService from '../util/authService';

interface UrlState {
  url: string;
}

function LoginIFrame() {
  const location = useLocation();
  const navigate = useNavigate();
  React.useEffect(() => {
    const locState = location.state as UrlState;
    const url = typeof locState?.url === 'string' ? locState.url : null;
    authService
      .get()
      .redirectToLogin(url ?? '')
      .then((response) => {
        navigate(response ?? '/');
      });
    // We just want this to run on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <iframe title="Login IFrame" className="h-screen w-screen" name="LoginIFrame" />;
}

export default LoginIFrame;
