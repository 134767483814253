import * as React from 'react';
import { ErrorMarker, GenericInput, GenericInputProps } from '.';
import Input from './Input';

export type TextInputProps = GenericInputProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    containerClassName?: string;
  };

const TextInput = React.forwardRef(
  (
    { id, value, name, label, required, containerClassName, labelClassName, errors, helptip, ...props }: TextInputProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    return (
      <GenericInput
        id={id}
        name={name}
        label={label}
        required={required}
        className={containerClassName}
        labelClassName={labelClassName}
        errors={errors}
        helptip={helptip}
      >
        {({ hasError, ...inputProps }) => (
          <>
            <Input
              {...inputProps}
              {...props}
              name={name}
              required={required}
              value={value ?? ''}
              theme={hasError ? 'error' : 'standard'}
              ref={ref}
            />
            <ErrorMarker hasError={hasError} className="ml-1 mt-2" />
          </>
        )}
      </GenericInput>
    );
  }
);

export default TextInput;
