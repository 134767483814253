import * as React from 'react';
import { Button, ButtonProps, mergeClasses } from './index';

export type CancelButtonProps = ButtonProps;

export default React.forwardRef(function CancelButton(
  { children, theme, className, ...props }: CancelButtonProps,
  ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>
) {
  return (
    <Button {...props} theme={theme ?? 'white'} ref={ref} className={mergeClasses(className, 'border-gray-400 py-1')}>
      {children ?? 'Cancel'}
    </Button>
  );
});
