import { Record } from 'immutable';

export enum SortDirection {
  Ascending,
  Descending,
}

export interface SortInfo {
  direction: SortDirection;
  column: string;
}

export type SortInfoRecord = Record<SortInfo>;
export const SortInfoRecordFactory = Record<SortInfo>(
  {
    direction: SortDirection.Ascending,
    column: '',
  },
  'SortInfo'
);

export interface GenericSortInfo<T> {
  direction: SortDirection;
  column: Extract<keyof T, string> | '';
}

export type GenericSortInfoRecord<T> = Record<GenericSortInfo<T>>;
