import axios, { CancelTokenSource } from 'axios';
import { debounce, isEqual } from 'lodash';
import * as React from 'react';
import createHttp, { REQUEST_CANCELED } from '../../util/http';

export interface LoadDataWrapperProps<TData, TParams> {
  url: string;
  params?: TParams;
  initialData?: TData;
  children: (props: LoadDataWrapperRenderProps<TData>) => React.ReactNode;
}

export interface LoadDataWrapperRenderProps<TData> {
  data: TData;
  loading: boolean;
}

type LoadDataWrapperState<TData> = LoadDataWrapperRenderProps<TData>;

export default class LoadDataWrapper<TData = any, TParams = any> extends React.Component<
  LoadDataWrapperProps<TData, TParams>,
  LoadDataWrapperState<TData>
> {
  private cancelToken: CancelTokenSource | null = null;
  private http = createHttp(true);
  private loadDataDebounced: () => void;

  constructor(props: LoadDataWrapperProps<TData, TParams>) {
    super(props);

    this.loadDataDebounced = debounce(this.loadData, 350);

    this.state = {
      data: props.initialData || ({} as TData),
      loading: false
    };
  }

  componentDidMount() {
    this.cancelToken = axios.CancelToken.source();
    this.loadData();
  }

  componentDidUpdate(prevProps: LoadDataWrapperProps<TData, TParams>) {
    if (!isEqual(this.props.params, prevProps.params) || !isEqual(this.props.url, prevProps.url)) {
      // We debounce loadData so that we don't make tons of requests when the user is typing in a search field.
      this.loadDataDebounced();
    }
  }

  componentWillUnmount() {
    if (this.cancelToken) {
      this.cancelToken.cancel(REQUEST_CANCELED);
      this.cancelToken = null;
    }
  }

  render() {
    if (typeof this.props.children !== 'function') {
      throw new Error('children prop must be a function.');
    }

    return this.props.children(this.state);
  }

  loadData = () => {
    this.setState({ loading: true });
    const cancelToken = this.cancelToken ? this.cancelToken.token : undefined;
    this.http
      .get(this.props.url, {
        params: this.props.params,
        cancelToken: cancelToken
      })
      .then((response) => {
        this.setState({
          data: response.data || {},
          loading: false
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };
}
