import * as React from 'react';
import { AuthProvider, useAuth } from '../global/AuthProvider';
import { LoadingIndicator } from './common';
import PageContainer from './PageContainer';
import Sidebar from './Sidebar';
import { UserPreferencesProvider } from '../global/userPreferences/UserPreferencesContext';

export default function Layout() {
  return (
    <AuthProvider>
      <LayoutInternal />
    </AuthProvider>
  );
}

function LayoutInternal() {
  const { accessToken } = useAuth();
  const [isMobileMenuOpen, setMobileMenuOpen] = React.useState<boolean>(false);

  return (
    <div className="relative">
      {accessToken === '' ? (
        <LoadingIndicator className="mt-5" />
      ) : (
        <UserPreferencesProvider>
          <Sidebar isMobileMenuOpen={isMobileMenuOpen} setMobileMenuOpen={setMobileMenuOpen} />
          <PageContainer setMobileMenuOpen={setMobileMenuOpen} />
        </UserPreferencesProvider>
      )}
    </div>
  );
}
