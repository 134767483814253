import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import authService from '../util/authService';

function LoginCallback() {
  const navigate = useNavigate();

  React.useEffect(() => {
    function handleError(e: { message: string }) {
      navigate('/', { state: { notification: { message: e.message } } });
    }

    try {
      authService
        .get()
        .handleCallback()
        .then((redirectLoc: string) => {
          navigate(redirectLoc || '/');
        })
        .catch(handleError);
    } catch (error) {
      handleError(error as Error);
    }
  }, [navigate]);

  return null;
}

export default LoginCallback;
