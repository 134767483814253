import { AxiosError } from 'axios';
import { Map } from 'immutable';

export function handleErrors(error: AxiosError, errorMap: Map<string, string[]>) {
  if (error.response && error.response.data && error.response.data.errors) {
    const errors = error.response.data.errors;
    return errorMap.withMutations((e) => {
      e.clear();
      Object.keys(errors).forEach((x) => {
        const arr: string[] = errors[x];
        if (arr && arr.length > 0) {
          e.set(x, arr);
        }
      });
    });
  }

  return errorMap;
}

export function handleCaughtError(error: Error, info: { componentStack: string }): void {
  console.error({ error, stackTrace: error.stack, name: error.name, info });
}
