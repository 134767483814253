import { Map } from 'immutable';
import React from 'react';
import ErrorAlert from './ErrorAlert';

export interface DefaultErrorAlertProps {
  errorKey?: string;
  errors: Map<string, string[]>;
}
export default function DefaultErrorAlert({ errors, errorKey }: DefaultErrorAlertProps) {
  return <ErrorAlert errors={errors.get(errorKey ?? '')} />;
}
