import { Button, PageSection, PageTemplate } from '../../common';
import PhoneInHand from '../../common/icons/PhoneInHand';

const MFASuccess: React.FC = () => {
  return (
    <PageTemplate title="MFA Setup">
      <PageSection childrenClassName='text-center'>
        <PhoneInHand />
        <p className='mt-3 text-lg font-medium'>Setup Successful!</p>
        <Button to="/profile/mfa" theme="primary" className='mt-3 py-1 px-2'>
            Continue
        </Button>
      </PageSection>
    </PageTemplate>
  );
};

export default MFASuccess