import { Dialog as HeadlessDialog, Transition } from '@headlessui/react';
import { Fragment, MutableRefObject, PropsWithChildren } from 'react';
import { mergeClasses, WithClassName } from '.';

export type DialogProps = PropsWithChildren<{}> &
  WithClassName & {
    show: boolean;
    initialFocus?: MutableRefObject<HTMLElement | null>;
    onClose(): void;
  };

function Dialog({ show, initialFocus, children, className, onClose }: DialogProps) {
  return (
    <Transition.Root show={show} as={Fragment}>
      <HeadlessDialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={initialFocus}
        onClose={onClose}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <HeadlessDialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={mergeClasses(
                'relative inline-block overflow-hidden rounded bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:h-[80vh] sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle',
                className
              )}
            >
              <Content>{children}</Content>
            </div>
          </Transition.Child>
        </div>
      </HeadlessDialog>
    </Transition.Root>
  );
}

function Content({ children }: PropsWithChildren<{}>) {
  return <div className="flex h-full w-full flex-col">{children}</div>;
}

function Title({ children }: PropsWithChildren<{}>) {
  return (
    <div className="mt-3 flex-shrink flex-grow-0 text-center sm:mt-0 sm:text-left">
      <HeadlessDialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
        {children}
      </HeadlessDialog.Title>
    </div>
  );
}

function Body({ children, className }: PropsWithChildren<WithClassName>) {
  return <div className={mergeClasses('mt-2 flex-auto overflow-y-scroll', className)}>{children}</div>;
}

function Actions({ children, className }: PropsWithChildren<WithClassName>) {
  return (
    <div className={mergeClasses('mt-5 flex-shrink grow-0 sm:mt-4 sm:flex sm:justify-end sm:gap-2', className)}>
      {children}
    </div>
  );
}

export default Object.assign(Dialog, { Title, Body, Actions });
