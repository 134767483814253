import React, { AnchorHTMLAttributes, ButtonHTMLAttributes } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { mergeClasses } from '.';
import theme from './Theme';

type ButtonThemeName = 'none' | 'primary' | 'error' | 'white';

interface ButtonThemeInfo {
  button: string;
}

export type ButtonTheme = ButtonThemeName | ButtonThemeInfo;

export const DefaultButtonClassName =
  'inline-flex items-center px-4 py-2 border border-transparent text-base font-medium rounded shadow-sm ';

const themes: Record<ButtonThemeName, ButtonThemeInfo> = {
  none: {
    button:
      'bg-transparent border-transparent hover:bg-gray-50 focus:ring-primary focus:outline-none focus:ring-2 focus:ring-offset-2',
  },
  primary: { button: theme.primary.button },
  error: { button: theme.error.button },
  white: { button: theme.white.button },
};

export type ButtonProps = (
  | ButtonHTMLAttributes<HTMLButtonElement>
  | AnchorHTMLAttributes<HTMLAnchorElement>
  | LinkProps
) & {
  theme?: ButtonTheme;
};

export default React.forwardRef(function Button(
  { className, theme, ...props }: ButtonProps,
  ref: React.ForwardedRef<HTMLAnchorElement | HTMLButtonElement>
) {
  let info: ButtonThemeInfo;
  if (typeof theme === 'undefined') {
    theme = 'primary';
  }

  if (typeof theme === 'string') {
    info = themes[theme];
    if (info === undefined) {
      throw Error('Invalid theme: ' + theme);
    }
  } else {
    info = theme;
  }

  const typedProps: ButtonHTMLAttributes<HTMLButtonElement> | AnchorHTMLAttributes<HTMLAnchorElement> | LinkProps =
    props;
  if (isLinkAttributes(typedProps)) {
    return (
      <Link
        {...typedProps}
        className={mergeClasses(DefaultButtonClassName, info.button, className)}
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
      />
    );
  }

  if (isAnchorAttributes(typedProps)) {
    return (
      // Content is passed as 'children' prop
      // eslint-disable-next-line jsx-a11y/anchor-has-content
      <a
        {...typedProps}
        className={mergeClasses(DefaultButtonClassName, info.button, className)}
        ref={ref as React.ForwardedRef<HTMLAnchorElement>}
      />
    );
  }

  return (
    <button
      {...typedProps}
      type={typedProps.type ?? 'button'}
      className={mergeClasses(DefaultButtonClassName, info.button, className)}
      ref={ref as React.ForwardedRef<HTMLButtonElement>}
    />
  );
});

function isLinkAttributes(props: Omit<ButtonProps, 'theme'>): props is LinkProps {
  const anchor = props as LinkProps;
  return anchor.to !== undefined;
}

function isAnchorAttributes(props: Omit<ButtonProps, 'theme'>): props is AnchorHTMLAttributes<HTMLAnchorElement> {
  const anchor = props as AnchorHTMLAttributes<HTMLAnchorElement>;
  return anchor.href !== undefined;
}
