import axios from 'axios';
import { Map } from 'immutable';
import { isNil } from 'lodash';

const emptyErrors = Map<string, string[]>();

export type ErrorType = string | string[] | undefined;

export interface ErrorResponse {
  errors: ErrorMap;
}
export type ErrorMapType = Map<string, string[]>;

export interface ErrorMap {
  [K: string]: string[];
}

export function getFieldErrors(errors?: Map<string, string[]>, name?: string): string[] {
  if (!errors || !name || !Map.isMap(errors)) {
    return [];
  }

  return errors.get(name, []);
}

export function normalizeErrors(errors: ErrorType) {
  if (typeof errors === 'string') {
    return [errors];
  }

  if (errors instanceof Array) {
    return errors;
  }

  return [];
}

export function mapErrorsFromResponse(response: ErrorResponse): Map<string, string[]> {
  const errors = response.errors;
  if (errors) {
    return emptyErrors.withMutations((e) =>
      Object.keys(errors).forEach((x) => {
        const arr: string[] = errors[x];
        if (arr && arr.length > 0) {
          e.set(x, arr);
        }
      })
    );
  } else {
    return emptyErrors;
  }
}

export function mapErrorsFromException(error: unknown): ErrorMapType {
  if (isNil(error)) {
    return emptyErrors;
  }

  if (axios.isAxiosError(error) && error.response?.data?.errors) {
    return mapErrorsFromResponse(error.response.data);
  } else if (error instanceof Error) {
    return emptyErrors.set('', [error.message]);
  }

  if (!axios.isCancel(error)) {
    console.error(error);
  }

  return emptyErrors;
}
