import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Record } from 'immutable';
import { noop } from 'lodash';
import * as React from 'react';
import { PageInfo, PageInfoRecord } from '.';

export interface ListPaginationProps {
  pageInfo: PageInfoRecord;
  onSelect(page: number): void;
}

export default function ListPagination({ pageInfo, onSelect }: ListPaginationProps) {
  const startRange = pageInfo.get('startRange', 0);
  const endRange = pageInfo.get('endRange', 0);
  const totalItems = pageInfo.get('totalItems', 0);
  const totalPageCount = pageInfo.get('totalPageCount', 1);
  const page = pageInfo.get('page', 1);
  const prev = page - 1;
  const prevDisabled = prev < 1;
  const next = page + 1;
  const nextDisabled = next > totalPageCount;

  return (
    <div className="flex items-center justify-between border-t border-gray-400 bg-white p-1.5 px-4 sm:px-6">
      <div className="flex flex-1 justify-between sm:hidden">
        <PaginationButton
          page={prevDisabled ? undefined : prev}
          className="hover:bg-gray-50 rounded border-gray-300 bg-white text-gray-700"
          onSelect={onSelect}
        >
          Previous
        </PaginationButton>
        <PaginationButton
          page={nextDisabled ? undefined : next}
          className="hover:bg-gray-50 ml-3 rounded border-gray-300 bg-white text-gray-700"
          onSelect={onSelect}
        >
          Next
        </PaginationButton>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-center md:justify-between">
        <div className="hidden md:block">
          <p className="text-sm text-gray-700">
            Showing <span className="font-medium">{startRange}</span> to <span className="font-medium">{endRange}</span>{' '}
            of <span className="font-medium">{totalItems}</span> results
          </p>
        </div>
        <div>
          <nav className="relative z-0 inline-flex -space-x-px rounded shadow-sm" aria-label="Pagination">
            <PaginationButton
              page={prevDisabled ? undefined : prev}
              className="hover:bg-gray-50 rounded-l border-gray-300 bg-white text-gray-500"
              onSelect={onSelect}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </PaginationButton>
            <PaginationNumbers pageInfo={pageInfo} onSelect={onSelect} />
            <PaginationButton
              page={nextDisabled ? undefined : next}
              className="hover:bg-gray-50 rounded-r border-gray-300 bg-white text-gray-500"
              onSelect={onSelect}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </PaginationButton>
          </nav>
        </div>
      </div>
    </div>
  );
}

interface PaginationNumbersProps {
  pageInfo: Record<PageInfo>;
  onSelect(page: number): void;
}

function PaginationNumbers({ pageInfo, onSelect }: PaginationNumbersProps) {
  const totalPageCount = pageInfo.get('totalPageCount', 1);
  const page = pageInfo.get('page', 1);
  const pageArray = getPageArray(page, totalPageCount);

  return (
    <>
      {pageArray.map((p, i) =>
        p === SeparatorValue ? (
          <PaginationSeparator key={-i} />
        ) : (
          <PaginationButton key={p} active={p === page} page={p} onSelect={onSelect}>
            {p}
          </PaginationButton>
        )
      )}
    </>
  );
}

const PageButtonCount = 7;
const StaticButtonCount = 3;
const PageButtonRange = (PageButtonCount - StaticButtonCount) / 2;
const SeparatorValue = 0;

// Creates an array that represents the page buttons that will be displayed
// Each entry in the array is a page number, 0 corresponds to the separator
function getPageArray(page: number, totalPageCount: number): number[] {
  const pageArray: number[] = [1];

  const atEnd = page + PageButtonRange >= totalPageCount;
  const start = Math.min(
    Math.max(2, atEnd ? totalPageCount - (PageButtonCount - 2) : page - PageButtonRange),
    totalPageCount
  );

  const atStart = page <= PageButtonRange + 1;
  const end = Math.min(atStart ? PageButtonCount - 1 : page + PageButtonRange, totalPageCount - 1);

  for (let x = start; x <= end; x++) {
    pageArray.push(x);
  }

  if (totalPageCount > 1) {
    pageArray.push(totalPageCount);
  }

  if (pageArray.length > 1 && pageArray[1] !== 2) {
    pageArray[1] = SeparatorValue;
  }

  if (pageArray.length === PageButtonCount && pageArray[PageButtonCount - 2] !== totalPageCount - 1) {
    pageArray[PageButtonCount - 2] = SeparatorValue;
  }

  return pageArray;
}

interface PaginationButtonProps {
  children: React.ReactNode;
  active?: boolean;
  page?: number;
  className?: string;
  onSelect(page: number): void;
}

function PaginationButton({ children, active, className, page, onSelect }: PaginationButtonProps) {
  const activeClass = 'z-10 bg-primary-100 border-primary-500 text-primary-600 ';
  const defaultClass = 'bg-white border-gray-300 text-gray-500 hover:bg-gray-50 ';
  const elemClass = active ? activeClass : defaultClass;
  const disabled = active || page === undefined;

  return (
    <button
      disabled={disabled}
      onClick={disabled ? noop : () => onSelect(page)}
      className={'relative inline-flex items-center border px-4 py-2 text-sm font-medium ' + elemClass + className}
    >
      {children}
    </button>
  );
}

function PaginationSeparator() {
  return <PaginationButton onSelect={noop}>...</PaginationButton>;
}
