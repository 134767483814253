import React, { useCallback } from 'react';
import { Button, ConfirmButton, ErrorAlert, PageSection, TextInputGeneric, TextInputProps } from '../common';
import useProfileForm, { ProfileState } from './utils/useProfileForm';
import { CheckIcon, PencilIcon, XIcon } from '@heroicons/react/outline';
import { useNavigate } from 'react-router-dom';

const profilePageSectionTitle = 'Profile';
const profilePageSectionSubtitle =
  'This information will be displayed to others in your organization, so be careful what you share.';

const ProfileForm: React.FC = () => {
  const navigate = useNavigate();
  const { errors, handleChange, handleSave, isExternalLogin, logout, state } = useProfileForm();

  const onClickChangePassword = useCallback(() => navigate({ pathname: '/profile/password' }), [navigate]);

  return (
    <PageSection title={profilePageSectionTitle} subtitle={profilePageSectionSubtitle}>
      <div className="border-gray-300">
        <ErrorAlert errors={errors.get('')} />
        <UserProfileFieldTextBox
          handleSave={handleSave}
          name="firstName"
          onChange={handleChange}
          value={state.firstName}
          label="First Name"
        />
        <UserProfileFieldTextBox
          handleSave={handleSave}
          name="lastName"
          onChange={handleChange}
          value={state.lastName}
          label="Last Name"
        />
        {isExternalLogin ? null : (
          <dl className="border-t divide-y divide-gray-200">
            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:py-3">
              <dt className="text-sm font-medium text-gray-500 self-center">Password</dt>
              <dd>
                <Button onClick={onClickChangePassword} theme="white" className="py-1">
                  Change Password
                </Button>
              </dd>
            </div>
          </dl>
        )}
        <hr />
        <ConfirmButton
          theme="primary"
          onClick={logout}
          confirmMessage="Are you sure you want to logout?"
          className="py-1 mt-3"
        >
          Logout
        </ConfirmButton>
      </div>
    </PageSection>
  );
};

type UserProfileFieldTextBoxProps = Omit<TextInputProps, 'name' | 'value' | 'onChange' | 'label'> & {
  name: keyof ProfileState;
  value: ProfileState[keyof ProfileState];
  onChange(name: keyof ProfileState, value: string): void;
  handleSave: () => Promise<void>;
  label: string;
};

const UserProfileFieldTextBox: React.FC<UserProfileFieldTextBoxProps> = (props: UserProfileFieldTextBoxProps) => {
  const [editing, setEditing] = React.useState(false);

  const startEditing = React.useCallback(() => {
    setEditing(true);
  }, []);

  const endEditing = React.useCallback(() => {
    setEditing(false);
  }, []);

  const handleSave = React.useCallback(() => {
    props.handleSave();
    endEditing();
  }, [endEditing, props]);

  return (
    <dl className="border-t divide-y divide-gray-200">
      <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
        <dt className="text-sm font-medium text-gray-500 self-center">{props.label}</dt>
        {editing ? (
          <dd className="mt-1 flex gap-2 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <TextInputGeneric<ProfileState>
              name={props.name}
              value={props.value}
              onChange={props.onChange}
              errors={props.errors}
              containerClassName="sm:col-span-4 pb-1 mb-1"
              autoFocus={true}
            />
            <Button theme="white" className="self-center p-0 hover:bg-gray-200" onClick={handleSave}>
              <CheckIcon className="h-7 w-7 m-1 text-resolve-green-dark" />
            </Button>
            <Button theme="white" className="self-center p-0 hover:bg-gray-200" onClick={endEditing}>
              <XIcon className="h-7 w-7 m-1 text-resolve-red-dark" />
            </Button>
          </dd>
        ) : (
          <dd className="group mt-1 flex gap-2 cursor-default text-sm text-gray-900 sm:col-span-2 sm:mt-0 w-fit">
            <span className="self-center">{props.value}</span>
            <Button
              theme="white"
              className="self-center p-0 hidden group-hover:flex hover:bg-gray-200"
              onClick={startEditing}
            >
              <PencilIcon className="h-5 w-5 m-1 text-gray-700" />
            </Button>
          </dd>
        )}
      </div>
    </dl>
  );
};

export default ProfileForm;
