import { Dialog, Transition, TransitionEvents } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import { Fragment, PropsWithChildren } from 'react';
import { mergeClasses, WithClassName } from '.';

export type PanelProps = TransitionEvents &
  WithClassName & {
    children?: React.ReactNode;
    open: boolean;
    initialFocus?: React.MutableRefObject<HTMLElement | null> | undefined;
    onClose(): void;
    unmount?: boolean;
  };

export function Panel({ children, className, open, initialFocus, unmount, onClose, ...events }: PanelProps) {
  return (
    <Transition.Root {...events} show={open} as={Fragment} unmount={unmount !== false}>
      <Dialog as="div" className="fixed inset-0 z-30 overflow-hidden" onClose={onClose} initialFocus={initialFocus}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-50"
            entered="opacity-50"
            leave="ease-in duration-200"
            leaveFrom="opacity-50"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-700" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className={mergeClasses('w-screen max-w-md', className)}>{children}</div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export type ContentProps = WithClassName<Pick<PanelProps, 'onClose' | 'children'>> & {
  title: string;
  subtitle?: string;
};

export function Content({ children, className, title, subtitle, onClose }: ContentProps) {
  return (
    <div className={mergeClasses('flex h-full flex-col bg-white shadow-xl', className)}>
      <div className="bg-primary px-4 py-6 text-white sm:px-6">
        <div className="flex items-center justify-between">
          <Dialog.Title className="text-lg font-medium text-white">{title}</Dialog.Title>
          <div className="ml-3 flex h-7 items-center">
            <button
              type="button"
              className="rounded bg-primary hover:text-primary-200 focus:outline-none focus:ring-2 focus:ring-white"
              onClick={onClose}
            >
              <span className="sr-only">Close panel</span>
              <XIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        {subtitle === undefined ? null : (
          <div className="mt-1">
            <p className="text-sm">{subtitle}</p>
          </div>
        )}
      </div>
      {children}
    </div>
  );
}

export type BodyProps = WithClassName<PropsWithChildren<{}>>;

export function Body({ children, className }: BodyProps) {
  return <div className={mergeClasses('relative flex-1 overflow-hidden py-6 px-4 sm:px-6', className)}>{children}</div>;
}

export type ActionsProps = WithClassName<PropsWithChildren<{}>>;

export function Actions({ children, className }: ActionsProps) {
  return (
    <div className={mergeClasses('flex flex-shrink-0 justify-end border-t border-gray-400 px-4 py-4', className)}>
      {children}
    </div>
  );
}
