import { DependencyList, useEffect, useRef, useState } from 'react';
import { ApiBase } from '../apiBase';
import useErrors from './useErrors';
import { ErrorMapType } from '../components/common/';

export type ApiEffectCallback<TApi extends ApiBase> = (api: TApi) => void | (() => void | undefined);

export function useApiEffect<TApi extends ApiBase>(
  apiClass: Clazz<TApi>,
  effect: ApiEffectCallback<TApi>,
  deps: DependencyList
) {
  useEffect(() => {
    const api = new apiClass();
    const destructor = effect(api);
    return () => {
      api.cancel();
      if (destructor) {
        destructor();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiClass, ...deps]);
}

export function useApiClass<TApi extends ApiBase>(apiClass: Clazz<TApi>): TApi {
  const api = useRef(new apiClass()).current;

  useEffect(() => api.cancel, [api.cancel]);
  return api;
}

export interface UseApiMethodState<TData> {
  loading: boolean;
  data: TData | null;
  errors: ErrorMapType;
}

export function useApiMethod<TApi extends ApiBase, TReturn>(
  apiClass: Clazz<TApi>,
  func: ApiCall<TApi, TReturn>,
  deps?: DependencyList
): UseApiMethodState<TReturn> {
  const api = useRef(new apiClass()).current;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<TReturn | null>(null);
  const { errors, clear: clearErrors, catchErrors } = useErrors();
  const definedDeps = deps ? deps : [];

  useEffect(() => {
    setLoading(true);
    clearErrors();

    func(api)
      .then((d) => {
        setData(d);
      })
      .catch(catchErrors)
      .then(() => {
        setLoading(false);
      });

    return api.cancel;
  }, [...definedDeps, api, catchErrors, clearErrors]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    loading,
    data,
    errors,
  };
}

export interface Clazz<T> {
  new (): T;
}

export interface ApiCall<TApi, TReturn> {
  (api: TApi): Promise<TReturn>;
}
