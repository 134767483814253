import { PlusIcon } from '@heroicons/react/solid';
import React, { SVGProps } from 'react';
import { To } from 'react-router-dom';
import { Button, mergeClasses } from '.';

export interface EmptyListPlaceholderProps {
  item?: string;
  itemPlural?: string;
  icon?: React.ComponentType<SVGProps<SVGSVGElement>>;
  children?: React.ReactNode;
  addLocation?: To;
  className?: string;
}

export default function EmptyListPlaceholder({
  icon: Icon,
  item,
  itemPlural,
  children,
  addLocation,
  className,
}: EmptyListPlaceholderProps) {
  return (
    <div
      className={mergeClasses(
        'relative w-full rounded border-2 border-dashed border-gray-400 bg-white p-12 text-center text-sm',
        className
      )}
    >
      {children === undefined ? (
        <>
          {Icon === undefined ? null : <Icon className="mx-auto h-12 w-12 text-gray-600" />}

          <h3 className="mt-2 text-sm text-gray-900">
            {itemPlural === undefined ? 'Empty' : 'There are no ' + itemPlural}
          </h3>
          {addLocation === undefined ? undefined : (
            <>
              <div className="mt-6 py-0">
                <Button to={addLocation} className="py-1">
                  <PlusIcon className="mr-2 -ml-1 h-5 w-5" aria-hidden="true" />
                  New {item ?? 'item'}
                </Button>
              </div>
            </>
          )}
        </>
      ) : (
        children
      )}
    </div>
  );
}
