import React from 'react';
import { WithClassName } from '..';
import Alert from '../Alert';
import { ErrorType, normalizeErrors } from './renderErrors';

export type ErrorAlertProps = WithClassName & {
  errors: ErrorType;
};
export default function ErrorAlert({ errors, className }: ErrorAlertProps) {
  const normalErrors = normalizeErrors(errors);
  if (normalErrors.length === 0) {
    return null;
  }

  if (normalErrors.length === 1) {
    const err = normalErrors[0];
    return (
      <Alert theme="error" className={className}>
        {err}
      </Alert>
    );
  }

  return (
    <Alert theme="error" className={className}>
      <h3 className="text-sm font-medium text-red-800">There were {normalErrors.length} errors</h3>
      <div className="mt-2 text-sm text-red-700">
        <ul className="list-disc pl-5 space-y-1">
          {normalErrors.map((err, i) => (
            <li key={i}>{err}</li>
          ))}
        </ul>
      </div>
    </Alert>
  );
}
