import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import Config from '../Config';
import { NotificationLevel, notify } from '../global/Notifications';
import authService, { AuthenticationService } from './authService';
import history from './history';

export const REQUEST_CANCELED = 'REQUEST_CANCELED';

export default function createHttp(handleErrors: boolean = true, excludeCodes: Array<number> = []) {
  const http = axios.create({
    baseURL: Config.ApiURI,
  });

  http.interceptors.request.use((request) => {
    addAuthHeader(request);
    return request;
  });

  // handle 401
  createAuthRefreshInterceptor(http, refreshToken);

  http.interceptors.response.use(
    (response) => response,
    function (error: AxiosError) {
      const { response, message } = error;
      if (!response) {
        if (message !== REQUEST_CANCELED) {
          dispatchServerError(message);
        }

        return Promise.reject(error);
      }

      const { status: statusCode, data } = response;
      switch (httpStatusClass(statusCode)) {
        case '4XX': {
          // Let the caller handle any error conditions
          if (excludeCodes.includes(statusCode)) {
            break;
          }

          if (statusCode === 403) {
            // FORBIDDEN, reload profile/permissions
            window.location.assign('/');
            break;
          }

          if (handleErrors) {
            if (data && data.errors && data.errors['']) {
              const errorMsgs: string[] = data.errors[''];
              if (errorMsgs.length > 0) {
                errorMsgs.forEach((x) => {
                  notify(x, x, NotificationLevel.Error);
                });
              }
            }

            dispatchClientError(message, data);
            break;
          }
          break;
        }

        default:
          dispatchServerError(message, data);
      }

      return Promise.reject(error);
    }
  );

  return http;
}

export function httpStatusClass(statusCode: number): string | null {
  let matches = statusCode.toString().match(/^(\d)\d\d$/);

  return matches ? matches[1] + 'XX' : null;
}

export function dispatchClientError(message: string, data?: any) {
  const logMessage = [message, data.toString()].join(' ').trim();

  console.log('Client Error! There was a problem with the request. ' + logMessage);
}

function dispatchServerError(message: string, data?: any) {
  notify('Server Error!', 'There was a problem communicating with the server. ' + message, NotificationLevel.Error);

  const logMessage = [message, data.toString()].join(' ').trim();

  console.log('Server Error! There was a problem communicating with the server. ' + logMessage);
}

async function refreshToken(request: AxiosRequestConfig): Promise<void> {
  const location = history.location;
  if (!location) {
    return;
  }

  const auth = authService.get();
  await auth.silentLogin(location.pathname);

  addAuthHeader(request, auth);
}

function addAuthHeader(request: AxiosRequestConfig, auth?: AuthenticationService): void {
  if (!request.headers) {
    request.headers = {};
  }

  request.headers.Authorization = 'Bearer ' + (auth ?? authService.get()).getAccessToken();
}
