import { matchPath, useLocation } from 'react-router-dom';

export function useMatchPath(url: string) {
  const location = useLocation();
  const match = matchPath({ path: url, end: false }, location.pathname);

  const localPathMatch = (subPath: string): boolean =>
    matchPath({ path: match?.pathnameBase + subPath, end: false }, location.pathname) !== null;
  return {
    localPathMatch,
    match,
  };
}
