import React from 'react';
import { useLocation } from 'react-router-dom';
import { ApiBase } from '../apiBase';
import { PageInfoRecord, SortDirection, SortInfoRecord } from '../components/common';
import { useApiMethod } from './useApi';
import { useIndexStorage } from './useIndexStorage';
import {
  getInitialListPageState,
  ListPageReducer,
  ListPageReducerActions,
  ListPageState,
  ListResponse,
  MandatoryInitialState,
} from './useListPageHelpers';

type ListFunction<TData, TFilters> = (
  pageInfo: PageInfoRecord,
  sortInfo: SortInfoRecord,
  filterInfo: TFilters
) => Promise<ListResponse<TData>>;
type FunctionPropertyNames<TApi, TData, TFilters> = {
  [K in keyof TApi]: TApi[K] extends ListFunction<TData, TFilters> ? K : never;
}[keyof TApi];

const useListPageState = <TData>() => {
  const useListPageState = <TFilters, TApi extends ApiBase, TDataType = TData>(
    apiClass: Clazz<TApi>,
    initialState: MandatoryInitialState<TFilters>,
    listRetrievalMethod: FunctionPropertyNames<TApi, TDataType, TFilters>
  ) => {
    type TState = ListPageState<TDataType, TFilters>;
    const { indexStates, setIndexStates } = useIndexStorage();
    const indexStatesRef = React.useRef(indexStates);
    const setIndexStatesRef = React.useRef(setIndexStates);
    let initialStateWithStorage = React.useRef<MandatoryInitialState<TFilters>>(initialState);

    const [state, dispatch] = React.useReducer((s: TState, a: ListPageReducerActions<TDataType, TFilters>) => {
      let newState = ListPageReducer<TDataType, TFilters, TState>(s, a);
      return newState;
    }, getInitialListPageState<TDataType, TFilters>(initialStateWithStorage.current));
    const [isFilterRetrievalComplete, setIsFilterRetrievalComplete] = React.useState(false);
    type TFilterKey = keyof TFilters;
    const location = useLocation();
    const pathKeyRef = React.useRef(normalizePath(location.pathname));

    React.useEffect(() => {
      indexStatesRef.current = indexStates;
    }, [indexStates]);

    React.useEffect(() => {
      setIndexStatesRef.current = setIndexStates;
    }, [setIndexStates]);

    React.useEffect(() => {
      pathKeyRef.current = normalizePath(location.pathname);
    }, [location.pathname]);

    React.useEffect(() => {
      const pageSavedState = indexStatesRef.current.get(pathKeyRef.current);
      if (pageSavedState) {
        dispatch({
          type: 'setInitialState',
          payload: { filters: pageSavedState.filters, page: pageSavedState.page, sortInfo: pageSavedState.sort },
        });
      }
      setIsFilterRetrievalComplete(true);
    }, []);

    useApiMethod(
      apiClass,
      (api) => {
        // The typings here aren't translating correctly, but listRetrievalMethod should be
        // the set of property names from TApi that match the ListFunction<TDataType> signature,
        // so we should have fairly strong type safety for consumers of this hook.
        const func: ListFunction<TDataType, TFilters> = api[listRetrievalMethod] as any;
        if (isFilterRetrievalComplete) {
          return func(state.pageInfo.set('page', state.page), state.sortInfo, state.filters).then((data) => {
            dispatch({
              type: 'loadedList',
              payload: data,
            });
          });
        } else {
          return Promise.resolve();
        }
      },
      [state.page, state.sortInfo, state.reloadIteration, state.filters, isFilterRetrievalComplete]
    );

    React.useEffect(() => {
      setIndexStatesRef.current(pathKeyRef.current, { filters: state.filters, page: state.page, sort: state.sortInfo });
    }, [state.filters, state.page, state.sortInfo]);

    const changePage = (pageNumber: number) => {
      dispatch({ type: 'changePage', payload: pageNumber });
    };
    const changeSort = (sortInfo: { column: string; direction: SortDirection }) => {
      dispatch({ type: 'changeSort', payload: sortInfo });
    };
    const changeFilter = (filterInfo: { filterName: TFilterKey; value: TFilters[TFilterKey] }) => {
      dispatch({ type: 'changeFilter', payload: { filterName: filterInfo.filterName, value: filterInfo.value } });
    };
    const resetFilters = (filterInfo: TFilters) => {
      dispatch({ type: 'resetFilters', payload: filterInfo });
    };
    const reloadList = React.useCallback(() => {
      dispatch({ type: 'reloadList' });
    }, []);

    return {
      state: state,
      actions: {
        changePage: changePage,
        changeSort: changeSort,
        changeFilter: changeFilter,
        resetFilters: resetFilters,
        reloadList: reloadList,
      },
    };
  };
  return useListPageState;
};
export default useListPageState;

interface Clazz<T> {
  new (): T;
}

const normalizePath = (path: string) => {
  if (path.endsWith('/')) {
    return path.substr(0, path.length - 1);
  } else {
    return path;
  }
};
