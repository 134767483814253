import { Map } from 'immutable';
import * as React from 'react';
import { SortInfoRecord } from '../components/common';

interface IndexState {
  page: number;
  sort: SortInfoRecord;
  filters: any;
}

type IndexStorageMap = Map<string, IndexState>;

interface IndexStateStorage {
  state: IndexStorageMap;
  update: (pageKey: string, newState: IndexState) => void;
}

let initialIndexState = Map<string, IndexState>();

const IndexStorageContext = React.createContext<IndexStateStorage>({
  state: initialIndexState,
  update: () => undefined,
});

export interface IndexStorageProviderProps {
  children: React.ReactChild;
}
export const IndexStorageProvider = (props: IndexStorageProviderProps) => {
  const [state, setState] = React.useState(initialIndexState);
  const changeState = (pageKey: string, newState: IndexState) => {
    setState(state.setIn([pageKey], newState));
  };

  return (
    <IndexStorageContext.Provider value={{ state, update: changeState }}>{props.children}</IndexStorageContext.Provider>
  );
};

export function useIndexStorage() {
  let { state: untypedIndexStates, update: untypedSetIndexStates } = React.useContext(IndexStorageContext);
  const indexStates = untypedIndexStates as IndexStorageMap;
  const setIndexStates = untypedSetIndexStates as (pageKey: string, newState: IndexState) => void;
  return { indexStates, setIndexStates };
}
