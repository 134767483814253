import { ArrowLeftIcon } from '@heroicons/react/outline';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { AnimatedLoadingOverlay, Button, mergeClasses, NavigateParams } from '.';

export type PageTemplateProps = InnerPageLayoutProps & {
  loading?: boolean;
};

export const windowTitlePrefix = 'BAS • ';

export default function PageTemplate({ loading, title, ...props }: PageTemplateProps) {
  // We only want to show the loading takeover on initial load
  const [initialLoading, setInitialLoading] = useState(loading);
  useEffect(() => {
    setInitialLoading((prev) => (prev === false ? false : loading ?? false));
  }, [loading]);

  return (
    <>
      <Helmet title={windowTitlePrefix + (typeof title === 'string' ? title : title.text)} />
      <AnimatedLoadingOverlay show={initialLoading ?? false} />
      <InnerPageLayout {...props} title={title} />
    </>
  );
}

type PageTitle = string | { element?: React.ReactElement; text: string };

interface InnerPageLayoutProps {
  title: PageTitle;
  subtitle?: string | JSX.Element;
  backButtonLocation?: string | NavigateParams;
  actionsSection?: JSX.Element;
  children?: React.ReactChild;
  className?: string;
}

function InnerPageLayout({
  title,
  subtitle,
  backButtonLocation,
  actionsSection,
  className,
  children,
}: InnerPageLayoutProps) {
  const linkAttribs: NavigateParams =
    typeof backButtonLocation === 'string' || typeof backButtonLocation === 'undefined'
      ? { to: backButtonLocation ?? '' }
      : backButtonLocation;
  return (
    <div className="my-5">
      <div className="mx-auto flex px-4 sm:flex-row sm:items-start sm:px-6 lg:px-8">
        {backButtonLocation === undefined ? null : (
          <Button {...linkAttribs} theme="white" className="mr-2 flex-initial p-2">
            <ArrowLeftIcon className="hidden h-4 w-4 sm:block" />
            <span className="sm:hidden">Back</span>
          </Button>
        )}
        <div className="flex-1">
          {typeof title === 'string' ? (
            <h1 className="mb-3 text-xl font-semibold text-gray-800 md:text-2xl">{title}</h1>
          ) : (
            title.element
          )}
        </div>
        {actionsSection === undefined ? null : <div className="flex space-x-2 space-y-0">{actionsSection}</div>}
      </div>
      {subtitle === undefined ? null : (
        <h2 className="w-full text-base text-gray-800 sm:px-5 md:text-sm lg:px-8 lg:text-base">{subtitle}</h2>
      )}
      <div className={mergeClasses('mx-auto mt-3 px-4 drop-shadow sm:px-6 md:px-8', className)}>{children}</div>
    </div>
  );
}
