import { useCallback, useEffect, useState } from 'react';
import AnimateHeight, { AnimateHeightProps } from 'react-animate-height';

export interface AnimatedToggleProps extends AnimateHeightProps {
  visible: boolean;
  unmount?: boolean;
}

export default function AnimatedToggle({
  height,
  visible,
  children,
  unmount = true,
  onAnimationEnd,
  ...props
}: AnimatedToggleProps) {
  const innerHeight = height ?? 'auto';
  const animateHeight = visible ? innerHeight : 0;
  const [shouldRender, setShouldRender] = useState(animateHeight !== 0 || !unmount);

  useEffect(() => {
    if (!shouldRender && (animateHeight !== 0 || !unmount)) {
      setShouldRender(true);
    }
  }, [animateHeight, shouldRender, unmount]);

  const handleOnAnimationEnd = useCallback(
    (props: { newHeight: number }) => {
      if (shouldRender && unmount && props.newHeight === 0) {
        setShouldRender(false);
      }

      if (onAnimationEnd) {
        onAnimationEnd(props);
      }
    },
    [onAnimationEnd, shouldRender, unmount]
  );

  // When expanding, make sure the element is mounted with a height of 0 before expanding
  const willRender = shouldRender || animateHeight !== 0 || !unmount;
  const renderHeight = shouldRender ? animateHeight : 0;

  return willRender ? (
    <AnimateHeight {...props} height={renderHeight} onAnimationEnd={handleOnAnimationEnd}>
      {children}
    </AnimateHeight>
  ) : null;
}
