import React from 'react';
import { forwardRef, InputHTMLAttributes } from 'react';
import { mergeClasses } from '.';

export type CheckboxThemeName = 'standard' | 'error';
export interface CheckboxThemeInfo {
  input: string;
}

export type CheckboxTheme = CheckboxThemeName | CheckboxThemeInfo;

const DefaultClassName = 'h-4 w-4 rounded ';
const StandardClassName = DefaultClassName + 'text-primary focus:ring-primary border-gray-400 ';
const ErrorClassName =
  DefaultClassName +
  'border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500 ';

const themes: Record<CheckboxThemeName, CheckboxThemeInfo> = {
  standard: { input: StandardClassName },
  error: { input: ErrorClassName },
};

export enum CheckboxState {
  Checked,
  Unchecked,
  Indeterminate,
}

export type CheckboxProps = Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'checked'> & {
  theme: CheckboxTheme;
  checked: CheckboxState | boolean;
};

export default forwardRef(function Checkbox(
  { className, theme, checked, ...props }: CheckboxProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  if (typeof theme === 'undefined') {
    theme = 'standard';
  }

  let info: CheckboxThemeInfo;
  if (typeof theme === 'string') {
    info = themes[theme];
    if (info === undefined) {
      throw Error('Invalid theme: ' + theme);
    }
  } else {
    info = theme;
  }

  const checkboxRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    if (checkboxRef.current !== null) {
      if (typeof checked === 'boolean') {
        if (checked) {
          checkboxRef.current.indeterminate = false;
          checkboxRef.current.checked = true;
        } else {
          checkboxRef.current.indeterminate = false;
          checkboxRef.current.checked = false;
        }
      } else {
        if (checked === CheckboxState.Indeterminate) {
          checkboxRef.current.indeterminate = true;
          checkboxRef.current.checked = true; //true causes the checkbox to deselect all when indeterminate checkbox is clicked.
        } else if (checked === CheckboxState.Checked) {
          checkboxRef.current.indeterminate = false;
          checkboxRef.current.checked = true;
        } else if (checked === CheckboxState.Unchecked) {
          checkboxRef.current.indeterminate = false;
          checkboxRef.current.checked = false;
        }
      }
    }
  }, [checked]);

  return (
    <input
      {...props}
      checked={typeof checked === 'boolean' ? checked : checked === CheckboxState.Unchecked ? false : true}
      type="checkbox"
      className={mergeClasses(info.input, className)}
      ref={(element) => {
        checkboxRef.current = element;

        if (typeof ref === 'function') {
          ref(element);
        } else if (ref !== null) {
          ref.current = element;
        }
      }}
    />
  );
});
