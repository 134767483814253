import { TrashIcon } from '@heroicons/react/outline';
import * as React from 'react';
import { Button, mergeClasses } from '.';
import ConfirmationDialog from './ConfirmationDialog';
import { DELETE } from './Constants';

export type DeleteButtonProps = Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> & {
  confirmMessage?: string;
  onClick(): void;
  iconClassName?: string;
};

function DeleteButton({
  title,
  confirmMessage,
  onClick,
  children,
  className,
  iconClassName,
  ...props
}: DeleteButtonProps) {
  const [showDialog, setShowDialog] = React.useState(false);
  const onShowDialog = () => setShowDialog(true);
  const onCloseDialog = () => setShowDialog(false);
  const onHandleAccept = () => {
    onCloseDialog();
    onClick();
  };

  return (
    <>
      <Button
        {...props}
        title={title ?? DELETE}
        onClick={onShowDialog}
        theme="white"
        className={mergeClasses('my-0 px-2', className)}
      >
        {children ?? <TrashIcon className={mergeClasses('h-4 w-4', iconClassName)} />}
      </Button>
      <ConfirmationDialog
        title="Are you sure?"
        message={confirmMessage || 'Are you sure you want to delete this item?'}
        show={showDialog}
        onAccept={onHandleAccept}
        onDecline={onCloseDialog}
      />
    </>
  );
}

export default DeleteButton;
