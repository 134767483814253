import { List } from 'immutable';
import {
  PageInfoRecord,
  PageInfoRecordFactory,
  SortDirection,
  SortInfoRecord,
  SortInfoRecordFactory,
} from '../components/common';
import { NamedAction, NamedActionPayload } from '../global/actions';

export type ListPageReducerActions<TDataType, TFilter> =
  | NamedActionPayload<'loadedList', { list: List<TDataType>; pageInfo: PageInfoRecord }>
  | NamedActionPayload<'changePage', number>
  | NamedActionPayload<'changeSort', { column: string; direction: SortDirection }>
  | NamedActionPayload<'changeFilter', { filterName: keyof TFilter; value: TFilter[keyof TFilter] }>
  | NamedActionPayload<'resetFilters', TFilter>
  | NamedAction<'reloadList'>
  | NamedActionPayload<'setInitialState', MandatoryInitialState<TFilter>>;

export interface ListPageState<T, F> {
  loading: boolean;
  loadingList: boolean;
  list: List<T>;
  pageInfo: PageInfoRecord;
  sortInfo: SortInfoRecord;
  page: number;
  reloadIteration: number;
  filters: F;
}

export interface UseListPageStateActions<TFilters> {
  changePage(pageNumber: number): void;
  changeSort(sortInfo: { column: string; direction: SortDirection }): void;
  changeFilter(filterInfo: { filterName: keyof TFilters; value: TFilters[keyof TFilters] }): void;
  resetFilters(filterInfo: TFilters): void;
  reloadList(): void;
}

export type ListResponse<TData> = { list: List<TData>; pageInfo: PageInfoRecord };

export type ListPageStateAndActions<T, F> = ListPageState<T, F> & UseListPageStateActions<F>;

export interface MandatoryInitialState<F> {
  filters: F;
  sortInfo?: SortInfoRecord;
  page?: number;
}

export function getInitialListPageState<T, F>(state: MandatoryInitialState<F>): ListPageState<T, F> {
  return {
    loading: true,
    loadingList: false,
    list: List<T>(),
    pageInfo: new PageInfoRecordFactory(),
    sortInfo: state.sortInfo || new SortInfoRecordFactory(),
    page: 1,
    reloadIteration: 1,
    filters: state.filters,
  };
}

export function ListPageReducer<TDataType, TFilter, TState extends ListPageState<TDataType, TFilter>>(
  state: TState,
  action: ListPageReducerActions<TDataType, TFilter>
): TState {
  switch (action.type) {
    case 'loadedList':
      return {
        ...state,
        loading: false,
        loadingList: false,
        list: action.payload.list,
        pageInfo: action.payload.pageInfo,
      };
    case 'changePage':
      return {
        ...state,
        page: action.payload,
        loadingList: true,
      };
    case 'changeSort':
      return {
        ...state,
        page: 1,
        sortInfo: state.sortInfo.set('column', action.payload.column).set('direction', action.payload.direction),
        loadingList: true,
      };
    case 'changeFilter':
      return {
        ...state,
        filters: { ...state.filters, [action.payload.filterName]: action.payload.value },
        page: 1,
        pageInfo: state.pageInfo.set('page', 1),
      };
    case 'reloadList':
      return { ...state, reloadIteration: state.reloadIteration + 1 };
    case 'resetFilters':
      return { ...state, filters: action.payload };
    case 'setInitialState':
      return {
        ...state,
        filters: action.payload.filters,
        page: action.payload.page,
        sortInfo: action.payload.sortInfo,
      };
    default:
      throw new Error();
  }
}
