import * as ReactDOM from 'react-dom';
import 'react-phone-input-2/lib/style.css';
import App from './components/App';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import './app.css';
import AppError from './components/AppError';

// break out of iframe before loading app
if (window.top && window.top.location !== window.self.location) {
  window.top.location.href = window.self.location.href;
}

try {
  ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);
} catch (e) {
  ReactDOM.render(
    <AppError>
      There was an error executing the application. This is most likely caused by using an unsupported browser. Please
      update to a more modern browser and try again.
    </AppError>,
    document.getElementById('root') as HTMLElement
  );
}
