import { Transition } from '@headlessui/react';
import { MenuIcon } from '@heroicons/react/outline';
import React, { ReactElement, useCallback, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useLocation, useOutlet } from 'react-router-dom';
import { handleCaughtError } from '../util/handleErrors';
import AppError from './AppError';
import { LoadingOverlay } from './common';
import DeprecationBanner from './DeprecationBanner';

export interface PageContainerProps {
  setMobileMenuOpen(open: boolean): void;
}

export default function PageContainer({ setMobileMenuOpen }: PageContainerProps) {
  const { pathname } = useLocation();
  return (
    <div className="flex min-h-screen flex-1 flex-col md:pl-64">
      <div className="sticky top-0 z-10 bg-gray-100 pt-1 pl-1 sm:pl-3 sm:pt-3 md:hidden">
        <button
          type="button"
          className="-ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <main className="flex-1">
        <ErrorBoundary FallbackComponent={AppError} resetKeys={[pathname]} onError={handleCaughtError}>
          <FadeOutOutlet />
        </ErrorBoundary>
      </main>
    </div>
  );
}

function FadeOutOutlet() {
  // const { pathname } = useLocation();
  const outlet = useOutlet();

  // Cache the last outlet value so we can display it during the exit transition.
  const [outletCache, setOutletCache] = useState<ReactElement | null>(outlet);

  if (outletCache === null && outlet !== null) {
    setOutletCache(outlet);
  }

  const clearOutletCache = useCallback(() => {
    setOutletCache(outlet === null ? null : outlet);
  }, [outlet]);

  return (
    <Transition
      as="div"
      show={outletCache?.type === outlet?.type}
      className="relative min-h-screen w-full"
      leave="transform duration-200 transition ease-in-out"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={clearOutletCache}
    >
      <DeprecationBanner />
      <React.Suspense fallback={<LoadingOverlay />}>
        <div data-testing>{outletCache?.type === outlet?.type ? outlet : outletCache}</div>
      </React.Suspense>
    </Transition>
  );
}
