import axios from 'axios';
import { Map } from 'immutable';
import { useCallback, useState } from 'react';
import { ErrorMapType, mapErrorsFromException } from '../components/common';

const emptyErrors = Map<string, string[]>();

export default function useErrors() {
  const [errors, setErrors] = useState<ErrorMapType>(emptyErrors);
  const clear = useCallback(
    (key?: string) => (typeof key === 'string' ? setErrors((prevErr) => prevErr.delete(key)) : setErrors(emptyErrors)),
    []
  );
  const addError = useCallback(
    (key: string, error: string) => setErrors((prevErr) => prevErr.update(key, (val = []) => val.concat(error))),
    []
  );
  const catchErrors = useCallback((error: unknown, rethrowUnhandled: boolean = true) => {
    if (axios.isCancel(error)) {
      if (rethrowUnhandled) {
        throw error;
      }
    }
    setErrors(mapErrorsFromException(error));
  }, []);
  return {
    errors,
    setErrors,
    clear,
    addError,
    catchErrors,
  };
}
