import { Record } from 'immutable';

export interface PageInfo {
  page: number;
  pageSize: number;
  totalItems: number;
  totalPageCount: number;
  startRange: number;
  endRange: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
}

export type PageInfoRecord = Record<PageInfo>;
export const PageInfoRecordFactory = Record<PageInfo>(
  {
    page: 1,
    pageSize: 20,
    totalItems: 0,
    totalPageCount: 1,
    startRange: 0,
    endRange: 0,
    hasPreviousPage: false,
    hasNextPage: false,
  },
  'PageInfo'
);

export interface PagedList {
  pageInfo: PageInfo;
}
