import { SparklesIcon, XIcon } from '@heroicons/react/outline';
import React, { ForwardedRef } from 'react';

export interface CustomNotificationProps {
  header?: string;
  message: string;
  actionText?: string;
  onActionClicked?: () => void;
  close(): void;
}

export const CustomNotification = React.forwardRef(
  (
    { header, message, actionText, onActionClicked, close }: CustomNotificationProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <SparklesIcon className="h-6 w-6 text-green-600" aria-hidden="true" />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {header ? <p className="text-sm font-medium text-gray-900">{header}</p> : null}
              <p className="mt-1 text-sm text-gray-700">{message}</p>
              <div className="flex mt-3 space-x-7">
                {actionText !== '' && onActionClicked && (
                  <button
                    className="text-sm font-medium rounded-md text-primary focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                    onClick={onActionClicked}
                  >
                    {actionText}
                  </button>
                )}
                <button
                  className="text-sm font-medium rounded-md text-gray-900 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                  onClick={close}
                >
                  Dismiss
                </button>
              </div>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5 text-gray-900" aria-hidden="true" onClick={close} />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
