import React from 'react';

export type FutureDispatch<TAction> = (value: TAction, timeoutMs?: number) => void;

export default function useFutureState<TState>(
  initialState: TState | (() => TState)
): [TState, FutureDispatch<React.SetStateAction<TState>>] {
  const timeoutHandle = React.useRef<number>(-1);
  const [state, setState] = React.useState<TState>(initialState);

  React.useEffect(() => {
    return () => {
      if (timeoutHandle.current !== -1) {
        window.clearTimeout(-1);
        timeoutHandle.current = -1;
      }
    };
  });

  const handleSetState: FutureDispatch<React.SetStateAction<TState>> = React.useCallback(
    (value: React.SetStateAction<TState>, timeoutMs?: number) => {
      if (timeoutHandle.current !== -1) {
        if (process.env.NODE_ENV === 'development') {
          console.warn(
            'useFutureState: setState was called before a previous call to setState was applied.  The previous call will be ignored.'
          );
        }
        window.clearTimeout(timeoutHandle.current);
        timeoutHandle.current = -1;
      }

      const timeout = timeoutMs ?? 0;
      if (timeout <= 0) {
        setState(value);
      } else {
        timeoutHandle.current = window.setTimeout(setState, timeout, value);
      }
    },
    []
  );

  return [state, handleSetState];
}
