import React from 'react';
import { ExclamationCircleIcon } from '@heroicons/react/solid';

export interface ErrorMarkerProps {
  hasError: boolean;
  className?: string;
  iconClassName?: string;
}

export default function ErrorMarker({ hasError, className, iconClassName }: ErrorMarkerProps) {
  if (!hasError) {
    return null;
  }

  return (
    <div className={className ?? 'absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none'}>
      <ExclamationCircleIcon className={iconClassName ?? 'h-5 w-5 text-red-500'} aria-hidden="true" />
    </div>
  );
}
