import { useCallback, useState } from 'react';

export interface UseConfirmDialogData {
  show: boolean;
  showDialog(): void;
  hideDialog(): void;
  onAccept(): void;
  onDecline(): void;
}

export type ConfirmDialogCallback = () => void;

export default function useConfirmDialog(
  onAccept: ConfirmDialogCallback,
  onDecline?: ConfirmDialogCallback,
  initialState?: boolean
): UseConfirmDialogData {
  const [showDialog, setShowDialog] = useState(initialState ?? false);

  const handleShowDialog = useCallback(() => {
    setShowDialog(true);
  }, []);

  const handleCloseDialog = useCallback(() => {
    setShowDialog(false);
    if (onDecline) {
      onDecline();
    }
  }, [onDecline]);

  const handleAccept = useCallback(() => {
    setShowDialog(false);
    if (onAccept) {
      onAccept();
    }
  }, [onAccept]);

  return {
    show: showDialog,
    showDialog: handleShowDialog,
    hideDialog: handleCloseDialog,
    onAccept: handleAccept,
    onDecline: handleCloseDialog,
  };
}
