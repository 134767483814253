import { PencilAltIcon } from '@heroicons/react/outline';
import { To } from 'history';
import * as React from 'react';
import { Button, mergeClasses } from '.';

export interface EditLinkButtonProps {
  to: To;
  title?: string;
  className?: string;
}

function EditLinkButton({ to, title, className }: EditLinkButtonProps) {
  if (to === undefined) {
    throw Error('The "to" prop is required');
  }

  return (
    <Button to={to} className={mergeClasses('my-0 px-2', className)} title={title ?? 'Edit'} theme="white">
      <PencilAltIcon className="h-4 w-4" />
    </Button>
  );
}

export default EditLinkButton;
