import axios, { AxiosInstance, CancelToken } from 'axios';
import * as React from 'react';
import createHttp from '../util/http';

export default function useHttp(handleErrors: boolean = true): [AxiosInstance, CancelToken] {
  const cancelToken = React.useRef(axios.CancelToken.source()).current;
  const http = React.useRef(createHttp(handleErrors)).current;

  React.useEffect(() => {
    return cancelToken.cancel;
  }, [cancelToken.cancel]);

  return [http, cancelToken.token];
}
