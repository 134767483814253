import { RadioGroup } from '@headlessui/react';
import { PhoneIcon } from '@heroicons/react/outline';
import { Button, ConfirmationDialog, PageSection, mergeClasses } from '../../common';
import { UserMFAType } from '../enums/UserMFAType.enum';
import useMfaForm from './useMfaForm';
import { useNavigate } from '../../../hooks';

const mfaPageSectionTitle = 'MFA';
const mfaPageSectionSubTitle = 'Select your preferred form of multi-factor authentication.';

const radioGroupOptionClassName = 'relative flex cursor-pointer border p-4 focus:outline-none border-gray-200';
const checkedRadioGroupOptionClassName = 'bg-primary-100 z-10 border-primary-200';
const radioGroupLabelOuterSpanClassName = 'ml-3 flex flex-col';
const radioGroupLabelInnerSpanClassName = 'text-gray-900 block text-sm font-medium';
const radioGroupLabelDisabledInnerSpanClassName = 'text-gray-600 block text-sm font-medium';
const radioGroupDescriptionClassName = 'text-gray-500 block text-sm';
const radioGroupDescriptionDisabledClassName = 'text-gray-400 block text-sm';

const mfaTypeAuthenticatorAppDescription =
  'The preferred and most secure method is the authenticator app (Google, Authy, Microsoft, etc...)';
const mfaTypeSmsDescription =
  'This method is not preferred, you will receive a text message with a code to confirm your identity.';
const mfaTypeNoneDescription = 'NetSPI highly recommends that you configure MFA to secure your account.';

const UserMFA: React.FC = () => {
  const navigate = useNavigate();

  const {
    mfaType,
    showAuthenticatorAppOption,
    showSmsVerificationOption,
    partiallyHiddenPhoneNumber,
    isMfaRequired,
    dialogState,
  } = useMfaForm();

  const handleCheckChange = (value: UserMFAType) => {
    if (value === UserMFAType.AUTHENTICATOR_APP) {
      navigate('/profile/mfa/authenticator');
    } else if (value === UserMFAType.SMS) {
      navigate('/profile/mfa/sms');
    } else {
      dialogState.showDialog();
    }
  };

  return (
    <PageSection title={mfaPageSectionTitle} subtitle={mfaPageSectionSubTitle}>
      <RadioGroup value={mfaType} onChange={handleCheckChange}>
        <div className="-space-y-px rounded-md bg-white">
          {showAuthenticatorAppOption && (
            <RadioGroup.Option
              value={UserMFAType.AUTHENTICATOR_APP}
              className={({ checked }) =>
                mergeClasses(radioGroupOptionClassName, checked ? checkedRadioGroupOptionClassName : '')
              }
            >
              {({ active, checked, disabled }) => (
                <div className="flex">
                  <SpanCircle active={active} checked={checked} />
                  <span className={radioGroupLabelOuterSpanClassName}>
                    <RadioGroup.Label
                      as="span"
                      className={mergeClasses(
                        radioGroupLabelInnerSpanClassName,
                        disabled ? radioGroupLabelDisabledInnerSpanClassName : ''
                      )}
                    >
                      {UserMFAType.AUTHENTICATOR_APP}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={mergeClasses(
                        radioGroupDescriptionClassName,
                        disabled ? radioGroupDescriptionDisabledClassName : ''
                      )}
                    >
                      {mfaTypeAuthenticatorAppDescription}
                    </RadioGroup.Description>
                    {checked && (
                      <Button theme="primary" to="/profile/mfa/authenticator" className="w-fit p-1">
                        Regenerate Authenticator Key
                      </Button>
                    )}
                  </span>
                </div>
              )}
            </RadioGroup.Option>
          )}
          {showSmsVerificationOption && (
            <RadioGroup.Option
              value={UserMFAType.SMS}
              className={({ checked }) =>
                mergeClasses(radioGroupOptionClassName, checked ? checkedRadioGroupOptionClassName : '')
              }
            >
              {({ active, checked, disabled }) => (
                <div className="flex">
                  <SpanCircle active={active} checked={checked} />
                  <span className={radioGroupLabelOuterSpanClassName}>
                    <RadioGroup.Label
                      as="span"
                      className={mergeClasses(
                        radioGroupLabelInnerSpanClassName,
                        disabled ? radioGroupLabelDisabledInnerSpanClassName : ''
                      )}
                    >
                      {UserMFAType.SMS}
                    </RadioGroup.Label>
                    <RadioGroup.Description
                      as="span"
                      className={mergeClasses(
                        radioGroupDescriptionClassName,
                        disabled ? radioGroupDescriptionDisabledClassName : ''
                      )}
                    >
                      {mfaTypeSmsDescription}
                    </RadioGroup.Description>
                    {checked && (
                      <>
                        <span className="flex mt-2">
                          <PhoneIcon className="w-4 h-4" />
                          <span>{partiallyHiddenPhoneNumber}</span>
                        </span>
                        <Button theme="primary" to="sms" className="w-fit p-1">
                          Change Phone Number
                        </Button>
                      </>
                    )}
                  </span>
                </div>
              )}
            </RadioGroup.Option>
          )}
          {!isMfaRequired && (
            <RadioGroup.Option
              value={UserMFAType.None}
              className={({ checked }) =>
                mergeClasses(radioGroupOptionClassName, checked ? checkedRadioGroupOptionClassName : '')
              }
            >
              {({ active, checked }) => (
                <div className="flex">
                  <SpanCircle active={active} checked={checked} />
                  <span className={radioGroupLabelOuterSpanClassName}>
                    <RadioGroup.Label as="span" className={radioGroupLabelInnerSpanClassName}>
                      {UserMFAType.None}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="span" className={radioGroupDescriptionClassName}>
                      {mfaTypeNoneDescription}
                    </RadioGroup.Description>
                  </span>
                </div>
              )}
            </RadioGroup.Option>
          )}
        </div>
      </RadioGroup>
      <ConfirmationDialog
        title="Are you sure?"
        message="Are you sure you want remove MFA?"
        show={dialogState.show}
        onAccept={dialogState.onAccept}
        onDecline={dialogState.onDecline}
      />
    </PageSection>
  );
};

interface SpanCircleProps {
  checked: boolean;
  active: boolean;
}

const SpanCircle: React.FC<SpanCircleProps> = (props) => {
  return (
    <span
      className={mergeClasses(
        props.checked ? 'bg-primary-600 border-transparent' : 'bg-white border-gray-300',
        props.active ? 'ring-2 ring-offset-2 ring-primary-600' : '',
        'mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center'
      )}
      aria-hidden="true"
    >
      <span className="rounded-full bg-white w-1.5 h-1.5" />
    </span>
  );
};

export default UserMFA;
