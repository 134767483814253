import { Dialog, Transition } from '@headlessui/react';
import { ExclamationIcon } from '@heroicons/react/outline';
import * as React from 'react';

export interface ConfirmationDialogProps {
  title: string;
  message: string | React.ReactNode;
  show: boolean;
  onAccept?(): void;
  onDecline(): void;
  noText?: string;
  yesText?: string;
  thirdConditionText?: string;
  onThirdConditionAccept?(): void;
}

function ConfirmationDialog({
  show,
  title,
  message,
  onAccept,
  onDecline,
  noText,
  yesText,
  thirdConditionText,
  onThirdConditionAccept,
}: ConfirmationDialogProps) {
  const cancelButtonRef = React.useRef(null);

  const noButtonText = noText ? noText : 'No';
  const yesButtonText = yesText ? yesText : 'Yes';

  return (
    <Transition.Root show={show} as={React.Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-40 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={onDecline}
      >
        <div className="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:h-screen sm:align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block overflow-hidden rounded bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <ExclamationIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{message}</p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                {thirdConditionText ? (
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded border border-transparent bg-primary px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={onThirdConditionAccept}
                  >
                    {thirdConditionText}
                  </button>
                ) : null}
                <button
                  type="button"
                  className="inline-flex w-full justify-center rounded border border-transparent bg-primary px-4 py-1 text-base font-medium text-white shadow-sm hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={onAccept}
                >
                  {yesButtonText}
                </button>
                <button
                  type="button"
                  className="hover:bg-gray-50 mt-3 inline-flex w-full justify-center rounded border border-gray-300 bg-white px-4 py-1 text-base font-medium text-gray-700 shadow-sm focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={onDecline}
                  ref={cancelButtonRef}
                >
                  {noButtonText}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ConfirmationDialog;
