import React from 'react';
import { mergeClasses } from '.';

export interface PageSectionProps {
  title?: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  children?: React.ReactNode;
  actionsSection?: React.ReactNode;
  className?: string;
  titleClassName?: string;
  childrenClassName?: string;
}

export default function PageSection({
  title,
  subtitle,
  actionsSection,
  children,
  className,
  titleClassName,
  childrenClassName,
}: PageSectionProps) {
  return (
    <div className={mergeClasses('my-3 rounded bg-white p-3 drop-shadow filter', className)}>
      {(title || subtitle || actionsSection) && (
        <div
          className={mergeClasses(
            'flex flex-row px-2 pt-1 text-lg font-semibold text-gray-800 sm:items-start sm:px-4',
            titleClassName
          )}
        >
          <div className="flex-1">
            {title === undefined ? null : <h1>{title}</h1>}
            {subtitle === undefined ? null : <h2 className="text-sm text-gray-600">{subtitle}</h2>}
          </div>
          {actionsSection === undefined ? null : (
            <div className="flex flex-initial flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              {actionsSection}
            </div>
          )}
        </div>
      )}

      <div className={mergeClasses('mt-3 px-2 sm:px-4', childrenClassName)}>{children}</div>
    </div>
  );
}
