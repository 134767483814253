import React from 'react';

export default function DeprecationBanner() {
  const netspiOrange = '#F56A00';
  return (
    <div style={{ backgroundColor: netspiOrange }} className="text-white p-3 text-center">
      <p>
        <b>Important update: </b>
        NetSPI BAS is now on The NetSPI Platform. Please visit{' '}
        <a href="https://platform.netspi.ai" className="underline">
          https://platform.netspi.ai
        </a>{' '}
        to create or log into your account. Note that{' '}
        <a href="https://bas.netspi.com" className="underline">
          https://bas.netspi.com
        </a>{' '}
        will no longer be available after <b>12/31/2024</b>.
      </p>
    </div>
  );
}
