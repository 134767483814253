import { debounce, isEqual } from 'lodash';
import * as React from 'react';
import { LoadDataWrapperRenderProps } from '.';

export interface LoadApiDataWrapperProps<TData> {
  initialData?: TData;
  children: (props: LoadDataWrapperRenderProps<TData>) => React.ReactNode;
  loadData(): Promise<TData>;
  cancel?(): void;
}

type LoadApiDataWrapperState<TData> = LoadDataWrapperRenderProps<TData>;

export default class LoadApiDataWrapper<TData = any> extends React.Component<
  LoadApiDataWrapperProps<TData>,
  LoadApiDataWrapperState<TData>
> {
  private loadDataDebounced: () => void;

  constructor(props: LoadApiDataWrapperProps<TData>) {
    super(props);

    this.loadDataDebounced = debounce(this.loadData, 350);

    this.state = {
      data: props.initialData || ({} as TData),
      loading: false
    };
  }

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps: LoadApiDataWrapperProps<TData>) {
    if (!isEqual(this.props.loadData, prevProps.loadData)) {
      this.loadDataDebounced();
    }
  }

  componentWillUnmount() {
    if (this.props.cancel) {
      this.props.cancel();
    }
  }

  render() {
    if (typeof this.props.children !== 'function') {
      throw new Error('children prop must be a function.');
    }

    return this.props.children(this.state);
  }

  loadData = () => {
    this.setState({ loading: true });
    this.props
      .loadData()
      .then((response) => {
        this.setState({
          data: response || ({} as any),
          loading: false as any
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
        throw error;
      });
  };
}
