import * as React from 'react';
import PhoneInput, { CountryData } from 'react-phone-input-2';
import { useNavigate } from 'react-router-dom';
import { useUserProfile } from '../../../global/UserCompanyContext';
import useErrors from '../../../hooks/useErrors';
import useHttp from '../../../hooks/useHttp';
import { CancelButton, DefaultErrorAlert, PageSection, PageTemplate, SaveButton, TextInput } from '../../common';

function SmsTwoFactorPage() {
  const navigate = useNavigate();
  const [http] = useHttp(false);
  const { updateProfile } = useUserProfile();

  const [phoneNumber, setPhoneNumber] = React.useState('');
  const [verificationCode, setVerificationCode] = React.useState('');
  const [inProgress, setInProgress] = React.useState(false);
  const [isVerifying, setIsVerifying] = React.useState(false);
  const [needsVerificationCode, setNeedsVerificationCode] = React.useState(false);
  const { errors: phoneNumberErrors, catchErrors: catchPhoneNumberErrors, clear: clearPhoneNumberErrors } = useErrors();
  const {
    errors: verficationCodeErrors,
    catchErrors: catchVerficationCodeErrors,
    clear: clearVerficationCodeErrors,
  } = useErrors();

  const handleVerificationCodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setVerificationCode(value);
  };

  const handleAddPhoneNumber = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (inProgress) {
      return;
    }

    setInProgress(true);
    clearPhoneNumberErrors();

    http
      .post('/profile/2fa/smscode', { phoneNumber: phoneNumber })
      .then(() => {
        setNeedsVerificationCode(true);
      })
      .catch(catchPhoneNumberErrors)
      .finally(() => {
        setInProgress(false);
      });
  };

  const handleVerifyPhoneNumber = (event: React.MouseEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (isVerifying) {
      return;
    }

    setIsVerifying(true);
    clearVerficationCodeErrors();
    const data = {
      phoneNumber: phoneNumber,
      code: verificationCode,
    };
    http
      .post('/profile/2fa/verifysmscode', data)
      .then((response) => {
        updateProfile(response.data);
        navigate('/profile/mfa/success');
      })
      .catch(catchVerficationCodeErrors)
      .finally(() => {
        setIsVerifying(false);
      });
  };

  const getPhoneNumberWithoutCountryCode = React.useCallback(
    (phoneNumberWithCountryCode: string, countryDialCodeValue: string) =>
      phoneNumberWithCountryCode.slice(countryDialCodeValue.length),
    []
  );

  const onChangeHandler = React.useCallback(
    (value: string, data: CountryData, event: React.ChangeEvent<HTMLInputElement>, formattedValue: string) => {
      setPhoneNumber(value);

      let valueForPropChangeEvent = '';
      if (getPhoneNumberWithoutCountryCode(value, data.dialCode)) {
        valueForPropChangeEvent = value.startsWith('+') ? value : `+${value}`;
        setPhoneNumber(valueForPropChangeEvent);
      }
    },
    [getPhoneNumberWithoutCountryCode]
  );

  return (
    <PageTemplate loading={false} title="Add SMS Two-Factor Authentication" backButtonLocation="/profile/mfa">
      <PageSection>
        <DefaultErrorAlert errors={verficationCodeErrors} />
        <p className="py-3 text-base">
          To configure two-factor authentication via SMS please enter your phone number below. You will be sent an SMS
          message with a code which you will be asked to enter below. Once configured you will be prompted to enter a
          code sent via SMS each time you log in.
        </p>
        <div className="mt-6 flex flex-col gap-y-6">
          <form onSubmit={handleAddPhoneNumber}>
            <label className="mb-2 text-sm font-medium">
              Phone Number <span className="text-primary">*</span>
            </label>
            <PhoneInput
              placeholder="###-###-####"
              countryCodeEditable={false}
              value={phoneNumber}
              country="us"
              enableSearch={true}
              onChange={onChangeHandler}
              inputProps={{
                name: 'phoneNumber',
                required: true,
                autoFocus: true,
              }}
              containerClass="react-phone-input-container"
              isValid={!!!phoneNumberErrors.size}
            />
            {!!phoneNumberErrors.size && <p className="text-red-500">{phoneNumberErrors.get('')}</p>}
            <div className="pt-5">
              <div className="flex justify-end">
                {!needsVerificationCode && <CancelButton to="/profile/mfa" />}
                <SaveButton
                  className="ml-3"
                  isSaving={inProgress}
                  disabled={needsVerificationCode || inProgress || phoneNumber.trim().length <= 3}
                >
                  Send Verification Code
                </SaveButton>
              </div>
            </div>
          </form>
          <form onSubmit={handleVerifyPhoneNumber}>
            {needsVerificationCode && (
              <div>
                <hr />
                <TextInput
                  name="verificationCode"
                  label="Verification Code"
                  type="tel"
                  pattern="[0-9]*"
                  autoComplete="off"
                  inputMode="numeric"
                  value={verificationCode}
                  required={true}
                  errors={verficationCodeErrors}
                  onChange={handleVerificationCodeChange}
                  autoFocus={true}
                />
              </div>
            )}
            <div className="pt-5">
              <div className="flex justify-end">
                {needsVerificationCode && <CancelButton to="/profile/mfa" tabIndex={1} />}
                {needsVerificationCode && (
                  <SaveButton className="ml-3" isSaving={isVerifying} disabled={inProgress}>
                    Verify
                  </SaveButton>
                )}
              </div>
            </div>
          </form>
        </div>
      </PageSection>
    </PageTemplate>
  );
}

export default SmsTwoFactorPage;
