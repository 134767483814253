import { FilterRow } from '.';
import { formatCamelCaseAsWords } from '../../util/formattingHelpers';
import { SearchFilterInput, SelectOption } from '../common';
import SearchFilterDatePicker from './SearchFilterDatePicker';
import SearchFilterDropdown from './SearchFilterDropdown';

interface ListFiltersProps<F> {
  resetFilters(filterInfo: F): void;
  changeFilter(filterInfo: { filterName: keyof F; value: F[keyof F] | string | number | undefined }): void;
  defaultFilters: F;
  filterInfo: ListFilterType<F>;
  filters: F;
}
export type ListFilterType<F> = { [K in keyof F]?: FilterInfo };

interface FilterInfo {
  type: 'search' | 'select' | 'date';
  label?: string;
  placeHolder?: string;
  options?: SelectOption[];
}

function Filters<F>(props: ListFiltersProps<F>) {
  let keys = Object.keys(props.filterInfo).map((k) => k as keyof F);

  return (
    <div className="flex pt-1.5 pr-4">
      {keys.map((k, i) => {
        const filter = props.filterInfo[k] as FilterInfo;

        return (
          <div key={k as string} className="inline-flex">
            {i > 1 && i !== 0 ? (
              <span className="pb-1 text-gray-400">|</span>
            ) : (
              <span className="pb-1 text-transparent">|</span> //Showing null was dropping the value display. Made transparent to keep sytling
            )}

            {filter.type === 'select' && (
              <SearchFilterDropdown
                className="flex"
                label={filter.label === undefined ? formatCamelCaseAsWords(String(k)) : filter.label}
                name={String(k)}
                value={props.filters[k] as unknown as string}
                options={filter.options ?? []}
                onChange={(name, value) => props.changeFilter({ filterName: name as any, value: value })}
              />
            )}
            {filter.type === 'date' && (
              <SearchFilterDatePicker
                className="flex"
                label={filter.label === undefined ? formatCamelCaseAsWords(String(k)) : filter.label}
                name={String(k)}
                value={props.filters[k] as unknown as string}
                onChange={(name, value) => props.changeFilter({ filterName: name as any, value: value })}
              />
            )}
          </div>
        );
      })}
    </div>
  );
}

function ListFilters<F>(props: ListFiltersProps<F>) {
  let keys = Object.keys(props.filterInfo).map((k) => k as keyof F);
  const searchIndex = keys.find((key) => key.toString() === 'search');
  const filter = props.filterInfo[searchIndex] as FilterInfo;

  return (
    <FilterRow startOpen={true} onClearFilters={() => props.resetFilters(props.defaultFilters)}>
      {filter === undefined ? (
        <Filters {...props} />
      ) : (
        <div key={filter.type as string} className="inline-flex w-full">
          <SearchFilterInput
            onChange={(e) => props.changeFilter({ filterName: searchIndex as keyof F, value: e.currentTarget.value })}
            name={filter.label as string}
            value={props.filters[searchIndex as keyof F] as any}
            placeholder={filter.placeHolder}
          >
            <Filters {...props} />
          </SearchFilterInput>
        </div>
      )}
    </FilterRow>
  );
}

export default ListFilters;
