import { PlusIcon } from '@heroicons/react/solid';
import * as React from 'react';
import { Button, ButtonProps } from './';

const DefaultClassName = 'p-2 sm:p-1.5';

export type AddLinkButtonProps = ButtonProps & {
  iconClassName?: string;
};

function AddLinkButton({ children, className, iconClassName, ...props }: AddLinkButtonProps) {
  const iconClass = iconClassName ?? 'h-5 w-5';
  const wChildClass = iconClassName ?? '-ml-0.5 mr-2' + iconClass;

  return (
    <Button {...props} className={className ?? DefaultClassName}>
      {children === undefined ? (
        <PlusIcon className={iconClass} aria-hidden="true" />
      ) : (
        <>
          <PlusIcon className={wChildClass} aria-hidden="true" /> {children}
        </>
      )}
    </Button>
  );
}

export default AddLinkButton;
