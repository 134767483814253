import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useApiClass } from '../../hooks';
import { UserPreferencesApi } from './userpreferenceApi';
import { UserPreferenceModel } from './userPrefereceModel';
import { Map } from 'immutable';

interface UserPreferenceProps {
  userPreferences: Map<number | undefined, Map<string, string>>;
  fetchUserPreferences: () => void;
  getUserpreferences: (key: string, operationId?: number) => string | undefined;
  saveUserPreferences: (UserPreferenceResult: UserPreferenceModel) => void;
}

export enum UserPreferencesKeys {
  IS_COVERAGE_SUMMARYCHART_VISIBLE = 'isCoverageSummaryChartVisible',
  IS_DATA_SOURCE_COVERAGE_SUMMARYCHART_VISIBLE = 'isDataSourceCoverageSummaryChartVisible',
  IS_DETECTION_STRATEGY_COVERAGE_SUMMARYCHART_VISIBLE = 'isDetectionStrategyCoverageSummaryChartVisible',
  IS_VENDOR_COVERAGE_SUMMARYCHART_VISIBLE = 'isVendorCoverageSummaryChartVisible',
  IS_VENDOR_DETAILS_SUMMARYCHART_VISIBLE = 'isVendorDetailsSummaryChartVisible',
}

const UserPreferencesContext = createContext<UserPreferenceProps | undefined>(undefined);

export function useUserPreferences() {
  const userPreferencesData = useContext(UserPreferencesContext);

  if (userPreferencesData === undefined) {
    throw new Error('useUserPreferences must be used within a UserPreferencesProvider.');
  }

  return userPreferencesData;
}

export interface UserPreferencesProviderProps {
  children?: React.ReactNode;
}

export function UserPreferencesProvider(props: UserPreferencesProviderProps) {
  const [userPreferences, setUserPreferences] = useState<Map<number | undefined, Map<string, string>>>(Map());
  const api = useApiClass(UserPreferencesApi);

  const fetchUserPreferences = useCallback(async () => {
    let operationMap = Map<number | undefined, Map<string, string>>();
    const userPreferencesData = await api.getUserpreferences();
    userPreferencesData.forEach((element) => {
      let userPreference = operationMap.get(element.operationId);
      if (userPreference) {
        operationMap = operationMap.set(element.operationId, userPreference.set(element.key, element.value));
      } else {
        operationMap = operationMap.set(element.operationId, Map({ [element.key]: element.value }));
      }
    });
    setUserPreferences(operationMap);
  }, [api]);

  useEffect(() => {
    fetchUserPreferences();
  }, [fetchUserPreferences]);

  const saveUserPreferences = useCallback(
    (userPreference: UserPreferenceModel) => {
      (async () => {
        return await api.saveUserpreferences(userPreference);
      })();

      setUserPreferences((prev) =>
        prev.update(userPreference.operationId, Map(), (v) => v.set(userPreference.key, userPreference.value))
      );
    },
    [api]
  );

  const getUserpreferences = useCallback(
    (key: string, operationId?: number): string | undefined => {
      return userPreferences.get(operationId)?.get(key);
    },
    [userPreferences]
  );
  const value = useMemo(
    () => ({
      userPreferences: userPreferences,
      fetchUserPreferences: fetchUserPreferences,
      saveUserPreferences: saveUserPreferences,
      getUserpreferences: getUserpreferences,
    }),
    [fetchUserPreferences, getUserpreferences, saveUserPreferences, userPreferences]
  );
  return <UserPreferencesContext.Provider {...props} value={value} />;
}
