export interface NamedAction<TAction extends string = string> {
  readonly type: TAction;
}

export interface NamedActionPayload<TAction extends string, TPayload> extends NamedAction<TAction> {
  readonly payload: TPayload;
}

export function generateNamedAction<TAction extends string>(type: TAction): NamedAction<TAction> {
  return { type };
}

export function generateNamedActionWithPayload<TAction extends string, TPayload>(
  type: TAction,
  payload: TPayload
): NamedActionPayload<TAction, TPayload> {
  return { type, payload: payload };
}
