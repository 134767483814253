import { Popover, Transition } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import * as React from 'react';
import { usePopper } from 'react-popper';

export interface HelpTipProps {
  children: React.ReactNode;
  button?: React.ReactNode;
}

function HelpTip({ button, children }: HelpTipProps) {
  const [isShowing, setIsShowing] = React.useState(false);
  let [referenceElement, setReferenceElement] = React.useState<HTMLButtonElement | null>(null);
  let [popperElement, setPopperElement] = React.useState<HTMLDivElement | null>(null);
  let { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'top',
  });

  return (
    <Popover as="span" className="inline-flex">
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className="font-sm inline-flex items-center rounded text-base hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
            onMouseEnter={() => setIsShowing(true)}
            onMouseLeave={() => setIsShowing(false)}
          >
            {button ? (
              button
            ) : (
              <QuestionMarkCircleIcon
                className="ml-2 h-4 w-4 text-gray-400 transition duration-150 ease-in-out"
                aria-hidden="true"
              />
            )}
          </Popover.Button>

          <Transition
            as="span"
            show={isShowing || open}
            onMouseEnter={() => setIsShowing(true)}
            onMouseLeave={() => setIsShowing(false)}
            className="z-10"
          >
            <Popover.Panel
              static
              as="div"
              className="mt-3 rounded border-2 border-gray-900 bg-gray-900 p-2 text-white"
              ref={setPopperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              {children}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
}

export default HelpTip;
