import { Transition } from '@headlessui/react';
import React, { useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Notification, useNotificationUi, useNotifications } from '../global/Notifications';
import { CustomNotification } from './notifications/CustomNotification';
import { StandardNotification } from './notifications/StandardNotification';

const NotificationList: React.FC = () => {
  const { remove } = useNotifications();
  const { notifications, unmount } = useNotificationUi();

  const notificationsArr = useMemo(() => {
    return notifications.valueSeq().slice(-3).toArray();
  }, [notifications]);

  return createPortal(
    <div
      aria-live="assertive"
      className="pointer-events-none fixed inset-0 flex items-end justify-end px-4 py-6 sm:items-start sm:p-6 z-50"
    >
      {notificationsArr.length === 0 ? null : (
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end h-full justify-end">
          {notificationsArr.map((notification: Notification) => (
            <NotificationItem key={notification.id} notification={notification} onClose={remove} onUnmount={unmount} />
          ))}
        </div>
      )}
    </div>,
    window.document.body
  );
};

interface NotificationItemProps {
  notification: Notification;
  onClose(id: string): void;
  onUnmount(id: string): void;
}

function NotificationItem({ notification, onClose, onUnmount }: NotificationItemProps) {
  const onCloseCb = useCallback(() => onClose(notification.id), [notification.id, onClose]);
  const onUnmountCb = useCallback(() => onUnmount(notification.id), [notification.id, onUnmount]);
  return (
    <Transition
      show={notification.visible}
      appear={false}
      as={React.Fragment}
      enter="transform ease-out duration-300 transition"
      enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
      enterTo="translate-y-0 opacity-100 sm:translate-x-0"
      leave="transition ease-in duration-100"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      afterLeave={onUnmountCb}
    >
      {notification.level === undefined ? (
        <CustomNotification
          header={notification.headerText}
          message={notification.message}
          actionText={notification.actionButtonName}
          onActionClicked={notification.actionCallback}
          close={onCloseCb}
        />
      ) : (
        <StandardNotification
          header={notification.headerText}
          message={notification.message}
          level={notification.level}
          close={onCloseCb}
        />
      )}
    </Transition>
  );
}

export default NotificationList;
