import { Navigate, Route, Routes } from 'react-router-dom';
import { useUserProfile } from '../global/UserCompanyContext';
import { useAppVersionNotification } from '../hooks/useAppVersion';
import Error404 from './Error404';
import ExternalRedirect from './ExternalRedirect';
import { useHelpHero } from './HelpHero';
import Layout from './Layout';
import LoginCallback from './LoginCallback';
import LoginIFrame from './LoginIFrame';
import * as AdminComponents from './admin/adminRoutes';
import * as AttackComponents from './attack/attackRoutes';
import { LoadingOverlay } from './common';
import * as DashboardComponents from './dashboard/dashboardRoutes';
import * as DetectionCoverageLanding from './detection/detectionRoutes';
import * as CoverageLanding from './detection/Coverage/coverageRoutes';
import ChangePasswordPage from './profile/ChangePasswordPage';
import ProfileForm from './profile/ProfileForm';
import UserProfile from './profile/UserProfile';
import AuthenticatorAppTwoFactorPage from './profile/mfa/AuthenticatorAppTwoFactorPage';
import MFASuccess from './profile/mfa/MFASuccess';
import SmsTwoFactorPage from './profile/mfa/SmsTwoFactorPage';
import UserMFA from './profile/mfa/UserMFA';

export default function AppRoutes() {
  useHelpHero();
  useAppVersionNotification();

  const { email, isAdmin, isExternalLogin } = useUserProfile();
  const isProfileLoaded = email !== '';

  return (
    <Routes>
      {/* Unauthenticated */}
      <Route path="/login" element={<LoginIFrame />} />
      <Route path="/callback" element={<LoginCallback />} />
      <Route path="/docs" element={<ExternalRedirect to="/docs" />} />

      {/* Authenticated */}
      <Route element={<Layout />}>
        <Route index element={<AttackComponents.OperationHome />} />
        <Route path="home/agent" element={<AttackComponents.OperationHome />}>
          <Route path=":agentId" element={<AttackComponents.AgentForm />} />
        </Route>
        <Route path="profile" element={<UserProfile />}>
          <Route path="general" element={<ProfileForm />} />
          {!isExternalLogin && <Route path="mfa" element={<UserMFA />} />}
          <Route index element={<Navigate to="general" replace />} />
          <Route path="*" element={<Navigate to="general" replace />} />
        </Route>
        <Route path="profile/password" element={<ChangePasswordPage />} />
        {!isExternalLogin && <Route path="profile/mfa/sms" element={<SmsTwoFactorPage />} />}
        {!isExternalLogin && <Route path="profile/mfa/authenticator" element={<AuthenticatorAppTwoFactorPage />} />}
        {!isExternalLogin && <Route path="profile/mfa/success" element={<MFASuccess />} />}

        <Route path="detection">
          <Route path="coverage" element={<DetectionCoverageLanding.DetectionCoverageLanding />}>
            <Route path=":id" element={<DetectionCoverageLanding.DetectionDetails />} />
          </Route>
        </Route>
        <Route path="agent" element={<AttackComponents.AgentIndex />}>
          <Route path=":agentId" element={<AttackComponents.AgentForm />} />
        </Route>
        <Route path="operation" element={<AttackComponents.OperationIndex />}></Route>
        <Route path="operation/new" element={<AttackComponents.OperationForm />} />
        <Route path="operation/:operationId" element={<AttackComponents.OperationsLanding />}>
          <Route path="" element={<AttackComponents.OperationForm />} />
          {isAdmin ? (
            <Route path="reports" element={<DashboardComponents.Dashboard />}>
              <Route index element={<DashboardComponents.SummaryDashboard />} />
              <Route path="summary" element={<DashboardComponents.SummaryDashboard />} />
              <Route path="tactic" element={<DashboardComponents.TacticDashboard />} />
              <Route path="full" element={<DashboardComponents.FullDashboard />} />
            </Route>
          ) : null}
          <Route path="detection">
            <Route path="workspace" element={<DetectionCoverageLanding.DetectionCoverageLanding />}>
              <Route path=":id" element={<DetectionCoverageLanding.DetectionDetails />} />
            </Route>
            <Route path="timeline" element={<DetectionCoverageLanding.Timeline />} />
            <Route path="heatmap" element={<DetectionCoverageLanding.MitreHeatMap />}>
              <Route path=":techniqueId" element={<DetectionCoverageLanding.MitreTechnique />} />
            </Route>
          </Route>
          <Route path="coverage" element={<CoverageLanding.Coverage />}>
            <Route path="vendor" element={<CoverageLanding.VendorCoverage />} />
            <Route path="datasource" element={<CoverageLanding.DataSourceCoverage />} />
            <Route path="detectionstrategy" element={<CoverageLanding.DetectionStrategyCoverage />} />
          </Route>
          <Route path="coverage/vendor/compare" element={<CoverageLanding.VendorComparison />} />
          <Route path="coverage/vendor/:vendorId" element={<CoverageLanding.VendorDetails />} />
          <Route path="run" element={<AttackComponents.RunOperationTab />}>
            <Route path="plan" element={<AttackComponents.OperationPlanTab />}>
              <Route path="agent/:agentId" element={<AttackComponents.AgentForm />} />
            </Route>
            <Route path="execute" element={<AttackComponents.OperationExecuteTab />}>
              <Route path="agent/:agentId" element={<AttackComponents.AgentForm />} />
            </Route>
            <Route path="history" element={<AttackComponents.OperationHistoryTab />}>
              <Route path="agent/:agentId" element={<AttackComponents.AgentForm />} />
            </Route>
            <Route index element={<Navigate to="plan" replace />} />
            <Route path="*" element={<Navigate to="plan" replace />} />
          </Route>
        </Route>
        <Route path="playbook" element={<AttackComponents.PlaybookIndexPage />} />
        <Route path="playbook/:id" element={<AttackComponents.PlaybookFormPage />} />
        {isProfileLoaded ? (
          isAdmin ? (
            <Route path="/admin">
              <Route path="company" element={<AdminComponents.AdminCompanyIndex />}>
                <Route path=":id" element={<AdminComponents.AdminCompanyForm />} />
              </Route>
              <Route path="user" element={<AdminComponents.AdminUserIndex />}>
                <Route path=":id" element={<AdminComponents.AdminUserForm />} />
              </Route>
              <Route path="tag" element={<AdminComponents.AdminTagIndex />} />
              <Route path="tag/:tagId" element={<AdminComponents.AdminTagForm />} />
              <Route path="import" element={<AdminComponents.AdminImportIndex />} />
              <Route path="playbook" element={<AdminComponents.AdminProvidedPlaybookIndex />} />
              <Route path="playbook/:id" element={<AdminComponents.AdminProvidedPlaybookForm />} />

              <Route path="attacksurface" element={<AdminComponents.AdminAttackSurfaceIndex />}>
                <Route path=":id" element={<AdminComponents.AdminAttackSurfaceForm />} />
              </Route>
              <Route path="datasource" element={<AdminComponents.AdminDataSourceIndex />}>
                <Route path=":id" element={<AdminComponents.AdminDataSourceForm />} />
              </Route>
              <Route path="securitycontroltype" element={<AdminComponents.AdminSecurityControlTypeIndex />}>
                <Route path=":id" element={<AdminComponents.AdminSecurityControlTypeForm />} />
              </Route>
              <Route path="securityvendor" element={<AdminComponents.AdminSecurityVendorIndex />}>
                <Route path=":id" element={<AdminComponents.AdminSecurityVendorForm />} />
              </Route>
              <Route path="industry" element={<AdminComponents.AdminIndustryIndex />}>
                <Route path=":id" element={<AdminComponents.AdminIndustryForm />} />
              </Route>
              <Route path="datacomponent" element={<AdminComponents.AdminDataComponentIndex />}>
                <Route path=":id" element={<AdminComponents.AdminDataComponentForm />} />
              </Route>
              <Route path="detectionstrategy" element={<AdminComponents.AdminDetectionStrategyIndex />}>
                <Route path=":id" element={<AdminComponents.AdminDetectionStrategyForm />} />
              </Route>
              <Route path="notificationstrategy" element={<AdminComponents.AdminNotificationStrategyIndex />}>
                <Route path=":id" element={<AdminComponents.AdminNotificationStrategyForm />} />
              </Route>
              <Route path="salespackage" element={<AdminComponents.AdminSalesPackageIndex />}>
                <Route path=":id" element={<AdminComponents.AdminSalesPackageForm />} />
              </Route>
            </Route>
          ) : null
        ) : (
          <Route path="/admin" element={<LoadingOverlay />} />
        )}
        <Route path="*" element={isProfileLoaded ? <Error404 /> : <LoadingOverlay />} />
      </Route>
    </Routes>
  );
}
