import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getHomeLocation } from '../components/attack/common/getAttackLocations';
import { useUserProfile } from '../global/UserCompanyContext';
import useNavigate from './useNavigate';
import { useManageUsersLastViewedOperation } from './useUsersLastViewedOperation';

export function useProxy() {
  const { fetchProfile } = useUserProfile();
  const { clear } = useManageUsersLastViewedOperation();
  const params = useParams();
  const backLocation = React.useMemo(getHomeLocation, []);
  const navigate = useNavigate();
  const navigateToHome = useCallback(() => {
    navigate(backLocation);
  }, [backLocation, navigate]);
  const refreshBrowser = useCallback(() => window.location.reload(), []);

  const startProxy = useCallback(async () => {
    await fetchProfile();
    clear();
    refreshBrowser();
  }, [fetchProfile, clear, refreshBrowser]);

  const endProxy = useCallback(async () => {
    await fetchProfile();
    clear();

    if (params.operationId !== undefined) {
      navigateToHome();
    } else {
      refreshBrowser();
    }
  }, [fetchProfile, clear, params.operationId, navigateToHome, refreshBrowser]);

  return { startProxy, endProxy };
}
