import { debounce } from 'lodash';
import { SetStateAction, useCallback, useState } from 'react';

export type DebouncedDispatch<TState> = (value: React.SetStateAction<TState>, immediate?: boolean) => void;

export default function useDebouncedSetState<TState>(
  initialState: TState | (() => TState),
  wait: number = 1000
): [TState, DebouncedDispatch<TState>] {
  const [state, setState] = useState(initialState);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debuouncedSetState = useCallback(debounce(setState, wait), [wait]);

  const customSetState = useCallback(
    (value: SetStateAction<TState>, immediate?: boolean) => {
      if (immediate) {
        debuouncedSetState.cancel();
        setState(value);
      } else {
        debuouncedSetState(value);
      }
    },
    [debuouncedSetState]
  );

  return [state, customSetState];
}
