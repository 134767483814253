import React, { InputHTMLAttributes } from 'react';
import { mergeClasses } from '.';

type InputThemeName = 'standard' | 'error';
interface InputThemeInfo {
  input: string;
}

export type InputTheme = InputThemeName | InputThemeInfo;

const DefaultClassName = 'px-2 shadow-sm block w-full sm:text-sm rounded disabled:bg-gray-100 ';

const themes: Record<InputThemeName, InputThemeInfo> = {
  standard: { input: DefaultClassName + 'focus:ring-primary focus:border-primary border-gray-400' },
  error: {
    input:
      DefaultClassName +
      'input-decoration-inset border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500',
  },
};

export type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  theme?: InputTheme;
};

export default React.forwardRef(function Input(
  { className, theme, type, ...props }: InputProps,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  if (typeof theme === 'undefined') {
    theme = 'standard';
  }

  let info: InputThemeInfo;
  if (typeof theme === 'string') {
    info = themes[theme];
    if (info === undefined) {
      throw Error('Invalid theme: ' + theme);
    }
  } else {
    info = theme;
  }

  return <input {...props} type={type ?? 'text'} className={mergeClasses(info.input, className)} ref={ref} />;
});
