import * as React from 'react';
import { TextInput, TextInputProps } from '.';

export type TextInputGenericProps<T> = Omit<TextInputProps, 'name' | 'value' | 'onChange'> & {
  name: keyof T;
  value?: T[keyof T];
  onChange(name: keyof T, value: string): void;
};

function TextInputGeneric<T>(props: TextInputGenericProps<T>, ref: React.Ref<HTMLInputElement>) {
  const { name, value, onChange, ...restProps } = props;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(name, e.currentTarget.value);
  };
  return <TextInput {...restProps} name={String(name)} value={value as any} onChange={handleChange} ref={ref} />;
}

export default React.forwardRef(TextInputGeneric) as unknown as <T>(
  props: TextInputGenericProps<T> & { ref?: React.ForwardedRef<HTMLInputElement> }
) => JSX.Element;
