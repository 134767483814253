import QRCode from 'react-qr-code';
import {
  CancelButton,
  ConfirmationDialog,
  DefaultErrorAlert,
  LoadingIndicator,
  PageSection,
  PageTemplate,
  SaveButton,
  TextInput,
} from '../../common';
import { useAuthenticatorAppTwoFactor } from '../utils/useAuthenticatorAppTwoFactor';

const AuthenticatorAppTwoFactorPage: React.FC = () => {
  const {
    qrCode,
    onSubmitTwoFactorCodeHandler,
    otp,
    handleOtpChange,
    errors,
    isVerifying,
    loading,
    hasAuthenticatorApp2Fa,
    showWarningDialog,
    onWarningDialogAccept,
    onWarningDialogDecline,
  } = useAuthenticatorAppTwoFactor();

  return (
    <PageTemplate loading={loading} title="Add Authenticator App" backButtonLocation="/profile/mfa">
      <>
        <PageSection>
          <DefaultErrorAlert errors={errors} />
          <p className="text-base pt-1">
            Follow the instructions below to complete your multi-factor authentication with an authenticator app.
          </p>
          <p className="text-base font-medium py-1">Instructions:</p>
          <ol className="text-base list-decimal pl-4">
            <li>
              Download an authenticator app. See our{' '}
              <a href="/docs/mfa/#suggested-authenticator-app-instructions" target="_blank" className="text-primary">
                help pages
              </a>{' '}
              for more detailed instructions.
            </li>
            <li>Configure the authenticator by scanning the provided barcode</li>
            <li>Enter a code below to verify the configuration</li>
          </ol>
          <div className="flex flex-col py-2">
            {qrCode !== '' ? (
              <QRCode size={200} style={{ height: 'auto', maxWidth: '100%' }} value={qrCode} viewBox={`0 0 200 200`} />
            ) : (
              <LoadingIndicator className="self-start" />
            )}
            <form onSubmit={onSubmitTwoFactorCodeHandler}>
              <TextInput
                name="otp"
                label="Enter the code from the authenticator app"
                type="tel"
                pattern="[0-9]*"
                autoComplete="off"
                inputMode="numeric"
                value={otp}
                required={true}
                onChange={handleOtpChange}
                autoFocus={true}
                containerClassName="pt-2"
              />
              <div className="pt-5">
                <div className="flex justify-end">
                  <CancelButton to="/profile/mfa" tabIndex={1} />
                  <SaveButton className="ml-3" isSaving={isVerifying} disabled={otp === ''}>
                    Submit
                  </SaveButton>
                </div>
              </div>
            </form>
          </div>
        </PageSection>
        <ConfirmationDialog
          title="Are you sure?"
          message={<WarningMessage hasAuthenticatorApp2Fa={hasAuthenticatorApp2Fa} />}
          show={showWarningDialog}
          onAccept={onWarningDialogAccept}
          onDecline={onWarningDialogDecline}
        />
      </>
    </PageTemplate>
  );
};

interface WarningMessageProps {
  hasAuthenticatorApp2Fa: boolean;
}

function WarningMessage({ hasAuthenticatorApp2Fa }: WarningMessageProps) {
  if (hasAuthenticatorApp2Fa) {
    return (
      <>
        <b>WARNING</b>: If you regenerate your authenticator key your authenticator app will not work until you verify
        the new key. If you fail to properly verify the new key you may lose access to your account.
        <br />
        <br />
        Are you sure you want to regenerate your authenticator key?
      </>
    );
  }

  return (
    <>
      <b>WARNING</b>: When switching to use an authenticator app you must first verify the app is configured properly by
      entering a code. If you fail to properly verify your app you may lose access to your account.
      <br />
      <br />
      Are you sure you want to switch to use an authenticator app?
    </>
  );
}

export default AuthenticatorAppTwoFactorPage;
