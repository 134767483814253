import * as React from 'react';
import AnimateHeight from 'react-animate-height';
import { Button } from '.';
import Content from './ContentRow';

export interface FilterRowProps {
  children: React.ReactChild;
  onClearFilters(): void;
  startOpen?: boolean;
}

export default function FilterRow(props: FilterRowProps) {
  const [open, setOpen] = React.useState(props.startOpen ? props.startOpen : false);
  return (
    <>
      <AnimateHeight height={open === true ? 0 : 'auto'}>
        <div>
          <Button title="Open Filters" onClick={() => setOpen(true)} className="w-full">
            <div className="width-100 align-center color-red bold">
              Filters <i className="fa fa-caret-down" />
            </div>
          </Button>
        </div>
      </AnimateHeight>
      <AnimateHeight height={open === true ? 'auto' : 0}>
        <Content.Row className="no-padding-row small-margin-bottom">
          <Content.Col size={12}>{props.children}</Content.Col>
          <Content.Col vAlign="middle" size={3}>
            <div className="display-flex">
              {/* <div className="flex-grow-1 small-pad-right align-right">
                <Button onClick={props.onClearFilters}>Clear All Filters</Button>
              </div>
              <div>
                <LinkButton title="Minimize Filters" onClick={() => setOpen(false)}>
                  <ChevronUpIcon />
                </LinkButton>
              </div> */}
            </div>
          </Content.Col>
        </Content.Row>
      </AnimateHeight>
    </>
  );
}
