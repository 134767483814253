import { Actions, Body, Content, ContentProps, Panel, PanelProps } from './SlideOverCommon';
export type {
  BodyProps as SlideOverPanelBodyProps,
  ActionsProps as SlideOverPanelActionsProps,
} from './SlideOverCommon';

export type SlideOverPanelProps = PanelProps & ContentProps;

function SlideOverPanel({ title, subtitle, onClose, children, ...panelProps }: SlideOverPanelProps) {
  return (
    <Panel {...panelProps} onClose={onClose}>
      <Content title={title} subtitle={subtitle} onClose={onClose}>
        {children}
      </Content>
    </Panel>
  );
}

export default Object.assign(SlideOverPanel, {
  Body: Body,
  Actions: Actions,
});
