import { isImmutable, merge } from 'immutable';
import { isObjectLike } from 'lodash';
import * as React from 'react';

export interface CancelableProps<T = any> {
  data: T;
  children: (props: CancelableRenderProps<T>) => React.ReactNode;
  onSave(data: T): Promise<number> | void;
}

export interface CancelableRenderProps<T = any> {
  data: T;
  dirty: boolean;
  saving: boolean;
  save(): void;
  cancel(): void;
  update(data: T): Promise<void>;
  update(name: keyof T, value: any): Promise<void>;
}

interface CancelableState<T = any> {
  dirty: boolean;
  data: T;
  originalData: T;
  saving: boolean;
}

export default class Cancelable<T = any> extends React.Component<CancelableProps<T>, CancelableState<T>> {
  constructor(props: CancelableProps<T>) {
    super(props);

    this.state = {
      dirty: false,
      originalData: props.data,
      data: props.data,
      saving: false
    };
  }

  componentDidUpdate() {
    if (this.state.originalData !== this.props.data) {
      this.setState({ originalData: this.props.data, data: this.props.data, dirty: false });
    }
  }

  render() {
    if (typeof this.props.children !== 'function') {
      throw Error("'children' should be a function");
    }

    return this.props.children({
      data: this.state.data,
      dirty: this.state.dirty,
      saving: this.state.saving,
      save: this.handleSave,
      cancel: this.handleCancel,
      update: this.handleUpdate
    });
  }

  private handleSave = () => {
    this.setState({ saving: true });
    const savedData = this.state.data;
    const result = this.props.onSave(savedData);
    if (typeof result === 'undefined') {
      this.setState({ dirty: false, saving: false, originalData: savedData });
      return;
    }

    return result
      .then((data) => {
        this.setState({ dirty: false, saving: false, originalData: savedData });
        return data;
      })
      .catch((err) => {
        this.setState({ saving: false });
        throw err;
      });
  };

  private handleCancel = () => {
    this.setState((prevState) => ({
      dirty: false,
      data: prevState.originalData
    }));
  };

  private handleUpdate = (data: keyof T | Partial<T>, value?: any) => {
    return new Promise<void>((resolve) => {
      let updated: Partial<T>;
      if (typeof data === 'string' && typeof value !== 'undefined') {
        let obj: Partial<T> = {};
        obj[data] = value;
        updated = obj;
      } else {
        updated = data as Partial<T>;
      }

      this.setState((prevState) => this.updateState(updated, prevState), resolve);
    });
  };

  private updateState = (updated: Partial<T>, prevState: CancelableState<T>) => {
    let newData: any;
    if (isObjectLike(updated)) {
      newData = isImmutable(updated) ? merge(prevState.data, updated) : Object.assign({}, prevState.data, updated);
    } else {
      newData = updated;
    }
    return {
      dirty: true,
      data: newData
    };
  };
}
