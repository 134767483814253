import React, { SelectHTMLAttributes } from 'react';
import { mergeClasses, SelectOption } from '.';

type SelectThemeName = 'standard' | 'error';
interface SelectThemeInfo {
  select: string;
}

export type SelectTheme = SelectThemeName | SelectThemeInfo;

const DefaultClassName = 'block w-full sm:text-sm rounded ';

const themes: Record<SelectThemeName, SelectThemeInfo> = {
  standard: { select: DefaultClassName + 'focus:ring-primary focus:border-primary border-gray-400' },
  error: {
    select:
      DefaultClassName +
      'input-decoration-inset border-red-300 text-red-900 placeholder-red-300 focus:outline-none focus:ring-red-500 focus:border-red-500',
  },
};

export type InputProps = SelectHTMLAttributes<HTMLSelectElement> & {
  theme: SelectTheme;
  options: SelectOption[];
};

export default React.forwardRef(function Input(
  { className, theme, options, ...props }: InputProps,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  let info: SelectThemeInfo;
  if (typeof theme === 'string') {
    info = themes[theme];
    if (info === undefined) {
      throw Error('Invalid theme: ' + theme);
    }
  } else {
    info = theme;
  }

  return (
    <select {...props} className={mergeClasses(info.select, className)} ref={ref}>
      {options.map((x, i) => (
        <option key={i} value={x.value}>
          {x.text}
        </option>
      ))}
    </select>
  );
});
