import * as React from 'react';
import { SelectInput, SelectInputProps } from '.';

export type SelectInputGenericProps<T> = Omit<SelectInputProps, 'name' | 'value' | 'onChange'> & {
  name: keyof T;
  value?: T[keyof T];
  onChange(name: keyof T, value: string | number): void;
};

export default function SelectInputGeneric<T>(props: SelectInputGenericProps<T>) {
  const { name, value, onChange, ...restProps } = props;
  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    onChange(name, e.currentTarget.value);
  };
  return <SelectInput {...restProps} name={String(name)} value={value as any} onChange={handleChange} />;
}
