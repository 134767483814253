import { useCallback } from 'react';
import { ErrorBoundary as ReactErrorBoundary, ErrorBoundaryProps } from 'react-error-boundary';

export default function ErrorBoundary({ onError, ...props }: React.PropsWithChildren<ErrorBoundaryProps>) {
  const handleError = useCallback(
    (error: Error, info: { componentStack: string }) => {
      if (onError) {
        onError(error, info);
      }
    },
    [onError]
  );
  return <ReactErrorBoundary {...props} onError={handleError} />;
}
