import { AxiosError } from 'axios';
import * as React from 'react';
import useHttp from '../hooks/useHttp';
import { generateNamedAction, generateNamedActionWithPayload, NamedAction, NamedActionPayload } from './actions';

interface UserState {
  id: string;
  email: string;
  phoneNumber: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  isExternalLogin: boolean;
  hasAuthenticatorApp2Fa: boolean;
  hasPhone2Fa: boolean;
  companyId: number;
  defaultOperationId: number | null;
  appVersion: string;
  isMfaRequired: boolean;
  isAuthenticatorAppRequired: boolean;
  isSmsVerificationRequired: boolean;
  loading: boolean;
}

const initialUserProfileState: UserState = {
  id: '',
  email: '',
  phoneNumber: '',
  firstName: '',
  lastName: '',
  isAdmin: false,
  isExternalLogin: false,
  hasAuthenticatorApp2Fa: false,
  hasPhone2Fa: false,
  companyId: 0,
  defaultOperationId: null,
  appVersion: '',
  isAuthenticatorAppRequired: false,
  isMfaRequired: false,
  isSmsVerificationRequired: false,
  loading: true,
};

interface UserMethods {
  resetProfile(): void;
  fetchProfile(): Promise<void>;
  updateProfile(data: UserDataResponse): void;
}

type UserProfileProps = UserState & UserMethods;

export interface UserDataResponse extends UserState {
  adminCompanyId: number;
  company: { name: string; id: number };
}

interface ProxyState {
  name: string;
  isProxy: boolean;
}

export type CompanyProxyProps = ProxyState;
interface ContextProps {
  profile: UserProfileProps;
  proxy: CompanyProxyProps;
}

const UserProfileContext = React.createContext<ContextProps | undefined>(undefined);

export function useCompanyProxy() {
  const ctx = React.useContext(UserProfileContext);
  if (ctx === undefined) {
    throw new Error('useCompanyProxy must be used within a UserCompanyProviderProvider.');
  }

  return ctx.proxy;
}

export function useUserProfile(): UserProfileProps {
  const ctx = React.useContext(UserProfileContext);
  if (ctx === undefined) {
    console.warn('useUserProfile must be used within a UserCompanyProviderProvider.');
    return {
      ...initialUserProfileState,
      resetProfile: () => void 0,
      fetchProfile: async () => void 0,
      updateProfile: (d) => void 0,
    };
  }

  return ctx.profile;
}

export interface UserProfileConsumerProps {
  children(props: UserProfileProps): React.ReactNode;
}

export function UserProfileConsumer({ children }: UserProfileConsumerProps) {
  return (
    <UserProfileContext.Consumer>
      {(ctx) => {
        if (ctx === undefined) {
          throw new Error('UserProfileConsumer must be used within a UserCompanyProviderProvider.');
        }

        return children(ctx.profile);
      }}
    </UserProfileContext.Consumer>
  );
}

export interface UserCompanyProviderProviderProps {
  children?: React.ReactNode;
}

interface State {
  profile: UserState;
  companyName: string;
  isProxy: boolean;
}

const initialState: State = {
  profile: initialUserProfileState,
  companyName: '',
  isProxy: false,
};

export function UserCompanyProvider(props: UserCompanyProviderProviderProps) {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  const [http, token] = useHttp();
  const fetchProfile = React.useCallback(async () => {
    await http
      .get<UserDataResponse>('/profile', { cancelToken: token })
      .then(({ data }) => {
        dispatch(generateNamedActionWithPayload('USER_FETCH_SUCCEEDED', data));
      })
      .catch((err: AxiosError) => {
        dispatch(generateNamedAction('CLEAR_STATE'));
      });
  }, [http, token]);

  const methods = React.useMemo(
    () => ({
      resetProfile: () => dispatch(generateNamedAction('CLEAR_STATE')),
      fetchProfile: async () => await fetchProfile(),
      updateProfile: (data: UserDataResponse) => dispatch(generateNamedActionWithPayload('USER_FETCH_SUCCEEDED', data)),
    }),
    [fetchProfile]
  );

  const proxyValue: CompanyProxyProps = React.useMemo(
    () => ({
      name: state.companyName,
      isProxy: state.isProxy,
    }),
    [state.companyName, state.isProxy]
  );

  const userValue: UserProfileProps = React.useMemo(
    () => ({
      ...state.profile,
      resetProfile: methods.resetProfile,
      fetchProfile: methods.fetchProfile,
      updateProfile: methods.updateProfile,
    }),
    [methods, state.profile]
  );

  const combinedValue = React.useMemo(
    () => ({
      profile: userValue,
      proxy: proxyValue,
    }),
    [proxyValue, userValue]
  );

  return <UserProfileContext.Provider {...props} value={combinedValue} />;
}

type ReducerActions = NamedActionPayload<'USER_FETCH_SUCCEEDED', UserDataResponse> | NamedAction<'CLEAR_STATE'>;

function reducer(state: State, action: ReducerActions): State {
  switch (action.type) {
    case 'USER_FETCH_SUCCEEDED':
      return {
        companyName: action.payload.company.name,
        isProxy: action.payload.adminCompanyId > 0,
        profile: getUserState(action.payload),
      };

    case 'CLEAR_STATE':
      return initialState;

    default:
      return state;
  }
}

export function getUserState(data: UserDataResponse): UserState {
  const {
    id,
    email,
    phoneNumber,
    firstName,
    lastName,
    hasAuthenticatorApp2Fa,
    hasPhone2Fa,
    isAdmin,
    isExternalLogin,
    company,
    defaultOperationId,
    appVersion,
    isMfaRequired,
    isAuthenticatorAppRequired,
    isSmsVerificationRequired,
  } = data;
  return {
    id,
    email,
    phoneNumber,
    firstName,
    lastName,
    hasAuthenticatorApp2Fa,
    hasPhone2Fa,
    isAdmin: isAdmin === undefined ? false : isAdmin,
    isExternalLogin: isExternalLogin === undefined ? false : isExternalLogin,
    companyId: company.id,
    defaultOperationId: defaultOperationId,
    appVersion: appVersion,
    isMfaRequired: isMfaRequired,
    isAuthenticatorAppRequired: isAuthenticatorAppRequired,
    isSmsVerificationRequired: isSmsVerificationRequired,
    loading: false,
  };
}
