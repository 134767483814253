import React from 'react';
import { useParams } from 'react-router-dom';
import { useUserProfile } from '../global/UserCompanyContext';

export function useGetUsersLastViewedOperation(): number {
  const { defaultOperationId, email } = useUserProfile();
  const usersLastViewedOperation = email + '_LastViewedOperation';
  const params = useParams();

  return React.useMemo(() => {
    return Number.isNaN(Number(params.operationId))
      ? localStorage.getItem(usersLastViewedOperation) === null
        ? Number(defaultOperationId)
        : Number(localStorage.getItem(usersLastViewedOperation))
      : Number(params.operationId);
  }, [params.operationId, defaultOperationId, usersLastViewedOperation]);
}

export function useManageUsersLastViewedOperation() {
  const { email } = useUserProfile();
  const usersLastViewedOperation = email + '_LastViewedOperation';
  const clear = React.useCallback(() => localStorage.removeItem(usersLastViewedOperation), [usersLastViewedOperation]);

  return { clear };
}

export function useUpdateUsersLastViewedOperation(operationId: number) {
  const { email } = useUserProfile();
  const usersLastViewedOperation = email + '_LastViewedOperation';

  return React.useEffect(() => {
    localStorage.setItem(usersLastViewedOperation, operationId.toString());
  }, [operationId, usersLastViewedOperation]);
}
