import * as React from 'react';
import { ConfirmButton, mergeClasses } from './';

interface EndProxyButtonProps {
  className?: string;
  companyName: string | undefined;
  onClick: () => void;
  confirmMessage?: string;
}

function EndProxyButton({ className, companyName, confirmMessage, onClick, ...props }: EndProxyButtonProps) {
  return (
    <ConfirmButton
      {...props}
      className={mergeClasses('', className)}
      onClick={onClick}
      confirmMessage={confirmMessage || `Are you sure you want to end the proxy as an employee of ${companyName}?`}
      title={`End ${companyName} Proxy Session`}
    >
      End Proxy Session
    </ConfirmButton>
  );
}

export default EndProxyButton;
