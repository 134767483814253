import * as React from 'react';
import { useRef, useState } from 'react';
import { useErrors, useFutureState, useNavigate } from '../../hooks';
import createHttp from '../../util/http';
import {
  Alert,
  CancelButton,
  PageTemplate,
  PageSection,
  PasswordInput,
  SaveButton,
  DefaultErrorAlert,
} from '../common';

function ChangePasswordPage() {
  const navigate = useNavigate();
  const [message, setMessage] = useFutureState<string | null>(null);
  const [currentPassword, setCurrentPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [saving, setSaving] = useState(false);
  const { errors, clear: clearErrors, addError, catchErrors } = useErrors();
  const http = useRef(createHttp(false));

  function handleSave(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (password !== confirmPassword) {
      addError('confirmPassword', 'Password do not match.');
      return;
    }

    setSaving(true);
    clearErrors();
    const data = { currentPassword, password, confirmPassword };
    http.current
      .put('profile/password', data)
      .then(() => {
        setMessage('Password Successfully Changed');
        setMessage(null, 5000);
        setCurrentPassword('');
        setPassword('');
        setConfirmPassword('');
        navigate('/profile');
      })
      .catch((error) => catchErrors(error))
      .finally(() => setSaving(false));
  }

  return (
    <PageTemplate loading={false} title="Change Password" backButtonLocation="/profile">
      <PageSection>
        <form onSubmit={handleSave}>
          {message === null ? null : <Alert theme="success">{message}</Alert>}
          <DefaultErrorAlert errors={errors} />
          <p className="py-3 text-base">
            Passwords require a length of at least 12 characters including at least one of each of the following:
            lowercase, uppercase, numeric, and special character.
          </p>
          <div className="flex flex-col mt-6 gap-y-6">
            <PasswordInput
              name="currentPassword"
              label="Current Password"
              value={currentPassword}
              autoFocus={true}
              errors={errors}
              required={true}
              autoComplete="current-password"
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
            <PasswordInput
              name="password"
              label="New Password"
              value={password}
              errors={errors}
              required={true}
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            <PasswordInput
              name="confirmPassword"
              label="Confirm Password"
              value={confirmPassword}
              errors={errors}
              required={true}
              autoComplete="new-password"
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="pt-5">
            <div className="flex justify-end">
              <CancelButton to="/profile" />
              <SaveButton isSaving={saving} className="ml-3" />
            </div>
          </div>
        </form>
      </PageSection>
    </PageTemplate>
  );
}

export default ChangePasswordPage;
