import { ChangeEventHandler, Dispatch, SetStateAction, useCallback } from 'react';
import { genericChangeHandler } from '../components/common';

export default function useSetStateChangeHandler<
  TElement extends HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
  TState = string
>(action: Dispatch<SetStateAction<TState>>): ChangeEventHandler<TElement> {
  return useCallback(
    function setStateHandler(e: React.ChangeEvent<TElement>) {
      action((prev) => {
        if (typeof prev === 'object') {
          return genericChangeHandler<TState>(e.target.name, e.target.value, prev);
        } else {
          return e.target.value as any;
        }
      });
    },
    [action]
  );
}
