import { User, UserManager, UserManagerSettings, Log } from 'oidc-client';
import Config from '../Config';

type OnLoginRedirectCallback = (url: string) => void;

interface AuthServiceConfig {
  id: string;
  authority: string;
  client_id: string;
  redirect_uri: string;
  response_type: string;
  post_logout_redirect_uri: string;
  scope: string;
  silent_redirect_uri?: string;
  popup_redirect_uri?: string;
  metadata?: {};
}

const authConfig: AuthServiceConfig = {
  id: 'defensix',
  authority: Config.IdentityURI,
  client_id: 'defensix',
  redirect_uri: Config.DefensixURI + '/callback',
  response_type: 'code',
  scope: 'defensix_api.web openid',
  post_logout_redirect_uri: Config.DefensixURI + '/login',
  silent_redirect_uri: Config.DefensixURI + '/login-silent.html',
  popup_redirect_uri: Config.DefensixURI + '/login-popup.html',
};

class AuthService {
  private userManager: UserManager;
  private user: User | null = null;
  private redirectUrl: string | null = null;
  private autoAuth = true;
  private silentLoginResponse: Promise<void> | null = null;

  public onLoginRedirect: OnLoginRedirectCallback | null = null;

  constructor(config: AuthServiceConfig) {
    const providerConfig: UserManagerSettings = {
      authority: config.authority,
      client_id: config.client_id,
      redirect_uri: config.redirect_uri,
      response_type: config.response_type,
      scope: config.scope,
      post_logout_redirect_uri: config.post_logout_redirect_uri,
      silent_redirect_uri: config.silent_redirect_uri,
      automaticSilentRenew: true,
      popup_redirect_uri: config.popup_redirect_uri,
      popupWindowTarget: 'LoginIFrame',
      loadUserInfo: false,
    };

    this.redirectToLogin = this.redirectToLogin.bind(this);
    this.getAccessToken = this.getAccessToken.bind(this);
    this.isExpired = this.isExpired.bind(this);
    this.getAutoAuth = this.getAutoAuth.bind(this);
    this.silentLogin = this.silentLogin.bind(this);
    this.logout = this.logout.bind(this);
    this.postExternalReturnUrlResponse = this.postExternalReturnUrlResponse.bind(this);

    this.userManager = new UserManager(providerConfig);
    this.userManager.events.addSilentRenewError(this.silentRenewError);
    this.userManager.events.addUserLoaded(this.assignUser);
    this.userManager.events.addUserUnloaded(this.userUnloaded);
    this.userManager.getUser().then(this.assignUser);

    window.addEventListener('message', this.postExternalReturnUrlResponse, false);
  }

  get events() {
    return this.userManager.events;
  }

  getAccessToken() {
    return this.user ? this.user.access_token : '';
  }

  isExpired() {
    return this.user && (this.user.expired || false);
  }

  getAutoAuth() {
    return this.autoAuth;
  }

  redirectToLogin(currentPath: string): Promise<string> {
    this.redirectUrl = currentPath;
    return this.userManager
      .signinPopup({ state: { url: this.redirectUrl } })
      .then((user: User) => {
        return user.state && user.state.url ? user.state.url : '/';
      })
      .catch((e) => {
        // Pause automatically authenticating if we get an error to prevent looping.
        this.autoAuth = false;
        console.error('Error authenticating', e);
        throw e;
      });
  }

  async silentLogin(currentPath: string): Promise<void> {
    if (!this.autoAuth) {
      return;
    }

    this.redirectUrl = currentPath;
    if (this.silentLoginResponse === null) {
      try {
        this.silentLoginResponse = this.userManager.signinSilent({ state: { url: currentPath } }).then((u) => {});
        await this.silentLoginResponse;
      } catch (e: unknown) {
        this.silentRenewError(e as Error);
      } finally {
        this.silentLoginResponse = null;
      }
    } else {
      await this.silentLoginResponse;
    }
  }

  handleCallback(): Promise<string> {
    return this.userManager
      .signinRedirectCallback()
      .then((user: User) => (user.state && user.state.url ? user.state.url : ''))
      .catch((e) => {
        // Pause automatically authenticating if we get an error to prevent looping.
        this.autoAuth = false;
        console.error('Error authenticating', e);
      });
  }

  logout() {
    const idToken = this.user !== null ? this.user.id_token : null;
    this.user = null;
    this.autoAuth = false;
    this.userManager.signoutRedirect({ id_token_hint: idToken });
  }

  private silentRenewError = (e: Error) => {
    if (this.onLoginRedirect !== null) {
      this.onLoginRedirect(this.redirectUrl || '/');
    }
  };

  private assignUser = (user: User | null): User | null => {
    this.user = user;
    if (user && user.access_token) {
      this.autoAuth = true;
    }
    return user;
  };

  private userUnloaded = () => {
    this.user = null;
  };

  private postExternalReturnUrlResponse = (event: MessageEvent) => {
    if (event.origin !== Config.IdentityURI) {
      return;
    }
    if (event.data === 'ExternalReturnUrlQuery' && event.source) {
      (event.source as WindowProxy).postMessage({ externalReturnUrlResponse: this.redirectUrl }, event.origin);
    }
  };
}

if (process.env.NODE_ENV !== 'production') {
  Log.logger = console;
  Log.level = Log.INFO;
}

class LazyAuthService {
  private static authService: AuthService | null = null;

  public static get() {
    if (LazyAuthService.authService === null) {
      LazyAuthService.authService = new AuthService(authConfig);
    }
    return LazyAuthService.authService;
  }
}

export type AuthenticationService = AuthService;

export default LazyAuthService;
