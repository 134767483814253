import * as React from 'react';
import { ErrorMarker, GenericInput, GenericInputProps } from '.';
import Textarea from './Textarea';

export type TextAreaInputProps = GenericInputProps &
  React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    containerClassName?: string;
  };

const TextAreaInput = React.forwardRef(
  (
    {
      id,
      value,
      name,
      label,
      required,
      containerClassName,
      labelClassName,
      errors,
      helptip,
      spellCheck,
      rows,
      ...props
    }: TextAreaInputProps,
    ref: React.Ref<HTMLTextAreaElement>
  ) => {
    return (
      <GenericInput
        id={id}
        name={name}
        label={label}
        required={required}
        className={containerClassName}
        labelClassName={labelClassName}
        errors={errors}
        helptip={helptip}
      >
        {({ hasError, ...inputProps }) => (
          <>
            <Textarea
              {...inputProps}
              {...props}
              name={name}
              required={required}
              value={value ?? ''}
              theme={hasError ? 'error' : 'standard'}
              rows={rows ?? 3}
              spellCheck={spellCheck === false}
              ref={ref}
            />
            <ErrorMarker
              hasError={hasError}
              className="absolute inset-y-3 right-0 pr-3 flex items-start pointer-events-none"
            />
          </>
        )}
      </GenericInput>
    );
  }
);
export default TextAreaInput;
