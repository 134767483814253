const vowels = ['A', 'E', 'I', 'O', 'U'];
export function formatIndefiniteArticle(noun: string): string {
  if (noun.length === 0) {
    return 'a';
  }

  return vowels.indexOf(noun[0].toUpperCase()) === -1 ? 'a' : 'an';
}

export function formatBooleanAsYesNo(bool: boolean): string {
  return bool ? 'Yes' : 'No';
}

export function formatCamelCaseAsWords(camelCase: string): string {
  return Array.from(camelCase).reduce((prev, cur) => {
    if (prev.length === 0) {
      return cur.toUpperCase();
    }

    if (isUpperCase(cur) && !isUpperCase(prev[prev.length - 1])) {
      return prev + ' ' + cur;
    }

    return prev + cur;
  }, '');
}

export function isUpperCase(c: string) {
  return c === c.toUpperCase();
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function formatMonthYear(date: Date): string {
  return monthNames[date.getMonth()] + ' ' + date.getFullYear();
}

export function formatMonthDateYear(date: Date): string {
  return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
}

const DefaultDateTimeFormatOptions: Intl.DateTimeFormatOptions = {
  dateStyle: 'long',
};

export function formatLongDate(date: Date, options?: Intl.DateTimeFormatOptions): string {
  return new Intl.DateTimeFormat(undefined, options ?? DefaultDateTimeFormatOptions).format(date);
}

export function formatDateToYYYYMMDD(date: Date): string {
  const offset = date.getTimezoneOffset();
  date = new Date(date.getTime() - offset * 60 * 1000);
  return date.toISOString();
}
export function formatDateRange(from?: Date | string, to?: Date | string) {
  const fromDate = from instanceof Date ? from : new Date(from as string);
  const toDate = to instanceof Date ? to : new Date(to as string);
  if (from && to) {
    return formatDateInternalUTC(fromDate) + ' - ' + formatDateInternalUTC(toDate);
  }

  if (from) {
    return formatDateInternalUTC(fromDate);
  }

  if (to) {
    return formatDateInternalUTC(toDate);
  }

  return '';
}

export function formatDate(date?: Date | string) {
  if (!date) {
    return '';
  }
  const dateObj = date instanceof Date ? date : new Date(date as string);
  return formatDateInternalUTC(dateObj);
}

export function formatDateTime(date?: Date | string) {
  if (!date) {
    return '';
  }
  const dateObj = date instanceof Date ? date : new Date(date as string);
  return formatDateTimeInternalUTC(dateObj);
}

export function formatDateNonUTC(date?: Date | string) {
  if (!date) {
    return '';
  }
  const dateObj = date instanceof Date ? date : new Date(date as string);
  return dateObj.toLocaleDateString();
}

export function formatDateTimeNonUTC(date?: Date | string) {
  if (!date) {
    return '';
  }
  const dateObj = date instanceof Date ? date : new Date(date as string);
  return dateObj.toLocaleString();
}

function formatDateInternalUTC(date: Date): string {
  const utcDate = transformToUtc(date);
  return utcDate.toLocaleDateString();
}
function formatDateTimeInternalUTC(date: Date): string {
  const utcDate = transformToUtc(date);
  return utcDate.toLocaleString();
}

function transformToUtc(date: Date): Date {
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds()
    )
  );
}

// https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date#value
export function formatDateForInput(date?: Date): string | undefined {
  if (date === undefined) {
    return undefined;
  }

  return date.toISOString().substring(0, 10);
}

export function stripTimeFromDateString(date: string): string {
  return date.split('T')[0];
}

export function isDateValid(date?: Date): boolean {
  if (!!!date) {
    return false;
  }
  return !Number.isNaN(new Date(date).getTime());
}
