interface DefensixConfig {
  apiBase: string;
  loginBase: string;
  version: string;
  environment: string;
  helpHero: string;
  agentPlatforms: string[];
}

declare global {
  interface Window {
    Defensix: {
      Config: DefensixConfig;
    };
  }
}

window.Defensix = window.Defensix || {};
const injectedConfig: DefensixConfig = window.Defensix.Config || {};
const config = {
  IdentityURI: injectedConfig.loginBase,
  DefensixURI: injectedConfig.apiBase,
  ApiURI: injectedConfig.apiBase + '/api',
  environment: injectedConfig.environment,
  version: injectedConfig.version,
  helpHero: injectedConfig.helpHero,
  agentPlatforms: injectedConfig.agentPlatforms,
};

export default config;
