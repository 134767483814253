import { Map } from 'immutable';
import React from 'react';
import { WithClassName } from '..';
import ErrorAlert from './ErrorAlert';

export type AllErrorsAlertProps = WithClassName & {
  errors: Map<string, string[]>;
};
export default function AllErrorsAlert({ errors, className }: AllErrorsAlertProps) {
  const errorArray = errors.reduce((a, v) => {
    a.push(...v);
    return a;
  }, [] as string[]);
  return <ErrorAlert errors={errorArray} className={className} />;
}
