import * as React from 'react';
import { LinkProps } from 'react-router-dom';
import { Button, ButtonProps } from '.';
import ConfirmationDialog from './ConfirmationDialog';

export type ConfirmButtonProps = Omit<
  Exclude<ButtonProps, React.AnchorHTMLAttributes<HTMLAnchorElement> | LinkProps>,
  'onClick'
> & {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  confirmTitle?: string;
  confirmMessage?: string | React.ReactNode;
  className?: string;
};

function ConfirmButton({ confirmTitle, confirmMessage, className, onClick, ...props }: ConfirmButtonProps) {
  const [showDialog, setShowDialog] = React.useState(false);
  const eventRef = React.useRef<React.MouseEvent<HTMLButtonElement> | null>(null);

  const handleShowDialog = (e: React.MouseEvent<HTMLButtonElement>) => {
    eventRef.current = e;
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    eventRef.current = null;
    setShowDialog(false);
  };

  const handleAccept = () => {
    const e = eventRef.current;
    handleCloseDialog();
    if (onClick) {
      onClick(e!);
    }
  };

  const typedProps = props as React.ButtonHTMLAttributes<HTMLButtonElement>;
  return (
    <>
      <Button {...typedProps} onClick={handleShowDialog} className={className} />
      <ConfirmationDialog
        title={confirmTitle || 'Are you sure?'}
        message={confirmMessage || 'Are you sure you want to proceed?'}
        show={showDialog}
        onAccept={handleAccept}
        onDecline={handleCloseDialog}
      />
    </>
  );
}

export default ConfirmButton;
