import { useCallback } from 'react';
import {
  useNavigate as useNavigateRR,
  NavigateFunction as NavigateFunctionRR,
  NavigateOptions,
  To,
} from 'react-router-dom';
import { NavigateParams } from '../components/common';

export type NavigateFunction = NavigateFunctionRR & {
  (to: NavigateParams): void;
};

// Wrapper around useNavigate from react-router-dom to accept our
// custom NavigateParams objects
export default function useNavigate(): NavigateFunction {
  const navigate = useNavigateRR();
  return useCallback(
    (location: NavigateParams | To | number, options?: NavigateOptions) => {
      if (isNavigateParams(location)) {
        navigate(location.to, { replace: location.replace, state: location.state });
      } else if (typeof location === 'number') {
        navigate(location);
      } else {
        navigate(location, options);
      }
    },
    [navigate]
  );
}

function isNavigateParams(location: NavigateParams | To | number): location is NavigateParams {
  return typeof location === 'object' && (location as NavigateParams).to !== undefined;
}
