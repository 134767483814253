import { Transition } from '@headlessui/react';
import { useEffect, useRef, useState } from 'react';
import { mergeClasses, WithClassName } from '.';
import { SpinnerIcon } from './icons';

export type LoadingIndicatorProps = Omit<AnimatedLoadingIndicatorProps, 'show'> & { iconClassName?: string };
export type AnimatedLoadingIndicatorProps = WithClassName<{ show: boolean }>;

export function LoadingIndicator({ className, iconClassName }: LoadingIndicatorProps) {
  return (
    <div className={mergeClasses('flex items-center justify-center', className)}>
      <div className="flex-grow-0">
        <SpinnerIcon className={mergeClasses('ml-3 -mr-1 h-24 w-24 animate-spin text-primary', iconClassName)} />
      </div>
    </div>
  );
}

export function LoadingOverlay({ className }: WithClassName<{}>) {
  return <Overlay className={className} show={true} />;
}

export function AnimatedLoadingOverlay({ className, show }: AnimatedLoadingIndicatorProps) {
  return (
    <Transition
      className="z-40"
      show={show}
      leave="transition-opacity duration-300"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
    >
      <Overlay className={className} show={show} />
    </Transition>
  );
}

function Overlay({ className, show }: AnimatedLoadingIndicatorProps) {
  const visible = useDelayedVisible(show);
  return (
    <div
      className={mergeClasses(
        'absolute z-10 flex h-full w-full flex-col items-center justify-start bg-gray-200',
        className
      )}
    >
      <Transition show={visible} enter="transition-opacity duration-100" enterFrom="opacity-0" enterTo="opacity-100">
        <LoadingIndicator className="relative inset-y-10 md:inset-y-28" />
      </Transition>
    </div>
  );
}

function useDelayedVisible(show: boolean) {
  const [visible, setVisible] = useState(false);
  const timeoutHandle = useRef(-1);

  useEffect(() => {
    if (show) {
      timeoutHandle.current = window.setTimeout(() => {
        setVisible(true);
      }, 500);
    } else {
      setVisible(false);
    }

    return () => {
      window.clearTimeout(timeoutHandle.current);
    };
  }, [show]);

  return visible;
}
