import { twMerge } from 'tailwind-merge';

type ClassType = string | null | undefined | { className: string; enable: boolean };

export default function mergeClasses(...classes: ClassType[]): string {
  const filteredClasses = classes.reduce((prev: string, next) => {
    let add = '';
    if (typeof next === 'object') {
      if (next && next.enable) {
        add = next.className.trim();
      }
    } else if (typeof next === 'string') {
      add = next.trim();
    }

    return add === '' ? prev : prev.concat(' ', add);
  }, '');

  const merge = twMerge(filteredClasses.trimStart());
  return merge;
}
