import { noop } from 'lodash';
import {
  createContext,
  MutableRefObject,
  ReactElement,
  Suspense,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { NavigateProps, useOutlet } from 'react-router-dom';
import { LoadingIndicator, WithClassName } from '.';
import { useNavigate } from '../../hooks';
import { Actions, Body, Content, ContentProps, Panel } from './SlideOverCommon';
export type {
  BodyProps as SlideOverOutletBodyProps,
  ActionsProps as SlideOverOutletActionsProps,
} from './SlideOverCommon';

const EmptyLocation: NavigateProps = { to: '' };

interface SlideOverOutletContextState {
  initialFocusRef: MutableRefObject<HTMLElement | null>;
  setOnCloseLocation(value: NavigateProps): void;
  handleClose(): void;
}

const DefaultSlideOverOutletContextState: SlideOverOutletContextState = {
  initialFocusRef: { current: null },
  setOnCloseLocation: noop,
  handleClose: noop,
};

const SlideOverOutletContext = createContext<SlideOverOutletContextState>(DefaultSlideOverOutletContextState);

export function useSlideOverOutletInitialFocusRef<
  TElement extends HTMLElement = HTMLElement
>(): MutableRefObject<TElement | null> {
  const { initialFocusRef } = useContext(SlideOverOutletContext);
  return initialFocusRef as MutableRefObject<TElement | null>;
}

export interface SlideOverOutletProps {}

function SlideOverOutlet({ className }: WithClassName<SlideOverOutletProps>) {
  const [onCloseLocation, setOnCloseLocation] = useState<NavigateProps>(EmptyLocation);
  const initialFocusRef = useRef<HTMLElement | null>(null);
  const outlet = useOutlet();

  // Cache the last outlet value so we can display it during the exit transition.
  const outletCache = useRef<ReactElement | null>(null);
  if (outlet !== null) {
    outletCache.current = outlet;
  }

  // The dialog must always have a focusable element, so if there is no outlet yet
  // render content section with a blank title and close location.
  if (outletCache.current === null) {
    outletCache.current = <SlideOverOutletContent title="" onCloseLocation={EmptyLocation} />;
  }

  const navigate = useNavigate();
  const handleClose = useCallback(() => {
    navigate(onCloseLocation);
  }, [navigate, onCloseLocation]);

  const ctxValue = useMemo(
    (): SlideOverOutletContextState => ({
      initialFocusRef,
      setOnCloseLocation,
      handleClose,
    }),
    [handleClose]
  );

  const clearOutletCache = useCallback(() => {
    outletCache.current = null;
  }, []);

  return (
    <SlideOverOutletContext.Provider value={ctxValue}>
      <Panel
        open={outlet !== null}
        initialFocus={initialFocusRef}
        onClose={handleClose}
        afterLeave={clearOutletCache}
        className={className}
      >
        <Suspense fallback={<LoadingContent />}>{outletCache.current}</Suspense>
      </Panel>
    </SlideOverOutletContext.Provider>
  );
}

function LoadingContent() {
  return (
    <SlideOverOutletContent title="" onCloseLocation={EmptyLocation}>
      <LoadingIndicator className="mt-6" />
    </SlideOverOutletContent>
  );
}

export type SlideOverOutletContentProps = Pick<ContentProps, 'title' | 'subtitle' | 'children'> & {
  loading?: boolean;
  onCloseLocation: NavigateProps;
};

function SlideOverOutletContent({ title, subtitle, onCloseLocation, loading, children }: SlideOverOutletContentProps) {
  const { setOnCloseLocation, handleClose } = useContext(SlideOverOutletContext);

  useEffect(() => {
    setOnCloseLocation(onCloseLocation);
  }, [onCloseLocation, setOnCloseLocation]);

  return (
    <Content title={title} subtitle={subtitle} onClose={handleClose}>
      {loading ?? false ? <LoadingIndicator className="mt-6" /> : children}
    </Content>
  );
}

export default Object.assign(SlideOverOutlet, {
  Content: SlideOverOutletContent,
  Body: Body,
  Actions: Actions,
});
