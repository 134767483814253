import React from 'react';
import { ErrorType, normalizeErrors } from './renderErrors';

export interface FieldErrorsAlertProps {
  errors: ErrorType;
}
export default function FieldErrorsAlert(props: FieldErrorsAlertProps) {
  const err = normalizeErrors(props.errors);
  if (err.length === 0) {
    return null;
  }

  return <p className="mt-2 text-sm text-red-600">{err[0]}</p>;
}
