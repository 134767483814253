import DOMPurify from 'dompurify';

export function HtmlBlock({ html }: { html: string }) {
  if (html.startsWith('<html>')) {
    html = getHtmlBody(html);
  }

  return html === '' ? (
    <p className="text-xs italic">Empty</p>
  ) : (
    <div className="whitespace-pre-wrap" dangerouslySetInnerHTML={{ __html: getDOMPurifySanitizedHtml(html) }} />
  );
}

function getHtmlBody(html: string) {
  var parser = new DOMParser();
  var doc = parser.parseFromString(html, 'text/html');
  return doc.body.innerHTML;
}

export function urlifyText(text: string) {
  var anchorRegex = /<a[\s]+([^>]+)>((?:.(?!<\/a>))*.)<\/a>/g;
  if (anchorRegex.test(text)) {
    return text;
  }
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function (url) {
    return `<a href="${url}" target="_blank" class="text-blue-600 hover:underline">${url}</a>`;
  });
}

export function getDOMPurifySanitizedHtml(html: string) {
  var config = { ADD_TAGS: ['ncode', 'red'] };
  return DOMPurify.sanitize(html, config);
}
