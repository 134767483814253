import { ArrowCircleLeftIcon, HomeIcon } from '@heroicons/react/solid';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './common';

function Error404() {
  const navigate = useNavigate();
  const canGoBack = window.history.length > 1;
  return (
    <>
      <div className="bg-white min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
        <div className="max-w-max mx-auto">
          <main className="sm:flex">
            <div className="sm:ml-6">
              <div className="sm:border-l sm:border-gray-200 sm:pl-6">
                <h1 className="text-4xl font-extrabold text-gray-800 tracking-tight sm:text-5xl">
                  Oops! Are you lost?
                </h1>
                <p className="mt-1 text-base text-gray-500">We can not find the page you're looking for.</p>
              </div>
              <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
                {canGoBack && (
                  <Button
                    onClick={(event: React.MouseEvent<HTMLElement>) => {
                      event.preventDefault();
                      navigate(-1);
                    }}
                  >
                    <ArrowCircleLeftIcon className="mr-3 h-5 w-5" />
                    Go back
                  </Button>
                )}
                <Button to="/" theme={canGoBack ? 'white' : 'primary'}>
                  <HomeIcon className="mr-3 h-5 w-5" /> Return home
                </Button>
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
export default Error404;
