import React from 'react';

export default function PowerIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" {...props}>
      <path
        d="M3.2,10.5A1.557,1.557,0,0,1,1.6,12,1.557,1.557,0,0,1,0,10.5,1.557,1.557,0,0,1,1.6,9,1.557,1.557,0,0,1,3.2,10.5Zm-1.6-6A1.557,1.557,0,0,0,0,6,1.557,1.557,0,0,0,1.6,7.5,1.557,1.557,0,0,0,3.2,6,1.557,1.557,0,0,0,1.6,4.5ZM1.6,0A1.557,1.557,0,0,0,0,1.5,1.557,1.557,0,0,0,1.6,3,1.557,1.557,0,0,0,3.2,1.5,1.557,1.557,0,0,0,1.6,0ZM6.4,3A1.557,1.557,0,0,0,8,1.5,1.557,1.557,0,0,0,6.4,0,1.557,1.557,0,0,0,4.8,1.5,1.557,1.557,0,0,0,6.4,3Zm0,1.5A1.557,1.557,0,0,0,4.8,6,1.557,1.557,0,0,0,6.4,7.5,1.557,1.557,0,0,0,8,6,1.557,1.557,0,0,0,6.4,4.5ZM6.4,9a1.557,1.557,0,0,0-1.6,1.5A1.557,1.557,0,0,0,6.4,12,1.557,1.557,0,0,0,8,10.5,1.557,1.557,0,0,0,6.4,9Z"
        fill="currentColor"
      />
    </svg>
  );
}
