import { CheckCircleIcon, ExclamationIcon, InformationCircleIcon, XIcon } from '@heroicons/react/outline';
import { NotificationLevel } from '../../global/Notifications';
import { mergeClasses } from '../common';
import React, { ForwardedRef } from 'react';

type NotificationStyle = {
  icon: React.ComponentType<React.ComponentProps<'svg'>>;
  className: string;
};

const DefaultStyle: NotificationStyle = { icon: ExclamationIcon, className: 'text-state-error' };

const NotificationStyles: Record<NotificationLevel, NotificationStyle> = {
  [NotificationLevel.Error]: DefaultStyle,
  [NotificationLevel.Warning]: { icon: ExclamationIcon, className: 'text-state-warn' },
  [NotificationLevel.Info]: { icon: InformationCircleIcon, className: 'text-state-info' },
  [NotificationLevel.Success]: { icon: CheckCircleIcon, className: 'text-state-success' },
};

export interface StandardNotificationProps {
  header?: string;
  message: string;
  level: NotificationLevel;
  close(): void;
}

export const StandardNotification = React.forwardRef(
  ({ header, message, level, close }: StandardNotificationProps, ref: ForwardedRef<HTMLDivElement>) => {
    const { icon: Icon, className: iconClassName } = NotificationStyles[level] ?? DefaultStyle;

    return (
      <div
        ref={ref}
        className="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
      >
        <div className="p-4">
          <div className="flex items-start">
            <div className="flex-shrink-0">
              <Icon className={mergeClasses('h-6 w-6', iconClassName)} aria-hidden="true" />
            </div>
            <div className="ml-3 w-0 flex-1 pt-0.5">
              {header ? <p className="text-sm font-medium text-gray-900">{header}</p> : null}
              <p className={mergeClasses('text-sm text-gray-500', { className: 'mt-1', enable: !!header })}>
                {message}
              </p>
            </div>
            <div className="ml-4 flex flex-shrink-0">
              <button
                type="button"
                className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2"
                onClick={close}
              >
                <span className="sr-only">Close</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
