import { ApiBase } from '../../apiBase';
import { UserDataResponse } from '../../global/UserCompanyContext';

export default class ProfileApi extends ApiBase {
  public getAuthenticatorQrCode = async (): Promise<string> => {
    return (await this.get<string>('/profile/2fa/qrcode')) ?? '';
  };

  public verifyAuthenticatorCode = (code: string) => {
    return this.post<UserDataResponse>('/profile/2fa/qrcode', { code });
  };

  public removeMfa = () => {
    return this.delete<UserDataResponse>('/profile/2fa');
  };
}
