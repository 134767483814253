import React from 'react';
import { XIcon } from '@heroicons/react/solid';
import { ExclamationIcon, CheckCircleIcon, InformationCircleIcon } from '@heroicons/react/outline';
import { mergeClasses, WithClassName } from '.';

export type AlertTheme = 'error' | 'warning' | 'success' | 'info';

interface AlertThemeInfo {
  icon: React.ComponentType<React.ComponentProps<'svg'>>;
  containerClass: string;
  innerClass: string;
  dismissClass: string;
}

const themes: Record<AlertTheme, AlertThemeInfo> = {
  error: {
    icon: ExclamationIcon,
    containerClass: 'bg-red-50 border-l-red-600 ',
    innerClass: 'text-red-600',
    dismissClass: 'bg-red-50 text-red-800 hover:bg-red-100 focus:ring-offset-red-50 focus:ring-red-600',
  },
  warning: {
    icon: ExclamationIcon,
    containerClass: 'bg-yellow-50 border-l-yellow-500 ',
    innerClass: 'text-yellow-500',
    dismissClass: 'bg-yellow-50 text-yellow-800 hover:bg-yellow-100 focus:ring-offset-yellow-50 focus:ring-yellow-500',
  },
  success: {
    icon: CheckCircleIcon,
    containerClass: 'bg-green-50 border-l-green-500 ',
    innerClass: 'text-green-500',
    dismissClass: 'bg-green-50 text-green-800 hover:bg-green-100 focus:ring-offset-green-50 focus:ring-green-500',
  },
  info: {
    icon: InformationCircleIcon,
    containerClass: 'bg-blue-50 border-l-blue-600 ',
    innerClass: 'text-blue-600',
    dismissClass: 'bg-blue-50 text-blue-800 hover:bg-blue-100 focus:ring-offset-blue-50 focus:ring-blue-600',
  },
};

export type AlertProps = WithClassName & {
  children: React.ReactNode | string;
  theme: AlertTheme;
  onDismiss?: () => void;
};

export default function Alert({ children, className, theme, onDismiss }: AlertProps) {
  const info = themes[theme];

  if (info === undefined) throw Error('Invalid theme: ' + theme);

  return (
    <div className={mergeClasses('p-2 rounded-none border-l-4', info.containerClass, className)}>
      <div className="flex">
        <div className="flex-shrink-0">
          <info.icon className={'h-5 w-5 ' + info.innerClass} aria-hidden="true" />
        </div>
        <div className="ml-2 flex">
          {typeof children === 'string' ? (
            <p className={'text-sm font-medium ' + info.innerClass}>{children}</p>
          ) : (
            children
          )}
        </div>
        {onDismiss === undefined ? null : (
          <div className="ml-auto pl-3">
            <div className="-mx-1.5 -my-1.5">
              <button
                type="button"
                className={
                  'inline-flex rounded p-1.5 focus:outline-none focus:ring-2 focus:ring-offset-2 ' + info.innerClass
                }
                onClick={() => onDismiss()}
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
