import { List } from 'immutable';
import { ApiBase } from '../../apiBase';
import { UserPreferenceModel } from './userPrefereceModel';

export class UserPreferencesApi extends ApiBase {
  public getUserpreferences = (): Promise<List<UserPreferenceModel>> => {
    return this.get('/preferences');
  };

  public saveUserpreferences = (userPreference: UserPreferenceModel): Promise<List<UserPreferenceModel>> => {
    return this.post('/preferences', userPreference);
  };
}
