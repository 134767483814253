import * as React from 'react';
import { GenericInput, GenericInputProps } from '.';
import Select from './Select';

export interface SelectableListItem<T = number> extends SelectItem<T> {
  selected: boolean;
}

export interface SelectItem<T = number> {
  id: T;
  name: string;
}

export interface SelectOption {
  text: string;
  value: string | number | undefined;
}

function SelectItemToSelectOption<T = number>(item: SelectItem<T>): SelectOption {
  if (typeof item.id === 'string') {
    return { text: item.name, value: item.id };
  } else if (typeof item.id === 'number') {
    return { text: item.name, value: item.id.toString() };
  } else {
    return { text: item.name, value: undefined };
  }
}

export function SelectItemsToSelectOptions<T = number>(items: SelectItem<T>[]): SelectOption[] {
  return items.map(SelectItemToSelectOption);
}

export type SelectInputProps = GenericInputProps &
  React.SelectHTMLAttributes<HTMLSelectElement> & {
    containerClassName?: string;
    options: SelectOption[];
  };

export default React.forwardRef(function SelectInput(
  { id, value, name, label, required, containerClassName, labelClassName, errors, helptip, ...props }: SelectInputProps,
  ref: React.ForwardedRef<HTMLSelectElement>
) {
  return (
    <GenericInput
      id={id}
      name={name}
      label={label}
      required={required}
      className={containerClassName}
      labelClassName={labelClassName}
      errors={errors}
      helptip={helptip}
    >
      {({ hasError, ...inputProps }) => (
        <Select
          {...inputProps}
          {...props}
          name={name}
          required={required}
          value={value ?? ''}
          theme={hasError ? 'error' : 'standard'}
          ref={ref}
        />
      )}
    </GenericInput>
  );
});
