import mergeClasses from './mergeClasses';

export type ThemeName = 'primary' | 'error' | 'white';

export interface Theme {
  text: string;
  bg: string;
  border: string;
  ring: string;
  button: string;
}

function buildTheme(t: Pick<Theme, 'text' | 'bg' | 'border' | 'ring'>): Theme {
  return {
    ...t,
    button: mergeClasses(t.text, t.bg, t.border, t.ring),
  };
}

const theme: Record<ThemeName, Theme> = {
  primary: buildTheme({
    text: 'text-white',
    bg: 'bg-primary hover:bg-primary-600 disabled:bg-gray-400',
    border: '',
    ring: 'focus:outline-none focus:ring-primary focus:ring-2 focus:ring-offset-2',
  }),
  error: buildTheme({
    text: 'text-white',
    bg: 'bg-red-600 hover:bg-red-700',
    border: '',
    ring: 'focus:outline-none focus:ring-red-500 focus:ring-2 focus:ring-offset-2',
  }),
  white: buildTheme({
    text: '',
    bg: 'bg-white hover:bg-gray-50',
    border: 'border-gray-300',
    ring: 'focus:outline-none focus:ring-primary focus:ring-2 focus:ring-offset-2',
  }),
};

export default theme;
