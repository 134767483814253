import { SidebarTab } from '../../Sidebar';
import { useMatchPath } from './useMatchPath';

export interface SubTabRouteStateResponse {
  parentTab: SidebarTab;
  activeSubTab: OperationSubTab | AdminSubTab;
}

export enum OperationSubTab {
  Default = '',
  Workspace = 'Workspace',
  Run = 'Run',
  Timeline = 'Timeline',
  Heatmap = 'Heatmap',
  Reports = 'Reports',
  Edit = 'Edit',
  Coverage = 'Coverage',
}

export enum AdminSubTab {
  Default = '',
  AttackSurface = 'Attack Surfaces',
  DataSource = 'Data Sources',
  SecurityControlType = 'Security Control Types',
  SecurityVendor = 'Security Vendors',
  Industries = 'Industries',
  DataComponent = 'Data Components',
  DetectionStrategy = 'Detection Strategies',
  SalesPackage = 'Sales Packages',
  NotificationStrategy = 'Notification Strategies',
}

const useSubTabRouteState = (): SubTabRouteStateResponse => {
  const operationUrl = '/operation/:operationId';
  const AdminUrl = '/admin';
  const { localPathMatch, match } = useMatchPath(operationUrl);
  const { localPathMatch: adminPath, match: adminMatch } = useMatchPath(AdminUrl);

  if (match !== null && match.params.operationId?.toLowerCase() !== 'new') {
    let subTab = null;
    if (localPathMatch('/run')) {
      subTab = OperationSubTab.Run;
    } else if (localPathMatch('/detection/workspace')) {
      subTab = OperationSubTab.Workspace;
    } else if (localPathMatch('/detection/timeline')) {
      subTab = OperationSubTab.Timeline;
    } else if (localPathMatch('/detection/heatmap')) {
      subTab = OperationSubTab.Heatmap;
    } else if (localPathMatch('/coverage')) {
      subTab = OperationSubTab.Coverage;
    } else if (localPathMatch('/reports')) {
      subTab = OperationSubTab.Reports;
    } else if (!isNaN(Number(match.params.operationId))) {
      subTab = OperationSubTab.Edit;
    } else {
      subTab = OperationSubTab.Default;
    }
    return {
      parentTab: SidebarTab.OPERATIONS,
      activeSubTab: subTab,
    };
  } else if (adminMatch !== null) {
    let subTab = null;
    if (adminPath('/industry')) {
      subTab = AdminSubTab.Industries;
    } else if (adminPath('/salespackage')) {
      subTab = AdminSubTab.SalesPackage;
    }

    if (!!subTab) {
      return {
        parentTab: SidebarTab.COMPANY_SETTINGS,
        activeSubTab: subTab,
      };
    }

    if (adminPath('/attacksurface')) {
      subTab = AdminSubTab.AttackSurface;
    } else if (adminPath('/datacomponent')) {
      subTab = AdminSubTab.DataComponent;
    } else if (adminPath('/datasource')) {
      subTab = AdminSubTab.DataSource;
    } else if (adminPath('/detectionstrategy')) {
      subTab = AdminSubTab.DetectionStrategy;
    } else if (adminPath('/securitycontroltype')) {
      subTab = AdminSubTab.SecurityControlType;
    } else if (adminPath('/securityvendor')) {
      subTab = AdminSubTab.SecurityVendor;
    } else if (adminPath('/notificationstrategy')) {
      subTab = AdminSubTab.NotificationStrategy;
    } else {
      return {
        parentTab: SidebarTab.DEFAULT,
        activeSubTab: AdminSubTab.Default,
      };
    }

    return {
      parentTab: SidebarTab.DATA_SOURCE,
      activeSubTab: subTab,
    };
  }

  return {
    parentTab: SidebarTab.DEFAULT,
    activeSubTab: OperationSubTab.Default,
  };
};

export default useSubTabRouteState;
