import axios, { AxiosInstance } from 'axios';
import createHttp, { REQUEST_CANCELED } from './util/http';

export interface EntityApiClass<T, A extends EntityApiBase<T>> {
  new (): A;
}

export type GetEntityResponse<T> = { entity: T };
export type SaveEntityResponse = { id: EntityId };
export type DeleteEntityResponse = { id: EntityId };
export type EntityId = number | string;
export type EntityIdArray = EntityId[];

export interface EntityApi<T> {
  get: (ids: EntityIdArray, params?: any) => Promise<GetEntityResponse<T>>;
  save: (data: T, ids: any[], ...params: any[]) => Promise<SaveEntityResponse>;
  delete: (ids: EntityIdArray, params?: any) => Promise<DeleteEntityResponse>;
  cancel: () => void;
}

export function IsNewEntityId(id: EntityId): boolean {
  switch (typeof id) {
    case 'string':
      return id === 'new' || id === 'add' || (!isNaN(Number(id)) && Number(id) < 1);
    case 'number':
      return id < 1;
    default:
      return true;
  }
}

export abstract class EntityApiBase<T> implements EntityApi<T> {
  private _cancelToken = axios.CancelToken.source();
  private _http: AxiosInstance;
  private _baseUri: string;
  private _entityUri: string;

  constructor(baseUri: string, entityUri: string, http: AxiosInstance = createHttp()) {
    this._http = http;
    this._baseUri = baseUri;
    this._entityUri = entityUri;
  }

  public cancel = () => {
    const currentToken = this._cancelToken;
    this._cancelToken = axios.CancelToken.source();
    currentToken.cancel(REQUEST_CANCELED);
  };

  public get = async (ids: EntityIdArray, params: any = {}): Promise<GetEntityResponse<T>> => {
    const response = await this._http.get(this.generateEntityUrl(ids), {
      params,
      cancelToken: this._cancelToken.token,
    });
    const transformedResponse = this.transformGetResponse(response.data);
    return { entity: transformedResponse };
  };

  public transformGetResponse = (response: any): T => {
    return response;
  };

  public transformSaveEntity = (entity: T): any => {
    return entity;
  };

  public async save(entity: T, ids: EntityIdArray, data: any = {}): Promise<SaveEntityResponse> {
    if (ids.length < 1) {
      throw new Error('Id array was empty.');
    }

    const url = IsNewEntityId(ids[ids.length - 1]) ? this.generateBaseUrl(ids) : this.generateEntityUrl(ids);
    const response = await this._http.post(url, this.transformSaveEntity(entity), {
      data,
      cancelToken: this._cancelToken.token,
    });
    return response.data;
  }

  public delete = async (ids: EntityIdArray, params: any = {}): Promise<DeleteEntityResponse> => {
    const response = await this._http.delete(this.generateEntityUrl(ids), {
      params,
      cancelToken: this._cancelToken.token,
    });
    return response.data;
  };

  protected generateBaseUrl = (ids: EntityIdArray) => this.replaceIndexesWithValues(this._baseUri, ids);
  protected generateEntityUrl = (ids: EntityIdArray) => this.replaceIndexesWithValues(this._entityUri, ids);

  private replaceIndexesWithValues = (text: string, values: EntityIdArray): string => {
    let newText = text;
    for (let i = 0; i < values.length; i++) {
      newText = newText.replace(new RegExp(`\\{${i}\\}`, 'g'), String(values[i]));
    }
    return newText;
  };
}
