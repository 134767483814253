import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { SortDirection, SortInfoRecord } from './components/common/sortInfo';
import createHttp, { REQUEST_CANCELED } from './util/http';

export interface SearchPagingParams<TFilter = any> {
  filter?: TFilter;
  page: number;
  sortInfo: SortInfoRecord;
}

export abstract class ApiBase {
  private _cancelToken = axios.CancelToken.source();
  private _http: AxiosInstance;

  constructor(http: AxiosInstance = createHttp()) {
    this._http = http;
  }

  public cancel = () => {
    const currentToken = this._cancelToken;
    this._cancelToken = axios.CancelToken.source();
    currentToken.cancel(REQUEST_CANCELED);
  };

  protected get = <T = any>(url: string, queryParams: any = {}): Promise<T> => {
    return this._http
      .get(url, {
        params: queryParams,
        cancelToken: this._cancelToken.token,
      })
      .then((response) => response.data);
  };

  protected post = <T = any>(url: string, data: any): Promise<T> => {
    return this._http
      .post(url, data, {
        cancelToken: this._cancelToken.token,
      })
      .then((response) => response.data);
  };

  protected delete = <T = any>(url: string, params?: any): Promise<T> => {
    return this._http
      .delete(url, {
        params,
        cancelToken: this._cancelToken.token,
      })
      .then((response) => response.data);
  };

  protected send = (config: AxiosRequestConfig): Promise<AxiosResponse> => {
    config.cancelToken = this._cancelToken.token;
    return this._http.request(config);
  };

  protected saveEntity = <T extends { id: number }>(entity: T, baseUri: string): Promise<number> => {
    if (entity.id > 0) {
      return this.post(baseUri + `/` + entity.id, entity).then(() => entity.id);
    } else {
      return this.post(baseUri, entity).then((data) => data.id);
    }
  };

  protected getSearchPagingQueryParams = <TFilter = any>({ page, sortInfo, filter }: SearchPagingParams<TFilter>) => {
    const queryParams: any = Object.assign({}, filter, {
      p: page,
      s: sortInfo.get('column', ''),
      d: sortInfo.get('direction', SortDirection.Ascending),
    });

    if (queryParams.hasOwnProperty('search')) {
      queryParams.q = queryParams.search;
      delete queryParams.search;
    }
    return queryParams;
  };
}
