import * as React from 'react';

interface ContentRowProps {
  border?: boolean;
  noVPad?: boolean;
  bg?: boolean;
  darkBorder?: boolean;
  className?: string;
  noVMargin?: boolean;
  onClick?: () => void | null;
  responsive?: boolean;
}

interface ContentColProps {
  vAlign?: string;
  align?: string;
  size?: number;
  colClassName?: string;
}

type ContentCombinedProps = ContentRowProps & ContentColProps;

class ContentRow extends React.Component<ContentRowProps> {
  render() {
    const noVPad = this.props.noVPad ? ' noVPad' : '';
    const border = this.props.border ? ' border' : '';
    const bg = this.props.bg ? ' grey' : '';
    const darkBorder = this.props.darkBorder ? ' dark-border' : '';
    const className = this.props.className ? this.props.className : '';
    const noVMargin = this.props.noVMargin ? ' noVMargin' : '';
    const onClick = this.props.onClick ? this.props.onClick : undefined;
    const responsive = this.props.responsive === false ? '' : ' m-grid-responsive-sm';
    return (
      <div
        className={
          'm-grid m-grid-full-height content-row' +
          border +
          bg +
          noVPad +
          darkBorder +
          noVMargin +
          responsive +
          ' ' +
          className
        }
        onClick={onClick}
      >
        <div className="m-grid-row">{this.props.children}</div>
      </div>
    );
  }
}

class ContentCol extends React.Component<ContentColProps> {
  render() {
    const vAlign = this.props.vAlign ? this.props.vAlign : 'top';
    const align = this.props.align ? this.props.align : 'left';
    const size = this.props.size ? this.props.size.toString() : '12';
    const colClassName = this.props.colClassName ? this.props.colClassName : '';
    return (
      <div
        className={
          'm-grid-col m-grid-col-' +
          vAlign +
          ' m-grid-col-' +
          align +
          ' m-grid-col-sm-' +
          size +
          ' content-col ' +
          colClassName
        }
      >
        {this.props.children}
      </div>
    );
  }
}

class ContentFull extends React.Component<ContentCombinedProps> {
  render() {
    return (
      <ContentRow
        border={this.props.border}
        bg={this.props.bg}
        noVPad={this.props.noVPad}
        darkBorder={this.props.darkBorder}
        className={this.props.className}
        noVMargin={this.props.noVMargin}
        onClick={this.props.onClick}
      >
        <ContentCol
          size={this.props.size}
          align={this.props.align}
          vAlign={this.props.vAlign}
          colClassName={this.props.colClassName}
        >
          {this.props.children}
        </ContentCol>
      </ContentRow>
    );
  }
}

class Content extends React.Component<ContentCombinedProps> {
  static Row: typeof ContentRow;
  static Col: typeof ContentCol;
  static Full: typeof ContentFull;
}

Content.Row = ContentRow;
Content.Col = ContentCol;
Content.Full = ContentFull;

export default Content;
