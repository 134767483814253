import initHelpHero, { HelpHero } from 'helphero';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Config from '../Config';
import { useUserProfile } from '../global/UserCompanyContext';

declare global {
  interface Window {
    HelpHero?: HelpHero;
  }
}

//https://helphero.co/docs/javascript/
export function useHelpHero() {
  const navigate = useNavigate();
  const { companyId, id } = useUserProfile();
  const environmentType = Config.environment.toLowerCase();
  const helpHeroAppId = Config.helpHero;

  if (!window.HelpHero) {
    initHelpHero(helpHeroAppId);
    window.HelpHero!.setOptions({
      navigate: navigate,
    });
  }

  useEffect(() => {
    if (id !== '' && window.HelpHero !== undefined) {
      window.HelpHero.identify(id, {
        company: companyId,
        environment: environmentType,
      });
    }
    return () => {
      if (window.HelpHero && window.HelpHero.reset) {
        window.HelpHero.reset();
      }
    };
  }, [companyId, environmentType, id]);
}
