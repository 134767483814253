import { Switch } from '@headlessui/react';
import * as React from 'react';
import { useCallback } from 'react';
import { FieldErrorsAlert, getFieldErrors, HelpTip, mergeClasses } from './';
import { GenericInputProps } from './GenericInput';
import Toggle, { ToggleProps } from './Toggle';

export type CheckboxToggleProps = Omit<ToggleProps, 'onChange' | 'theme'> &
  Omit<GenericInputProps, 'required'> & {
    containerClassName?: string;
    onChange(name: string, checked: boolean): void;
    required?: boolean;
    disabled?: boolean;
  };

export default React.forwardRef(function CheckboxToggle(
  {
    labelClassName,
    containerClassName,
    label,
    id,
    name,
    checked,
    required,
    helptip,
    errors,
    onChange,
    disabled,
    ...props
  }: CheckboxToggleProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const errorList = getFieldErrors(errors, name);
  const hasError = errorList.length > 0;

  const handleChange = useCallback((checked: boolean) => onChange(name, checked), [name, onChange]);

  return (
    <div className={containerClassName}>
      <Switch.Group as="div" className="flex items-center">
        <Toggle
          {...props}
          theme={hasError ? 'error' : 'standard'}
          id={id ?? name}
          name={name}
          checked={checked === true}
          ref={ref}
          onChange={handleChange}
        />
        <Switch.Label htmlFor={id ?? name} className={mergeClasses('ml-2 block text-sm text-gray-900', labelClassName)}>
          {label}
          {required && <span className="text-primary">*</span>}
        </Switch.Label>
        {helptip === undefined ? null : (
          <span className={mergeClasses('ml-2 text-sm', labelClassName)}>
            <HelpTip>{helptip}</HelpTip>
          </span>
        )}
      </Switch.Group>
      {hasError ? <FieldErrorsAlert errors={errorList} /> : null}
    </div>
  );
});
