import { useCallback, useMemo } from 'react';
import { useUserProfile } from '../../../global/UserCompanyContext';
import { useApiClass, useConfirmDialog } from '../../../hooks';
import { UserMFAType } from '../enums/UserMFAType.enum';
import ProfileApi from '../profileApi';

function getMfaType(hasAuthenticatorApp2Fa: boolean, hasPhone2Fa: boolean): UserMFAType {
  if (hasAuthenticatorApp2Fa) {
    return UserMFAType.AUTHENTICATOR_APP;
  }
  if (hasPhone2Fa) {
    return UserMFAType.SMS;
  }

  return UserMFAType.None;
}

export default function useMfaForm() {
  const api = useApiClass(ProfileApi);

  const {
    phoneNumber,
    hasPhone2Fa,
    hasAuthenticatorApp2Fa,
    isMfaRequired,
    isAuthenticatorAppRequired,
    isSmsVerificationRequired,
    updateProfile,
  } = useUserProfile();

  const partiallyHiddenPhoneNumber: string = useMemo(() => {
    if (!phoneNumber) {
      return '';
    }
    const last4Digits = phoneNumber.slice(-4);
    const maskedNumber = last4Digits.padStart(phoneNumber.length, '*');
    return maskedNumber;
  }, [phoneNumber]);

  const handleRemoveMfa = useCallback(async () => {
    const response = await api.removeMfa();
    updateProfile(response);
  }, [api, updateProfile]);

  const dialogState = useConfirmDialog(handleRemoveMfa);

  const showAuthenticatorAppOption = useMemo(
    () => !isMfaRequired || (isMfaRequired && isAuthenticatorAppRequired),
    [isAuthenticatorAppRequired, isMfaRequired]
  );

  const showSmsVerificationOption = useMemo(
    () => !isMfaRequired || (isMfaRequired && isSmsVerificationRequired),
    [isSmsVerificationRequired, isMfaRequired]
  );

  return {
    partiallyHiddenPhoneNumber,
    mfaType: getMfaType(hasAuthenticatorApp2Fa, hasPhone2Fa),
    showAuthenticatorAppOption,
    showSmsVerificationOption,
    isMfaRequired,
    dialogState,
  };
}
