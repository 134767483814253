import { AxiosError } from 'axios';
import { Map } from 'immutable';
import * as React from 'react';
import { mapErrorsFromResponse } from '../components/common';
import { NamedAction, NamedActionPayload } from '../global/actions';

export type UseFormPageStateAndActions<TDataType> = UseFormPageStateActions<TDataType> & FormPageState<TDataType>;

interface UseFormPageStateActions<TDataType> {
  updateEntity(name: keyof TDataType, value: TDataType[keyof TDataType]): void;
  resetEntity(): void;
  saveEntity(e?: React.SyntheticEvent, close?: boolean): void;
}

export type FormPageReducerActions<TDataType> =
  | NamedActionPayload<'loadedEntity', { entity: TDataType }>
  | NamedAction<'blankEntity'>
  | NamedActionPayload<'updateEntity', { name: keyof TDataType; value: TDataType[keyof TDataType] }>
  | NamedActionPayload<'saveError', AxiosError>
  | NamedAction<'resetEntity'>
  | NamedAction<'saveEntity'>
  | NamedAction<'finishedSavingEntity'>
  | NamedAction<'clearEntity'>;

export interface FormPageState<T> {
  loading: boolean;
  saving: boolean;
  originalEntity: T;
  savedEntity: T;
  entity: T;
  errors: Map<string, string[]>;
}

export const getInitialFormPageState = <TDataType>(initialEntity: TDataType): FormPageState<TDataType> => {
  return {
    loading: true,
    saving: false,
    originalEntity: initialEntity,
    savedEntity: initialEntity,
    entity: initialEntity,
    errors: Map<string, string[]>()
  };
};

export function FormPageReducer<TDataType, TState extends FormPageState<TDataType>>(
  state: TState,
  action: FormPageReducerActions<TDataType>
): TState {
  switch (action.type) {
    case 'loadedEntity':
      return {
        ...state,
        entity: action.payload.entity,
        savedEntity: action.payload.entity,
        loading: false
      };
    case 'blankEntity':
      return {
        ...state,
        loading: false
      };
    case 'updateEntity':
      return {
        ...state,
        entity: { ...state.entity, [action.payload.name]: action.payload.value }
      };
    case 'saveEntity':
      return { ...state, savedEntity: state.entity, saving: true };
    case 'finishedSavingEntity':
      return { ...state, saving: false };
    case 'saveError':
      if (action.payload.response) {
        return { ...state, errors: mapErrorsFromResponse(action.payload.response.data), saving: false };
      } else {
        return { ...state, saving: false };
      }
    case 'resetEntity':
      return {
        ...state,
        entity: state.savedEntity
      };
    case 'clearEntity':
      return {
        ...state,
        savedEntity: state.originalEntity,
        entity: state.originalEntity
      };
    default:
      throw new Error();
  }
}
