import React from 'react';

export default function PowerIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="15.501" height="16.162" viewBox="0 0 15.501 16.162" {...props}>
      <path
        d="M172.494,237.544l-3.855-3.854a.552.552,0,0,0-.391-.162H157.708a.553.553,0,0,0-.553.553v15.056a.553.553,0,0,0,.553.553h14.4a.553.553,0,0,0,.553-.553v-11.2A.553.553,0,0,0,172.494,237.544Zm-10.912-2.911h6.135v3h-6.135ZM169,248.583h-8.008v-5.624H169Zm2.555,0H170.1v-6.177a.553.553,0,0,0-.553-.553h-9.114a.553.553,0,0,0-.553.553v6.177h-1.62v-13.95h2.215v3.557a.553.553,0,0,0,.553.553h7.241a.553.553,0,0,0,.553-.553v-2.754l2.728,2.727Z"
        transform="translate(-157.155 -233.527)"
        fill="currentColor"
      />
    </svg>
  );
}
