import React from 'react';

export default function PowerIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="16.412"
      height="17.884"
      viewBox="0 0 16.412 17.884"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            width="16.412"
            height="17.884"
            transform="translate(0 0)"
            fill="none"
            stroke="currentColor"
            strokeWidth="1"
          />
        </clipPath>
      </defs>
      <g clipPath="url(#clip-path)">
        <path
          d="M11.641,3.342A7.206,7.206,0,1,1,4.668,3.4"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
        <line
          y1="9.771"
          transform="translate(8.203 1)"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeWidth="2"
        />
      </g>
    </svg>
  );
}
