import { PropsWithChildren, useLayoutEffect, useRef, useState } from 'react';
import { mergeClasses, WithClassName } from '.';

const DefaultOverflowHeight = 150;

export type PartialDisclosureProps = PropsWithChildren<{}> &
  WithClassName<{}> & {
    overflowHeight?: number;
  };

export default function PartialDisclosure({ children, className, overflowHeight }: PartialDisclosureProps) {
  const height = overflowHeight ?? DefaultOverflowHeight;
  const measured = useRef<boolean>(false);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [overflow, setOverflow] = useState(false);
  const [isExtended, setIsExtended] = useState(false);

  const onClickButton = () => {
    setIsExtended(!isExtended);
  };

  // Trigger remeasure when the desired height or children change
  useLayoutEffect(() => {
    if (measured.current) {
      setOverflow(false);
      measured.current = false;
    }
  }, [children, height]);

  // Measure the content to see if we need to add the "Show More" button
  useLayoutEffect(() => {
    if (!measured.current && containerRef.current !== null) {
      setOverflow(containerRef.current?.clientHeight > height);
      measured.current = true;
    }
  }, [children, height, overflow]);

  return overflow ? (
    <div className="flex flex-col">
      <div className={className + 'h-auto'}>
        <div className={mergeClasses(isExtended ? 'max-h-fit' : 'max-h-60', 'overflow-x-hidden overflow-y-hidden')}>
          {children}
        </div>

        <button className="py-2 text-primary" type="button" onClick={onClickButton}>
          {isExtended ? '...Show less' : 'View more...'}
        </button>
      </div>
    </div>
  ) : (
    <div className={className} ref={containerRef}>
      {children}
    </div>
  );
}
