import * as React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { NotificationsProvider } from '../global/Notifications';
import { UserCompanyProvider } from '../global/UserCompanyContext';
import { IndexStorageProvider } from '../hooks/useIndexStorage';
import AppError from './AppError';
import AppRoutes from './AppRoutes';
import { ErrorBoundary } from './common';
import NotificationList from './NotificationList';

const queryClient = new QueryClient();

export default function App() {
  return (
    <React.StrictMode>
      <ErrorBoundary FallbackComponent={AppError}>
        <BrowserRouter>
          <HelmetProvider>
            <NotificationsProvider>
              <IndexStorageProvider>
                <QueryClientProvider client={queryClient}>
                  <UserCompanyProvider>
                    <AppRoutes />
                    <NotificationList />
                  </UserCompanyProvider>
                </QueryClientProvider>
              </IndexStorageProvider>
            </NotificationsProvider>
          </HelmetProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>
  );
}
