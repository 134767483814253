import * as React from 'react';
import Cancelable, { CancelableProps, CancelableRenderProps } from './Cancelable';
import { Omit } from 'lodash';

export interface EditToggleProps<T = any> extends Omit<CancelableProps<T>, 'children'> {
  children: (props: EditToggleRenderProps<T>) => React.ReactNode;
}

export interface EditToggleRenderProps<T = any> extends CancelableRenderProps<T> {
  editing: boolean;
  startEdit(): void;
}

interface State {
  editing: boolean;
}

export default class EditToggle<T = any> extends React.Component<EditToggleProps<T>, State> {
  constructor(props: EditToggleProps<T>) {
    super(props);
    this.state = {
      editing: false
    };
  }

  render() {
    if (typeof this.props.children !== 'function') {
      throw Error("'children' should be a function");
    }

    return (
      <Cancelable<T> {...this.props}>
        {({ save, cancel, ...cancelProps }) =>
          this.props.children({
            ...cancelProps,
            ...this.state,
            save: this.toggleProxy(save),
            cancel: this.toggleProxy(cancel),
            startEdit: this.handleStartEdit
          })
        }
      </Cancelable>
    );
  }

  private handleStartEdit = () => {
    this.setState({ editing: true });
  };

  private handleStopEdit = () => {
    this.setState({ editing: false });
  };

  private toggleProxy = (func: () => void | Promise<void>) => {
    return () => {
      const result = func();
      if (this.isPromise(result)) {
        result.then((ret) => {
          this.handleStopEdit();
          return ret;
        });
      } else {
        this.handleStopEdit();
      }
    };
  };

  private isPromise(promise: void | Promise<void>): promise is Promise<void> {
    return !!promise && typeof promise.then === 'function';
  }
}
