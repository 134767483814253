const NetSPIProvidedPlaybookIcon = (props: React.SVGAttributes<SVGElement>): React.ReactElement => {
  return (
    <svg
      id="a"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 127.36 127.36"
      style={{ maxWidth: 20, marginLeft: 3 }}
      {...props}
    >
      <path
        d="M0,0h34.16v21.93l68.67,46.18v-33.95h-9.62V0h34.15v34.16h-10.27v47.34c0,2.64-1.45,5.06-3.78,6.29-1.05.56-2.2.84-3.35.84-1.39,0-2.78-.41-3.98-1.21L26.77,34.16H0V0Z"
        fill="#cbd5e0"
        strokeWidth="0"
      />
      <path
        d="M0,93.2h10.27v-47.34c0-2.64,1.45-5.06,3.78-6.3,2.33-1.24,5.15-1.09,7.33.38l79.2,53.26h26.77v34.15h-34.15v-21.93L24.54,59.25v33.95h9.62v34.15H0v-34.15Z"
        fill="#cbd5e0"
        strokeWidth="0"
      />
    </svg>
  );
};

export default NetSPIProvidedPlaybookIcon;
