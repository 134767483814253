import { useCallback, useMemo } from 'react';
import { Path, useLocation, useNavigate } from 'react-router-dom';

export default function useClearRouterState() {
  const { pathname, search, hash } = useLocation();
  const path: Path = useMemo(() => ({ pathname, search, hash }), [hash, pathname, search]);
  const navigate = useNavigate();
  return useCallback(
    (replace: boolean = true) => {
      navigate(path, { replace: replace });
    },
    [navigate, path]
  );
}
