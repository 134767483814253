import { SearchIcon, XIcon } from '@heroicons/react/solid';
import * as React from 'react';
import { mergeClasses } from '.';
import Input from './Input';

const inputSetter = getInputSetter();

export type SearchFilterInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  containerClassName?: string;
  showClearButton?: boolean;
  children?: React.ReactNode;
  showBottomBorder?: boolean;
  clearButtonClassName?: string;
  searchIconClassName?: string;
  placeholderClassName?: string;
};

const SearchFilterInput = React.forwardRef(
  (
    {
      id,
      type,
      value,
      name,
      className,
      clearButtonClassName,
      containerClassName,
      searchIconClassName,
      placeholderClassName,
      placeholder,
      showClearButton,
      showBottomBorder = true,
      children,
      ...props
    }: SearchFilterInputProps,
    ref: React.ForwardedRef<HTMLInputElement>
  ) => {
    const inputRef = React.useRef<HTMLInputElement | null>(null);
    const [hideClearButton, setHideClearButton] = React.useState(showClearButton);

    function updateRefs(elem: HTMLInputElement | null): void {
      inputRef.current = elem;

      setHideClearButton(
        inputRef.current?.value === undefined || inputRef.current?.value === '' || showClearButton === false
          ? true
          : false
      );

      if (ref) {
        if (typeof ref === 'function') {
          ref(elem);
        } else {
          ref.current = elem;
        }
      }
    }

    function handleClear() {
      if (inputSetter) {
        inputSetter.call(inputRef.current, '');
      } else {
        if (inputRef.current) {
          inputRef.current.value = '';
        }
      }
      const event: Event & { simulated?: boolean } = new Event('input', {
        bubbles: true,
      });
      if (inputRef.current) {
        inputRef.current.dispatchEvent(event);
        inputRef.current.focus();
      }
    }

    return (
      <div
        className={mergeClasses(
          showBottomBorder ? 'border-b' : '',
          'relative flex w-full items-center rounded-t border-gray-400 bg-white shadow-none drop-shadow-none',
          containerClassName ? containerClassName : ''
        )}
      >
        <div
          className={mergeClasses(
            'pointer-events-none absolute inset-y-0 left-0 flex items-center pl-5',
            searchIconClassName ? searchIconClassName : ''
          )}
        >
          <SearchIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
        </div>
        <Input
          {...props}
          type={type ?? 'search'}
          name={name}
          value={value ?? ''}
          placeholder={placeholder ?? 'Search...'}
          theme={'standard'}
          ref={updateRefs}
          className={mergeClasses(
            'input-decoration-none my-1 ml-1 -mr-1 flex-1 border-transparent bg-transparent pl-11 pr-8 shadow-none placeholder:text-gray-500',
            placeholderClassName,
            className
          )}
          autoComplete="off"
        />

        {hideClearButton === true ? null : (
          <div className={className ?? 'relative flex'}>
            <button
              title="clear search"
              type="button"
              onClick={handleClear}
              className={mergeClasses(
                'item-center relative -inset-x-6 -mr-7 pt-0.5 focus:outline-none focus:ring-2 focus:ring-primary rounded ring-inset',
                clearButtonClassName
              )}
            >
              <XIcon className="h-5 w-5 flex-1 text-gray-500" aria-hidden="true" />
            </button>
          </div>
        )}

        {/* Filters */}
        {children}
      </div>
    );
  }
);

function getInputSetter() {
  var checkObject = Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value');
  return checkObject && checkObject.set ? checkObject.set : null;
}

export default SearchFilterInput;
