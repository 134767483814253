import { CalendarIcon } from '@heroicons/react/outline';
import * as React from 'react';
import Flatpickr from 'react-flatpickr';
import { ErrorMapType, FieldErrorsAlert, getFieldErrors } from '.';
import './DatePicker.css';

export type DateInputProps = {
  onChange(name: string, value: string): void;
  value: string;
  label?: string;
  name: string;
  required?: boolean;
  errors: ErrorMapType;
};

export default React.forwardRef(function DateInput(props: DateInputProps, ref: React.ForwardedRef<HTMLInputElement>) {
  const errorList = getFieldErrors(props.errors, props.name);
  const hasError = errorList.length > 0;

  return (
    <>
      <div>
        {props.label === undefined || props.label === '' ? null : (
          <label htmlFor={props.name} className="mb-1 block text-sm font-medium text-gray-700">
            {props.label}:{props.required === true ? <span className="text-primary">*</span> : null}
          </label>
        )}

        <div className="flex">
          <Flatpickr
            name={props.name}
            value={props.value}
            placeholder="Select Date"
            className="rounded-md border-gray-400 text-sm font-normal max-w-[100%] ring-transparent focus:border-primary focus:ring-primary"
            onOpen={(e, value, instance) => {
              instance.calendarContainer.addEventListener('mousedown', CalendarClickHandler);
            }}
            onClose={(e, value, instance) => {
              instance.calendarContainer.removeEventListener('mousedown', CalendarClickHandler);
            }}
            options={{
              dateFormat: 'M d, Y',
              parseDate: (d) => transformToLocal(new Date(d)),
            }}
            onChange={(e, value, instance) => {
              props.onChange(props.name, e[0].toISOString());
            }}
          />
          <div className="item-center relative -inset-x-7 inset-y-1.5 mt-1 w-0">
            <CalendarIcon className="h-5 w-5 text-gray-500" aria-hidden="true" />
          </div>
          {hasError ? <FieldErrorsAlert errors={errorList} /> : null}
        </div>
      </div>
    </>
  );
});

function CalendarClickHandler(e: MouseEvent) {
  e.stopPropagation();
}

function transformToLocal(date: Date): Date {
  return new Date(
    date.getUTCFullYear(),
    date.getUTCMonth(),
    date.getUTCDate(),
    date.getUTCHours(),
    date.getUTCMinutes(),
    date.getUTCSeconds(),
    date.getUTCMilliseconds()
  );
}
