import React from 'react';
import { Path, useLocation, useNavigate } from 'react-router-dom';
import { NavigateParams } from '../components/common';
import { EntityApiBase, EntityApiClass, EntityIdArray, IsNewEntityId, SaveEntityResponse } from '../entityApiBase';
import { FormPageReducer, FormPageReducerActions, FormPageState, getInitialFormPageState } from './useFormPageHelpers';

export interface Options<TDataType> {
  saveAndCloseLocationCallback?: () => NavigateParams;
  saveLocationCallback?: (id: any) => Path;
  preSaveCallback?: () => void;
  postSaveCallback?: (entity: TDataType, keepOpen: boolean) => void;
  params?: any;
  data?: any;
  preventEntityRetrieval?: boolean;
}

export default function useEntityFormPageState<TDataType, TApi extends EntityApiBase<TDataType>>(
  apiClass: EntityApiClass<TDataType, TApi>,
  initialEntity: TDataType,
  ids: EntityIdArray,
  options: Options<TDataType> = {}
) {
  const {
    saveAndCloseLocationCallback,
    saveLocationCallback,
    preSaveCallback,
    postSaveCallback,
    params,
    data,
    preventEntityRetrieval,
  } = options;

  const navigate = useNavigate();
  const appLocation = useLocation();
  const [state, dispatch] = React.useReducer((s: FormPageState<TDataType>, a: FormPageReducerActions<TDataType>) => {
    let newState = FormPageReducer<TDataType, FormPageState<TDataType>>(s, a);
    return newState;
  }, getInitialFormPageState(initialEntity));
  const entityApi = React.useRef(new apiClass());

  React.useEffect(() => {
    const api = entityApi.current;
    if (IsNewEntityId(ids[ids.length - 1]) || preventEntityRetrieval) {
      dispatch({ type: 'blankEntity' });
    } else {
      api.get(ids, params).then((response) => dispatch({ type: 'loadedEntity', payload: response }));
    }
    return () => api.cancel();
  }, [ids, params, preventEntityRetrieval]);

  const updateEntity = (name: keyof TDataType, value: TDataType[keyof TDataType]) => {
    dispatch({ type: 'updateEntity', payload: { name, value } });
  };

  const resetEntity = () => {
    dispatch({ type: 'resetEntity' });
  };

  const clearEntity = () => {
    dispatch({ type: 'clearEntity' });
  };

  const saveEntity = React.useCallback(
    (e?: React.SyntheticEvent, keepOpen: boolean = false) => {
      if (e) {
        e.preventDefault();
      }

      dispatch({ type: 'saveEntity' });

      if (typeof preSaveCallback !== 'undefined') {
        preSaveCallback();
      }

      const api = entityApi.current;
      return api
        .save(state.entity, ids, data)
        .then((response: SaveEntityResponse) => {
          dispatch({ type: 'finishedSavingEntity' });

          if (typeof postSaveCallback !== 'undefined') {
            postSaveCallback({ ...state.entity, id: response.id }, keepOpen);
          }

          if (keepOpen) {
            if (typeof response.id !== 'undefined' && typeof saveLocationCallback !== 'undefined') {
              const location = saveLocationCallback(response.id);
              if (appLocation.pathname !== location.pathname) {
                navigate(location);
              }
            }
          } else {
            if (typeof saveAndCloseLocationCallback !== 'undefined') {
              const loc = saveAndCloseLocationCallback();
              navigate(loc.to);
            }
          }
        })
        .catch((error) => {
          dispatch({ type: 'saveError', payload: error });
        });
    },
    [
      appLocation.pathname,
      data,
      ids,
      navigate,
      postSaveCallback,
      preSaveCallback,
      saveAndCloseLocationCallback,
      saveLocationCallback,
      state.entity,
    ]
  );

  return {
    state,
    actions: {
      updateEntity,
      resetEntity,
      saveEntity,
      clearEntity,
    },
  };
}
