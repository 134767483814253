export const ADD_ROW = 'Add Row';
export const CANCEL = 'Cancel';
export const DELETE = 'Delete';
export const EDIT = 'Edit';
export const EMPTY_PLACEHOLDER_NA = 'N/A';
export const LCPROCEDURE = 'procedure';
export const LCPROCEDURES = 'procedures';
export const PROCEDURE = 'Procedure';
export const PROCEDURES = 'Procedures';
export const POST = 'Post';
export const SAVE = 'Save';
export const SHOW = 'Show';
export const VIEW = 'View';
export const L = 'L';
export const D = 'D';
export const A = 'A';
export const R = 'R';
export const P = 'P';
export const COLLAPSE_ALL = 'Collapse All';
export const EXPAND_ALL = 'Expand All';
export const EXPORT_AS_CSV = 'Export as CSV';
export const EXPORT_AS_JSON = 'Export as JSON';
export const EXPORT_AS_PDF = 'Export as PDF';
export const NO_RESULTS_FOUND = 'No results found';
