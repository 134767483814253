import HighchartsInstance, { SVGPathArray, SVGRenderer } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import Accessibility from 'highcharts/modules/accessibility';
import Exporting from 'highcharts/modules/exporting';
import NoData from 'highcharts/modules/no-data-to-display';
import OfflineExporting from 'highcharts/modules/offline-exporting';
import solidGauge from 'highcharts/modules/solid-gauge';
import React from 'react';
import Treemap from 'highcharts/modules/treemap';

HighchartsMore(HighchartsInstance);
solidGauge(HighchartsInstance);
Exporting(HighchartsInstance);
OfflineExporting(HighchartsInstance);
Accessibility(HighchartsInstance);
NoData(HighchartsInstance);
CustomSymbols(HighchartsInstance);
Treemap(HighchartsInstance);

HighchartsInstance.theme = {
  title: {
    align: 'left',
    style: {
      fontFamily: 'Open Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
      fontSize: '1.0rem',
      fontWeight: '600',
      color: '#2D3748',
    },
  },
  colors: ['#1A202C', '#CE112D', '#A0AEC0'],
  credits: {
    enabled: false,
  },
  responsive: {
    rules: [
      {
        condition: {
          minWidth: 1200,
        },
        chartOptions: {
          title: {
            style: {
              fontSize: '1.2rem',
            },
          },
          xAxis: {
            labels: {
              style: {
                fontSize: '0.7rem',
                textOverflow: 'none',
              },
            },
          },
          yAxis: {
            title: {
              style: {
                fontSize: '1rem',
              },
            },
            labels: {
              style: {
                fontSize: '0.7rem',
              },
            },
          },
        },
      },
    ],
  },
};

HighchartsInstance.setOptions(HighchartsInstance.theme);

const Chart = React.forwardRef(
  (props: Omit<HighchartsReact.Props, 'highcharts'>, ref: React.ForwardedRef<HighchartsReact.RefObject>) => {
    return <HighchartsReact {...props} highcharts={HighchartsInstance} ref={ref} />;
  }
);

function CustomSymbols(H: typeof HighchartsInstance) {
  H.setOptions({
    lang: {
      lineChartButton: 'Line Chart',
      verticalBarChartButton: 'Vertical Bar Chart',
      horizontalStackedBarChartButton: 'Horizontal Stacked Bar Chart',
      radarChartButton: 'Radar Chart',
    } as any,
    exporting: {
      sourceHeight: 600,
      sourceWidth: 1200,
      printMaxWidth: 1350,
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'printChart', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadSVG'],
        },
      },
    },
  });
  registerSymbols(SVGRenderer);
}

function registerSymbols(SVGRendererClass: typeof SVGRenderer) {
  const symbols = SVGRendererClass.prototype.symbols;
  symbols.chart_line = getLineChartSymbolPath;
  symbols.chart_stacked_bar = getStackedBarChartSymbolPath;
  symbols.chart_bar = getVerticalBarChartSymbolPath;
  symbols.chart_radar = getRadarChartSymbolPath;
}

function getRadarChartSymbolPath(x: number, y: number, width: number, height: number): SVGPathArray {
  return [
    ['M', x + width * 0.7839, y + height * 0.9553],
    ['h', width * -0.5675],
    ['a', width * 0.041, height * 0.041, 0.0, 0.0, 1.0, width * -0.0391, height * -0.0283],
    ['l', width * -0.1753, height * -0.5397],
    ['a', width * 0.041, height * 0.041, 0.0, 0.0, 1.0, width * 0.015, height * -0.0459],
    ['l', width * 0.4589, height * -0.3335],
    ['a', width * 0.0411, height * 0.0411, 0.0, 0.0, 1.0, width * 0.0483, height * 0.0],
    ['l', width * 0.4591, height * 0.3336],
    ['a', width * 0.041, height * 0.041, 0.0, 0.0, 1.0, width * 0.015, height * 0.0459],
    ['l', width * -0.1754, height * 0.5396],
    ['a', width * 0.041, height * 0.041, 0.0, 0.0, 1.0, width * -0.0391, height * 0.0283],
    ['m', width * -0.5376, height * -0.0821],
    ['h', width * 0.5078],
    ['l', width * 0.1569, height * -0.4829],
    ['l', width * -0.4109, height * -0.2984],
    ['l', width * -0.4106, height * 0.2984],
    ['z'],
    ['m', width * 0.2071, height * -0.3327],
    ['a', width * 0.05, height * 0.05, 0.0, 1.0, 1.0, width * -0.05, height * -0.05],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 1.0, width * 0.05, height * 0.05],
    ['m', width * 0.1931, height * 0.0965],
    ['a', width * 0.05, height * 0.05, 0.0, 1.0, 1.0, width * -0.05, height * -0.05],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 1.0, width * 0.05, height * 0.05],
    ['m', width * 0.0413, height * -0.2414],
    ['a', width * 0.05, height * 0.05, 0.0, 1.0, 1.0, width * -0.05, height * -0.05],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 1.0, width * 0.05, height * 0.05],
    ['m', width * -0.1793, height * -0.1517],
    ['a', width * 0.05, height * 0.05, 0.0, 1.0, 1.0, width * -0.05, height * -0.05],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 1.0, width * 0.05, height * 0.05],
    ['m', width * -0.1861, height * 0.1517],
    ['a', width * 0.05, height * 0.05, 0.0, 1.0, 1.0, width * -0.05, height * -0.05],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 1.0, width * 0.05, height * 0.05],
    ['m', width * 0.3023, height * 0.2967],
    ['a', width * 0.0204, height * 0.0204, 0.0, 0.0, 1.0, width * -0.0092, height * -0.0022],
    ['l', width * -0.2114, height * -0.1058],
    ['a', width * 0.0205, height * 0.0205, 0.0, 0.0, 1.0, width * -0.006, height * -0.0046],
    ['l', width * -0.1435, height * -0.1586],
    ['a', width * 0.0204, height * 0.0204, 0.0, 0.0, 1.0, width * 0.0022, height * -0.0297],
    ['l', width * 0.2039, height * -0.1661],
    ['a', width * 0.0204, height * 0.0204, 0.0, 0.0, 1.0, width * 0.0263, height * 0.0],
    ['l', width * 0.1963, height * 0.1662],
    ['a', width * 0.0204, height * 0.0204, 0.0, 0.0, 1.0, width * 0.007, height * 0.0191],
    ['l', width * -0.0452, height * 0.2646],
    ['a', width * 0.0204, height * 0.0204, 0.0, 0.0, 1.0, width * -0.0109, height * 0.015],
    ['a', width * 0.0206, height * 0.0206, 0.0, 0.0, 1.0, width * -0.0093, height * 0.0022],
    ['m', width * -0.199, height * -0.1431],
    ['l', width * 0.1832, height * 0.0916],
    ['l', width * 0.0387, height * -0.2255],
    ['l', width * -0.1743, height * -0.1476],
    ['l', width * -0.174, height * 0.1419],
    ['z'],
    ['m', width * -0.2269, height * 0.3243],
    ['a', width * 0.0133, height * 0.0133, 0.0, 0.0, 1.0, width * -0.0075, height * -0.0022],
    ['a', width * 0.0136, height * 0.0136, 0.0, 0.0, 1.0, width * -0.004, height * -0.0189],
    ['l', width * 0.1802, height * -0.2771],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * 0.023, height * 0.015],
    ['l', width * -0.1806, height * 0.2769],
    ['a', width * 0.0135, height * 0.0135, 0.0, 0.0, 1.0, width * -0.0115, height * 0.0063],
    ['m', width * 0.2424, height * -0.3745],
    ['a', width * 0.0133, height * 0.0133, 0.0, 0.0, 1.0, width * -0.0075, height * -0.0022],
    ['a', width * 0.0136, height * 0.0136, 0.0, 0.0, 1.0, width * -0.004, height * -0.0189],
    ['l', width * 0.0184, height * -0.0281],
    ['l', width * -0.1223, height * -0.0279],
    ['a', width * 0.0137, height * 0.0137, 0.0, 1.0, 1.0, width * 0.006, height * -0.0267],
    ['l', width * 0.1415, height * 0.032],
    ['a', width * 0.0139, height * 0.0139, 0.0, 0.0, 1.0, width * 0.0097, height * 0.0082],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * -0.0012, height * 0.0126],
    ['l', width * -0.0292, height * 0.045],
    ['a', width * 0.0135, height * 0.0135, 0.0, 0.0, 1.0, width * -0.0115, height * 0.0063],
    ['m', width * -0.2188, height * -0.1011],
    ['a', width * 0.0141, height * 0.0141, 0.0, 0.0, 1.0, width * -0.003, height * -0.0003],
    ['l', width * -0.1835, height * -0.0415],
    ['a', width * 0.0137, height * 0.0137, 0.0, 1.0, 1.0, width * 0.006, height * -0.0266],
    ['l', width * 0.1833, height * 0.0413],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * -0.003, height * 0.0271],
    ['m', width * 0.2364, height * -0.1878],
    ['h', width * 0.0],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * -0.0137, height * -0.0137],
    ['l', width * 0.0003, height * -0.1519],
    ['a', width * 0.0138, height * 0.0138, 0.0, 0.0, 1.0, width * 0.0138, height * -0.0138],
    ['h', width * 0.0],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * 0.0137, height * 0.0138],
    ['l', width * -0.0003, height * 0.1519],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * -0.0137, height * 0.0137],
    ['m', width * -0.0006, height * 0.2534],
    ['a', width * 0.0138, height * 0.0138, 0.0, 0.0, 1.0, width * -0.0137, height * -0.0137],
    ['l', width * 0.0003, height * -0.1419],
    ['a', width * 0.0138, height * 0.0138, 0.0, 0.0, 1.0, width * 0.0137, height * -0.0137],
    ['h', width * 0.0],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * 0.0137, height * 0.0138],
    ['l', width * -0.0003, height * 0.1247],
    ['l', width * 0.1113, height * -0.025],
    ['a', width * 0.0136, height * 0.0136, 0.0, 0.0, 1.0, width * 0.0163, height * 0.0103],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * -0.0103, height * 0.0163],
    ['l', width * -0.1281, height * 0.0289],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * -0.003, height * 0.0003],
    ['m', width * 0.2372, height * -0.0622],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * -0.003, height * -0.0271],
    ['l', width * 0.1994, height * -0.045],
    ['a', width * 0.0137, height * 0.0137, 0.0, 1.0, 1.0, width * 0.006, height * 0.0267],
    ['l', width * -0.1994, height * 0.045],
    ['a', width * 0.0141, height * 0.0141, 0.0, 0.0, 1.0, width * -0.003, height * 0.0003],
    ['m', width * -0.1469, height * 0.1883],
    ['a', width * 0.0135, height * 0.0135, 0.0, 0.0, 1.0, width * -0.0115, height * -0.0063],
    ['l', width * -0.09, height * -0.1386],
    ['a', width * 0.0137, height * 0.0137, 0.0, 1.0, 1.0, width * 0.023, height * -0.015],
    ['l', width * 0.09, height * 0.1386],
    ['a', width * 0.0136, height * 0.0136, 0.0, 0.0, 1.0, width * -0.004, height * 0.0189],
    ['a', width * 0.0133, height * 0.0133, 0.0, 0.0, 1.0, width * -0.0075, height * 0.0022],
    ['m', width * 0.1782, height * 0.2739],
    ['a', width * 0.0135, height * 0.0135, 0.0, 0.0, 1.0, width * -0.0115, height * -0.0063],
    ['l', width * -0.1077, height * -0.1655],
    ['a', width * 0.0137, height * 0.0137, 0.0, 0.0, 1.0, width * 0.023, height * -0.015],
    ['l', width * 0.1077, height * 0.1656],
    ['a', width * 0.0136, height * 0.0136, 0.0, 0.0, 1.0, width * -0.004, height * 0.0189],
    ['a', width * 0.0133, height * 0.0133, 0.0, 0.0, 1.0, width * -0.0075, height * 0.0022],
  ];
}

function getStackedBarChartSymbolPath(x: number, y: number, width: number, height: number): SVGPathArray {
  return [
    ['M', x + width * 0.0, y + height * 0.25],
    ['v', height * -0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * -0.15],
    ['h', width * 0.3],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * 0.15],
    ['v', height * 0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * 0.15],
    ['h', width * -0.3],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * -0.15],
    ['m', width * 0.5, height * -0.1],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * -0.05],
    ['h', width * -0.3],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * 0.05],
    ['v', height * 0.1],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * 0.05],
    ['h', width * 0.3],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * -0.05],
    ['z'],
    ['m', width * -0.5, height * 0.4],
    ['v', height * -0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * -0.15],
    ['h', width * 0.5],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * 0.15],
    ['v', height * 0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * 0.15],
    ['h', width * -0.5],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * -0.15],
    ['m', width * 0.7, height * -0.1],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * -0.05],
    ['h', width * -0.5],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * 0.05],
    ['v', height * 0.1],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * 0.05],
    ['h', width * 0.5],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * -0.05],
    ['z'],
    ['m', width * -0.7, height * 0.4],
    ['v', height * -0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * -0.15],
    ['h', width * 0.7],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * 0.15],
    ['v', height * 0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * 0.15],
    ['h', width * -0.7],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * -0.15],
    ['m', width * 0.9, height * -0.1],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * -0.05],
    ['h', width * -0.7],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * 0.05],
    ['v', height * 0.1],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * 0.05],
    ['h', width * 0.7],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * -0.05],
    ['z'],
  ];
}

function getVerticalBarChartSymbolPath(x: number, y: number, width: number, height: number): SVGPathArray {
  return [
    ['M', x + width * 0.25, y + height * 1.0],
    ['h', width * -0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * -0.15],
    ['v', height * -0.3],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * -0.15],
    ['h', width * 0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * 0.15],
    ['v', height * 0.3],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * 0.15],
    ['m', width * -0.1, height * -0.5],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * 0.05],
    ['v', height * 0.3],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * 0.05],
    ['h', width * 0.1],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * -0.05],
    ['v', height * -0.3],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * -0.05],
    ['z'],
    ['M', x + width * 0.55, y + height * 1.0],
    ['h', width * -0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * -0.15],
    ['v', height * -0.5],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * -0.15],
    ['h', width * 0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * 0.15],
    ['v', height * 0.5],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * 0.15],
    ['m', width * -0.1, height * -0.7],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * 0.05],
    ['v', height * 0.5],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * 0.05],
    ['h', width * 0.1],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * -0.05],
    ['v', height * -0.5],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * -0.05],
    ['z'],
    ['M', x + width * 0.85, y + height * 1.0],
    ['h', width * -0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * -0.15],
    ['v', height * -0.7],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * -0.15],
    ['h', width * 0.1],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * 0.15, height * 0.15],
    ['v', height * 0.7],
    ['a', width * 0.15, height * 0.15, 0.0, 0.0, 1.0, width * -0.15, height * 0.15],
    ['m', width * -0.1, height * -0.9],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * 0.05],
    ['v', height * 0.7],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * 0.05],
    ['h', width * 0.1],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * 0.05, height * -0.05],
    ['v', height * -0.7],
    ['a', width * 0.05, height * 0.05, 0.0, 0.0, 0.0, width * -0.05, height * -0.05],
    ['z'],
  ];
}

function getLineChartSymbolPath(x: number, y: number, width: number, height: number): SVGPathArray {
  y -= 3;
  height -= 2;
  return [
    ['M', x + width * 0.05, y + height * 0.4 + height * 1.0],
    ['a', width * 0.05, height * 0.0833, 0.0, 0.0, 1.0, width * -0.0353, height * -0.1423],
    ['l', width * 0.3, height * -0.5],
    ['a', width * 0.05, height * 0.0833, 0.0, 0.0, 1.0, width * 0.0707, height * 0.0],
    ['l', width * 0.1647, height * 0.2744],
    ['l', width * 0.2793, height * -0.4655],
    ['h', width * -0.2793],
    ['a', width * 0.05, height * 0.0833, 0.0, 0.0, 1.0, width * 0.0, height * -0.1667],
    ['h', width * 0.4],
    ['a', width * 0.0494, height * 0.0823, 0.0, 0.0, 1.0, width * 0.0189, height * 0.0062],
    ['a', width * 0.0503, height * 0.0839, 0.0, 0.0, 1.0, width * 0.0272, height * 0.0453],
    ['a', width * 0.0494, height * 0.0823, 0.0, 0.0, 1.0, width * 0.0039, height * 0.0318],
    ['v', height * 0.6667],
    ['a', width * 0.05, height * 0.0833, 0.0, 0.0, 1.0, width * -0.1, height * 0.0],
    ['v', height * -0.4655],
    ['l', width * -0.3146, height * 0.5244],
    ['a', width * 0.05, height * 0.0833, 0.0, 0.0, 1.0, width * -0.0707, height * 0.0],
    ['l', width * -0.1647, height * -0.2744],
    ['l', width * -0.2646, height * 0.4411],
    ['a', width * 0.05, height * 0.0833, 0.0, 0.0, 1.0, width * -0.0353, height * 0.0244],
  ];
}

export const Highcharts = HighchartsInstance;
export default Chart;
