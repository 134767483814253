import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon, XIcon } from '@heroicons/react/outline';
import * as React from 'react';
import { SelectOption } from '.';

export type SearchFilterDropdownProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> & {
  containerClassName?: string;
  options: SelectOption[];
  label: string;
  name: string;
  onChange(name: string, value: string | number): void;
};

const SearchFilterDropdown = ({ id, type, label, value, name, className, ...props }: SearchFilterDropdownProps) => {
  const hasFilterOptionSelected = value === undefined || value === '';

  const selectedOption = props.options.find((option) => option.value === value);
  const displayName = hasFilterOptionSelected ? label : selectedOption?.text;

  const conditionalClass = hasFilterOptionSelected ? 'pl-2' : 'pl-2 text-primary font-semibold';

  function handleOnChange(value: string) {
    props.onChange(name, value);
  }

  function handleClear() {
    props.onChange(name, '');
  }

  return (
    <div className="flex pr-0 pt-2 text-base text-gray-800">
      <Listbox value={value} onChange={handleOnChange}>
        <Listbox.Button>
          <Listbox.Label className="-mt-3 flex cursor-pointer">
            <div className={conditionalClass}>{displayName ?? ''}</div>
          </Listbox.Label>
        </Listbox.Button>
        <div className={className ?? 'relative flex'}>
          {hasFilterOptionSelected === true ? (
            <Listbox.Button>
              <ChevronDownIcon className="-mt-3.5 h-5 w-5 pt-2 pr-1 text-gray-800" aria-hidden="true" />
            </Listbox.Button>
          ) : (
            <button title="clear search" type="button" onClick={handleClear} className="item-center -mt-2 px-1">
              <XIcon className="h-4 w-4 flex-1 text-gray-500 " aria-hidden="true" />
            </button>
          )}
        </div>

        <Transition
          as={React.Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Listbox.Options
            static
            className="-px-1 w-38 absolute z-50 mt-9 max-h-60 overflow-auto rounded bg-white text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {props.options.map((option) => (
              <Listbox.Option
                key={option.value}
                className={({ active }) =>
                  `${active ? 'bg-primary text-white' : 'text-gray-800'}
                          relative cursor-default select-none py-2 pl-8 pr-4`
                }
                value={option.value}
              >
                {({ selected, active }) => (
                  <>
                    <span className={`${selected ? 'font-medium' : 'font-normal'} block truncate`}>{option.text}</span>
                    {selected ? (
                      <span
                        className={`${active ? 'text-white' : 'text-gray-800'}
                                absolute inset-y-0 left-0 flex items-center pl-2`}
                      >
                        <CheckIcon className="h-4 w-4" aria-hidden="true" />
                      </span>
                    ) : null}
                  </>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};
export default SearchFilterDropdown;
