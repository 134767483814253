import * as React from 'react';
import { LinkProps } from 'react-scroll';
import { DefaultButtonClassName, mergeClasses } from '.';
import Button, { ButtonProps } from './Button';
import { SpinnerIcon } from './icons';
import { SAVE } from './Constants';

export type SaveButtonProps = Exclude<ButtonProps, LinkProps | React.AnchorHTMLAttributes<HTMLAnchorElement>> & {
  isSaving?: boolean;
};

function SaveButton({ className, children, isSaving, disabled, type, ...props }: SaveButtonProps) {
  return (
    <Button
      {...props}
      type={type ?? 'submit'}
      disabled={isSaving || disabled}
      className={mergeClasses(
        className ?? DefaultButtonClassName,
        'py-1 transition-all',
        isSaving ? 'animate-pulse py-1' : ''
      )}
    >
      {children ?? SAVE}
      {isSaving && <SpinnerIcon className="ml-3 -mr-1 h-5 w-5 animate-spin text-white" />}
    </Button>
  );
}

export default SaveButton;
