import { NavigateProps } from 'react-router-dom';
import type { SortInfoRecord } from './sortInfo';

// Components
export { default as AddLinkButton } from './AddLinkButton';
export * from './Alert';
export { default as Alert } from './Alert';
export * from './AnimatedToggle';
export { default as AnimatedToggle } from './AnimatedToggle';
export { default as AutoSizingTextAreaInput } from './AutoSizingTextAreaInput';
export * from './Button';
export { default as Button } from './Button';
export * from './Cancelable';
export { default as Cancelable } from './Cancelable';
export { default as CancelButton } from './CancelButton';
export type { CheckboxProps, CheckboxTheme } from './Checkbox';
export { default as Checkbox } from './Checkbox';
export type { CheckboxToggleProps } from './CheckboxToggle';
export { default as CheckboxToggle } from './CheckboxToggle';
export * from './ConfirmationDialog';
export { default as ConfirmationDialog } from './ConfirmationDialog';
export * from './ConfirmButton';
export { default as ConfirmButton } from './ConfirmButton';
export { default as ContentRow } from './ContentRow';
export { default as DateInput } from './DateInput';
export { default as DatePicker } from './DatePicker';
export type { DatePickerProps } from './DatePicker';
export { default as DeleteButton } from './DeleteButton';
export type { DialogProps } from './Dialog';
export { default as Dialog } from './Dialog';
export { default as EditLinkButton } from './EditLinkButton';
export * from './EditToggle';
export { default as EditToggle } from './EditToggle';
export * from './EmptyListPlaceholder';
export { default as EmptyListPlaceholder } from './EmptyListPlaceholder';
export * from './ErrorBoundary';
export { default as ErrorBoundary } from './ErrorBoundary';
export * from './ErrorMarker';
export { default as ErrorMarker } from './ErrorMarker';
export { default as FilterRow } from './FilterRow';
export * from './GenericInput';
export { default as GenericInput } from './GenericInput';
export * from './HelpTip';
export { default as HelpTip } from './HelpTip';
export { default as Chart } from './Chart';
export type { Highcharts } from './Chart';
export type { InputProps, InputTheme } from './Input';
export { default as Input } from './Input';
export { default as LinkButton } from './LinkButton';
export { default as ListFilters } from './ListFilters';
export { default as ListPageDisplay } from './ListPageDisplay';
export * from './ListPagination';
export { default as ListPagination } from './ListPagination';
export { default as ListTable } from './ListTable';
export * from './LoadApiDataWrapper';
export { default as LoadApiDataWrapper } from './LoadApiDataWrapper';
export * from './LoadDataWrapper';
export { default as LoadDataWrapper } from './LoadDataWrapper';
export { AnimatedLoadingOverlay, LoadingIndicator, LoadingOverlay } from './LoadingIndicator';
export type { AnimatedLoadingIndicatorProps, LoadingIndicatorProps } from './LoadingIndicator';
export * from './NumberInput';
export { default as NumberInput } from './NumberInput';
export { default as PageSection } from './PageSection';
export type { PageSectionProps } from './PageSection';
export { default as PageTabbedSection } from './PageTabbedSection';
export type { PageTabbedSectionProps } from './PageTabbedSection';
export * from './PageTemplate';
export { default as PageTemplate } from './PageTemplate';
export * from './PartialDisclosure';
export { default as PartialDisclosure } from './PartialDisclosure';
export * from './PasswordInput';
export { default as PasswordInput } from './PasswordInput';
export type { RadioProps, RadioTheme } from './Radio';
export { default as Radio } from './Radio';
export * from './ReportingReportSelection';
export { default as ReportingReportSelection } from './ReportingReportSelection';
export * from './RichTextEditorInput';
export { default as RichTextEditorInput } from './RichTextEditorInput';
export { default as RichTextRenderer } from './RichTextRenderer';
export { default as SaveButton } from './SaveButton';
export * from './SearchFilterInput';
export { default as SearchFilterInput } from './SearchFilterInput';
export * from './SelectInput';
export { default as SelectedCheckbox } from './SelectedCheckbox';
export * from './SelectedCheckbox';
export { default as SelectInput } from './SelectInput';
export * from './SelectInputGeneric';
export { default as SelectInputGeneric } from './SelectInputGeneric';
export type { SlideOverPanelProps, SlideOverPanelBodyProps, SlideOverPanelActionsProps } from './SlideOverPanel';
export { default as SlideOverPanel } from './SlideOverPanel';
export type {
  SlideOverOutletProps,
  SlideOverOutletBodyProps,
  SlideOverOutletActionsProps,
  SlideOverOutletContentProps,
} from './SlideOverOutlet';
export { default as SlideOverOutlet, useSlideOverOutletInitialFocusRef } from './SlideOverOutlet';
export { default as SortHeaderButton } from './SortHeaderButton';
export * from './TextAreaInput';
export { default as TextAreaInput } from './TextAreaInput';
export * from './TextInput';
export { default as TextInput } from './TextInput';
export * from './TextInputGeneric';
export { default as TextInputGeneric } from './TextInputGeneric';
export type { ToggleProps, ToggleTheme } from './Toggle';
export { default as Toggle } from './Toggle';

export { default as AllErrorsAlert } from './errors/AllErrorsAlert';
export type { AllErrorsAlertProps } from './errors/AllErrorsAlert';
export { default as DefaultErrorAlert } from './errors/DefaultErrorAlert';
export type { DefaultErrorAlertProps } from './errors/DefaultErrorAlert';
export { default as ErrorAlert } from './errors/ErrorAlert';
export type { ErrorAlertProps } from './errors/ErrorAlert';
export { default as FieldErrorsAlert } from './errors/FieldErrorsAlert';
export type { FieldErrorsAlertProps } from './errors/FieldErrorsAlert';
export { getFieldErrors, mapErrorsFromException, mapErrorsFromResponse } from './errors/renderErrors';
export type { ErrorMapType, ErrorResponse } from './errors/renderErrors';

export * from './sortInfo';
export * from './pageInfo';
export { default as mergeClasses } from './mergeClasses';

// Types
export type WithClassName<T = {}> = T & { className?: string };

export interface SearchPagingParams<TFilter = any> {
  filter?: TFilter;
  page: number;
  sortInfo: SortInfoRecord;
}

export type ReactProps<P> = Readonly<{ children?: React.ReactNode }> & Readonly<P>;

export interface ChangeHandler {
  (name: string, value: any): void;
}

export interface GenericChangeHandler<T> {
  (name: keyof T, value: any): void;
}

export function genericChangeHandler<T>(name: string, value: any, object: T): T {
  let obj: { [k: string]: string | number | boolean | null | undefined } = {};
  obj[name] = value;
  return { ...object, ...obj };
}

export function genericMapChangeHandler<T>(handler: GenericChangeHandler<T>): ReactChangeEventHandler {
  return (event: GenericChangeEvent) => {
    const target = event.target;
    let value: string | number | boolean = target.value;
    if (isInputElement(target) && target.type === 'checkbox') {
      value = target.checked;
    }

    handler(target.name as keyof T, value);
  };
}

interface FormInput extends HTMLElement {
  name: string;
  value: string | number;
}

export type GenericChangeEvent = React.ChangeEvent<FormInput>;

export interface ReactChangeEventHandler {
  (event: GenericChangeEvent): void;
}

export function mapChangeHandler(handler: ChangeHandler): ReactChangeEventHandler {
  return (event: GenericChangeEvent) => {
    const target = event.target;
    let value: string | number | boolean = target.value;
    if (isInputElement(target) && target.type === 'checkbox') {
      value = target.checked;
    }

    handler(target.name, value);
  };
}

function isInputElement(element: HTMLElement): element is HTMLInputElement {
  return element.tagName === 'INPUT';
}

export type NavigateParams = NavigateProps;

export function getNavigateParams(params: NavigateParams | string): NavigateParams {
  return typeof params === 'string' ? { to: params } : params;
}
