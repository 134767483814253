import { User } from 'oidc-client';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import authService, { AuthenticationService } from '../util/authService';
import history from '../util/history';
import { useUserProfile } from './UserCompanyContext';

interface AuthState {
  accessToken: string;
}

interface AuthMethods {
  login(): void;
  logout(): void;
}

type AuthContextProps = AuthState & AuthMethods;

const AuthContext = React.createContext<AuthContextProps | undefined>(undefined);

export function useAuth() {
  const ctx = React.useContext(AuthContext);
  if (ctx === undefined) {
    throw new Error('useAuth must be used within a NotificationsProvider.');
  }

  return ctx;
}

export interface AuthConsumerProps {
  children(props: AuthContextProps): React.ReactNode;
}

export function AuthConsumer({ children }: AuthConsumerProps) {
  return (
    <AuthContext.Consumer>
      {(ctx) => {
        if (ctx === undefined) {
          throw new Error('AuthConsumer must be used within a AuthProvider.');
        }

        return children(ctx);
      }}
    </AuthContext.Consumer>
  );
}

export interface AuthProviderProps {
  children?: React.ReactNode;
}

export function AuthProvider(props: AuthProviderProps) {
  const navigate = useNavigate();
  const auth = React.useRef(authService.get()).current;
  const { fetchProfile, resetProfile } = useUserProfile();
  const [accessToken, setAccessToken] = React.useState(() => auth.getAccessToken());

  React.useEffect(() => {
    auth.onLoginRedirect = (url: string) => {
      navigate('/login', { state: { url: url } });
    };

    return () => {
      auth.onLoginRedirect = null;
    };
  }, [auth, auth.onLoginRedirect, navigate]);

  React.useEffect(() => {
    function onAuthenticated({ access_token }: User) {
      setAccessToken(access_token);
    }

    function onLogout() {
      setAccessToken('');
      resetProfile();
    }

    auth.events.addUserLoaded(onAuthenticated);
    auth.events.addUserSignedOut(onLogout);

    return () => {
      auth.events.removeUserLoaded(onAuthenticated);
      auth.events.removeUserSignedOut(onLogout);
    };
  }, [auth.events, resetProfile]);

  React.useEffect(() => {
    if (accessToken && accessToken.length > 0) {
      if (auth.isExpired()) {
        doSilentLogin(auth);
      } else {
        fetchProfile();
      }
    } else {
      doSilentLogin(auth);
    }
  }, [accessToken, auth, fetchProfile]);

  const value: AuthContextProps = React.useMemo((): AuthContextProps => {
    return {
      accessToken: accessToken,
      login: () => {
        const location = history.location;
        history.push('/login', { url: location ? location.pathname : '/' });
      },
      logout: () => auth.logout(),
    };
  }, [accessToken, auth]);

  return <AuthContext.Provider {...props} value={value} />;
}

function doSilentLogin(auth: AuthenticationService) {
  const location = history.location;
  // We don't need to do a silent login if this is the callback from the login server
  // if (auth.getAutoAuth() && (location && location.pathname !== '/callback' && location.pathname !== '/login')) {
  if (auth.getAutoAuth() && location && location.pathname !== '/callback') {
    auth.silentLogin(location ? location.pathname : '');
  }
}
