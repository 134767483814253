import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export default function useOnNavigate(onRefresh: () => void): void {
  const { pathname } = useLocation();
  const mounted = useRef(false);

  useEffect(() => {
    if (mounted.current) {
      onRefresh();
    }
    mounted.current = true;
  }, [onRefresh, pathname]);
}
