import { AxiosError } from 'axios';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth } from '../../../global/AuthProvider';
import { useUserProfile } from '../../../global/UserCompanyContext';
import { useErrors, useHttp, useNavigate } from '../../../hooks';
import { genericChangeHandler } from '../../common';

export interface ProfileState {
  firstName: string;
  lastName: string;
}

const initialProfileState: ProfileState = {
  firstName: '',
  lastName: '',
};

function useProfileForm() {
  const navigate = useNavigate();
  const { firstName, lastName, email, isExternalLogin, updateProfile } = useUserProfile();
  const { errors, clear: clearErrors, catchErrors } = useErrors();
  const [http] = useHttp();
  const [state, setState] = useState<ProfileState>(initialProfileState);
  const { logout } = useAuth();

  const loading: boolean = useMemo(() => email === '', [email]);

  useEffect(() => {
    if (state.firstName === '' || state.lastName === '') {
      setState({
        firstName: firstName,
        lastName: lastName,
      });
    }
  }, [firstName, lastName, state.firstName, state.lastName]);

  const handleSave = useCallback(
    async function handleSave() {
      try {
        const response = await http.put('/profile', state);
        clearErrors();
        updateProfile(response.data);
      } catch (e: unknown) {
        catchErrors(e as AxiosError);
        throw e;
      }
    },
    [catchErrors, clearErrors, http, state, updateProfile]
  );

  const handleChange = useCallback((prop: keyof ProfileState, value: string) => {
    setState((prev) => genericChangeHandler(prop, value, prev));
  }, []);

  const handleOnClickChangePhoneNumber = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault();
      navigate('/profile/mfa/sms');
    },
    [navigate]
  );

  return {
    state,
    loading,
    isExternalLogin,
    errors,
    handleSave,
    handleChange,
    handleOnClickChangePhoneNumber,
    logout,
  };
}

export default useProfileForm;
