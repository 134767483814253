import React from 'react';
import Flatpickr from 'react-flatpickr';
import './DatePicker.css';

export type DatePickerProps = {
  onChange(date: string): void;
  value: string;
  children?: React.ReactNode;
};

function DatePicker(props: DatePickerProps) {
  return (
    <>
      <Flatpickr
        value={props.value}
        className="c-date-picker border-0 bg-transparent focus:ring-0"
        onChange={(_e, value, _instance) => {
          props.onChange(value);
        }}
        children={props.children as any}
        options={{
          wrap: true,
          dateFormat: 'Y-m-d',
        }}
      />
    </>
  );
}

export default DatePicker;
