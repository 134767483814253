import * as React from 'react';
import { ErrorMarker, GenericInput, GenericInputProps } from '.';
import Input from './Input';

export type PasswordInputProps = GenericInputProps &
  React.InputHTMLAttributes<HTMLInputElement> & {
    containerClassName?: string;
  };

export default React.forwardRef(function PasswordInput(
  {
    id,
    type,
    value,
    name,
    label,
    required,
    containerClassName,
    labelClassName,
    errors,
    helptip,
    ...props
  }: PasswordInputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <GenericInput
      id={id}
      name={name}
      label={label}
      required={required}
      className={containerClassName}
      labelClassName={labelClassName}
      errors={errors}
      helptip={helptip}
    >
      {({ hasError, ...inputProps }) => (
        <>
          <Input
            {...inputProps}
            {...props}
            type={type ?? 'password'}
            name={name}
            required={required}
            value={value ?? ''}
            theme={hasError ? 'error' : 'standard'}
            ref={ref}
          />
          <ErrorMarker hasError={hasError} />
        </>
      )}
    </GenericInput>
  );
});
